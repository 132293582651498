import { getConfig, getIntl, getTheme } from "portal-state-management";
import { applyTargetTheme, COOKIE_LOCALE, getCookie, loadThemeStyles, setCookie } from "portal-utilities";
import queryString from "query-string";
import { initGlobalApp } from "../index";

/**
 * Legal content initializer
 * @param {*} dispatch
 * @returns
 */
export const initLegalContentApp = async ({ dispatch }) => {
  const queryParams = queryString.parse(location.search);
  const lang = queryParams?.lang;
  const localeQuery = lang?.replace("_", "-");

  if (localeQuery) {
    if (getCookie(COOKIE_LOCALE) !== localeQuery || !getCookie(COOKIE_LOCALE)) {
      setCookie(COOKIE_LOCALE, localeQuery);
    } else {
      setCookie(COOKIE_LOCALE, "en-US");
    }
  }

  const locale = localeQuery || getCookie(COOKIE_LOCALE);

  const config = await dispatch(getConfig({}));
  await initGlobalApp({ config: config?.data });
  await dispatch(getIntl({ locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(
      getTheme({ isTargetTheme: applyTargetTheme({ config }) })
    );
    loadThemeStyles(theme);
  }
};

export const isLegalContentAppInitialized = (props) => {
  if (props?.theme?.isLoading === false && props?.intl?.isLoading === false) {
    return true;
  }

  return false;
};
