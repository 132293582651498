import { axios } from "portal-services";
import { getHeaders, URLS } from "portal-utilities";

/**
 * gets service status for IFE, IPTV, link up/down, and service coverage
 * @return {Promise}
 */
export const getServiceStatus = () => {
  return axios({
    method: "get",
    headers: getHeaders(),
    url: "/abp/v2/service-status",
    transformResponse: [
      function (data) {
        return transformServiceStatus(data);
      },
    ],
  });
};

/**
 * tranforms service status to readable format
 * @param  {Object} data
 * @return {Object}
 */
/* eslint-disable complexity */
export const transformServiceStatus = (data) => {
  const { service_status, domain_status, edge_cache_apistatus } =
    JSON.parse(data);

  let output = {
    ifeStatus: service_status?.ife_status || false,
    iptvStatus: service_status?.iptv_status || false,
    linkStatus: service_status?.link_status || false,
    serviceCoverage: service_status?.service_coverage || false,
    serviceStatus: service_status?.service_status || false,
    protocol: domain_status?.protocol || "http",
    abpHostName: domain_status?.abp_host_name || URLS?.airborne,
    gbpHostName: domain_status?.gbp_host_name || URLS?.airborne,
    mediaHostName: domain_status?.media_host_name || URLS?.airborneMedia,
    synced: domain_status?.synced || false,
    freePortal: domain_status?.portal_type === "FREE" || false,
    edgeCacheStatus: edge_cache_apistatus?.status || false,
    bypassMaintenanceUrl: service_status?.bypass_maintenance_url || null,
  };

  return output;
};
