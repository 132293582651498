import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import { COOKIE_JWT_TOKEN, getCookie } from "portal-utilities";

/**
 * gets available passes of user
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getAvailablePasses = ({ uxdId, userName, status }) => {
  const token = getCookie(COOKIE_JWT_TOKEN);

  return axios({
    method: "get",
    url: `/v2/subscription/user/${userName}/uxdId/${uxdId}`,
    headers: {
      Authorization: token,
    },
    baseURL: getAirborneABPEdgeBaseUrl(status),
    transformResponse: [
      function (data) {
        return transformAvailablePasses(data);
      },
    ],
  });
};

/**
 * tranforms available passes to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformAvailablePasses = (data) => {
  const response = JSON.parse(data);

  const availablePasses = response?.available?.filter((pass) => {
    return pass?.serviceIdentifier !== response?.active[0]?.serviceIdentifier;
  });

  let output = {
    activePasses: response?.active || [],
    availablePasses: availablePasses || [],
    unavailablePasses: response?.unAvailable || [],
  };

  return output;
};

/**
 * gets available passes of user
 * @param  {Object} options.accountId
 * @param  {Object} options.partnerName
 * @param  {Object} options.status
 * @param  {String} options.uxdId
 * @return {Promise}
 */
export const getAvailablePartnerPasses = ({
  accountId,
  partnerName,
  status,
  uxdId,
}) => {
  const token = getCookie(COOKIE_JWT_TOKEN);

  return axios({
    method: "post",
    url: `/v4/available-services/uxdid/${uxdId}`,
    headers: {
      Authorization: token,
    },
    baseURL: getAirborneABPEdgeBaseUrl(status),
    data: {
      requester: "GOGO_INTERNET",
      vendor_id: "GOGO",
      tracking_id: uxdId,
      partner_name: partnerName.toUpperCase(),
      account_id: accountId,
    },
    transformResponse: [
      function (data) {
        return transformAvailablePartnerPasses(data);
      },
    ],
  });
};

/**
 * tranforms available passes to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformAvailablePartnerPasses = (data) => {
  const response = JSON.parse(data);

  return {
    loyaltyAvailablePasses: {
      activeServices: response?.active_services || [],
      availableServices: response?.available_services || [],
      unavailableServices: response?.unAvailable_services || [],
    },
  };
};
