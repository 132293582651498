import {
  getConfig,
  getFlightInfo,
  getIntl,
  getStatus,
  getTheme,
  updateFlightInfo,
} from "portal-state-management";
import {
  applyTargetTheme,
  getByPassLogInfo,
  loadThemeStyles,
  redirectIfByPassEnabled,
  ROUTE_IFC_SPLASH,
} from "portal-utilities";
import { getInFlight } from "../../state_management/in_flight/actions.js";
import { getCurrency, getLocale, initGlobalApp } from "../index";

/**
 * IFC initializer
 * @param {*} dispatch
 * @returns
 */
export const initIFCApp = async ({ dispatch }) => {
  const config = await dispatch(getConfig({}));

  await initGlobalApp({ config: config?.data });

  const serviceStatus = await dispatch(getStatus());
  // logic to redirect to splash with correct domainName
  if (
    process.env.NODE_ENV !== "development" &&
    serviceStatus?.abpHostName &&
    serviceStatus?.abpHostName !== window.location.hostname
  ) {
    window.location.href = `${serviceStatus?.protocol}://${serviceStatus?.abpHostName}${config?.data?.baseName}${ROUTE_IFC_SPLASH}`;
  }

  const flightInfo = await dispatch(
    getFlightInfo({
      config: config?.data,
      status: serviceStatus,
    })
  );

  let locale = await getLocale({ config: config?.data });

  let currency = await getCurrency({ config: config?.data });

  await dispatch(getIntl({ locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(
      getTheme({ isTargetTheme: applyTargetTheme({ config, flightInfo }) })
    );
    loadThemeStyles(theme);
  }

  try {
    dispatch(
      getInFlight({
        config: config?.data,
        currency,
        flightInfo,
        locale,
        status: serviceStatus,
      })
    ).then((inflightInfo) => {
      const bypassMaintenanceUrl = inflightInfo?.bypassMaintenanceUrl;
      if (bypassMaintenanceUrl) {
        const logData = getByPassLogInfo(flightInfo);
        redirectIfByPassEnabled(bypassMaintenanceUrl, {
          ...logData,
        });
      }
    });
    setInterval(() => {
      dispatch(
        updateFlightInfo({ config: config?.data, status: serviceStatus })
      );
    }, 60000);
  } catch (error) {
    dispatch(
      getInFlight({
        config: config?.data,
        currency,
        flightInfo: {},
        locale,
        serviceStatus: {},
      })
    );
  }
};

/**
 * IFC initializer status
 * @param {*} props
 * @returns
 */
export const isIFCInitialized = (props) => {
  // TODO: Check for error.
  if (
    props?.flightInfo?.isLoading === false &&
    props?.config?.isLoading === false &&
    props?.theme?.isLoading === false
  ) {
    return true;
  }

  return false;
};
