import { call, put, takeLatest } from "redux-saga/effects";

import { getStatusTray } from "portal-services";
import { COOKIE_LOGGER_DATA, setCookie } from "portal-utilities";

/**
 * @param {Object} action
 */
export function* flightInfoSaga(action) {
  const meta = action.meta;
  const { config, isTailNumberApi, status } = action.payload;

  switch (action.type) {
    case "GET_FLIGHT_INFO":
      try {
        const { data } = yield call(getStatusTray, {
          config,
          isTailNumberApi,
          status,
        });
        const { airlineCode, flightNumber, user } = data;
        // Set cookie for logging to work in payment app
        setCookie(
          COOKIE_LOGGER_DATA,
          JSON.stringify({
            airlineCode,
            flightNumber,
            mac: user?.macAddress,
          })
        );

        yield put({
          type: "GET_FLIGHT_INFO_SUCCESS",
          payload: data,
          meta: meta,
        });
      } catch (e) {
        yield put({ type: "GET_FLIGHT_INFO_FAIL", payload: e, meta });
      }
      return;
    case "UPDATE_FLIGHT_INFO":
      try {
        const { data } = yield call(getStatusTray, {
          config,
          status,
        });

        yield put({
          type: "UPDATE_FLIGHT_INFO_SUCCESS",
          payload: data,
          meta: meta,
        });
      } catch (e) {
        yield put({ type: "UPDATE_FLIGHT_INFO_FAIL", payload: e, meta });
      }
      return;
  }
}

/**
 * get flight info watcher used for testing purposes
 */
export function* getFlightInfoWatcher() {
  yield takeLatest("GET_FLIGHT_INFO", flightInfoSaga);
}
