import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import Analytics from "../Analytics";

export const AnalyticsContext = React.createContext();

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export class AnalyticsProvider extends Component {
  analytics = Analytics.getInstance(this.props.config?.key);

  render() {
    return (
      <AnalyticsContext.Provider value={{ analytics: this.analytics }}>
        {this.props.children}
      </AnalyticsContext.Provider>
    );
  }
}

AnalyticsProvider.propTypes = {
  config: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

AnalyticsProvider.defaultProps = {
  config: {},
};
