/**
 * get flight info redux action
 * @param  {Object} options.status
 * @return {Object}
 */
export const getFlightInfo = ({ config, isTailNumberApi, status }) => {
  return {
    type: "GET_FLIGHT_INFO",
    payload: { config, isTailNumberApi, status },
    meta: {
      thunk: true,
    },
  };
};

/**
 * update flight info redux action
 * @param  {Object} options.status
 * @return {Object}
 */
export const updateFlightInfo = ({ config, status }) => {
  return {
    type: "UPDATE_FLIGHT_INFO",
    payload: { config, status },
    meta: {
      thunk: true,
    },
  };
};
