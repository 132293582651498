/**
 * get theme redux action
 * @param  {String} id
 * @return {Object}
 */
export const getTheme = ({
  airlineCode,
  isDarkMode = false,
  isTargetTheme = false,
}) => {
  return {
    type: "GET_THEME",
    payload: {
      airlineCode,
      isDarkMode,
      isTargetTheme,
    },
    meta: {
      thunk: true,
    },
  };
};
