import { jVar } from "json-variables";
import { axios } from "portal-services";

/**
 * gets theme from public directory(non OBP airlines) or pimcore(local development for CMS enabled airlines) or from abp(for OBP dev/stage/production)
 * @return {Promise}
 */
export const getTheme = ({ airlineCode = "", isTargetTheme = false }) => {
  if (process.env.HAS_ABP_CONFIG === "true") {
    return axios({
      method: "get",
      url: isTargetTheme
        ? "/ui-config/shared/theme-target.json"
        : "/ui-config/shared/theme.json",
      headers: {
        "airline-code": airlineCode,
      },
      params: {
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformTheme(data);
        },
      ],
    });
  } else if (process.env.THEME_ID) {
    return axios({
      method: "get",
      url: "/cmsthemes",
      params: {
        airlineCode: process.env.THEME,
        filePath: "shared",
        fileName: "theme.json",
        state: "obpdevelopment",
        theme_id: process.env.THEME_ID,
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformTheme(data);
        },
      ],
    });
  } else {
    return axios({
      method: "get",
      url: isTargetTheme
        ? "/configuration/theme-target.json"
        : "/configuration/theme.json",
      baseURL: process.env.PUBLIC_URL,
      params: {
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformTheme(data);
        },
      ],
    });
  }
};

/**
 * tranforms theme to readable format and includes json variables
 * @param {Object} data
 * @returns {Object}
 */
const transformTheme = (data) => {
  const response = JSON.parse(data);

  return process.env.THEME_ID ? jVar(JSON.parse(response)) : jVar(response);
};
