import {
  generateProductAddOnInfo,
  getProductDuration,
  getProductGroup,
  isSponsoredProduct,
} from "portal-utilities";

/**
 * tranforms products to readable format.
 * @param {Object} data
 * @param {Object} config
 * @returns {Object}
 */
export const transformProducts = ({ config, data }) => {
  const response = JSON.parse(data);

  let productsList = [];
  let entitlementProductsList = [];

  let addOnProductsList;
  let entitlementPasses = config?.entitlementPasses || [];

  if (response?.products) {
    addOnProductsList = generateProductAddOnInfo(response?.products);
    productsList = response?.products;
    if (!config?.isEntitlementProductsFromThemes) {
      entitlementPasses.length > 0 &&
        response?.products.map((product) => {
          let index = entitlementPasses.findIndex(
            (i) => i?.productCode === product?.product_code
          );
          if (index >= 0) {
            entitlementProductsList.push({
              ...generateProductAttributes(product),
              authType: entitlementPasses[index]?.authType,
              partner: entitlementPasses[index]?.partner,
            });
          }
        });

      entitlementProductsList = sortByPresentationPriority(
        entitlementProductsList
      );

      productsList = response?.products.filter(
        (prd) =>
          entitlementPasses.findIndex(
            (i) => i?.productCode === prd?.product_code
          ) < 0
      );
    }

    productsList = productsList.map((product) => {
      const attributes = generateProductAttributes(product);
      const addOnProductList = addOnProductsList[product?.product_code] || null;

      return {
        ...attributes,
        addOnProductRule: addOnProductList?.addOnProductRule || null,
        duration: getProductDuration(attributes),
        group: getProductGroup(attributes),
        isAddOnProduct: addOnProductList?.isAddOnProduct || false,
        isSponsoredProduct: isSponsoredProduct(attributes),
        rootProduct: addOnProductList?.rootProduct || false,
      };
    });

    productsList = sortByPresentationPriority(productsList);
  }

  return {
    currency: response?.currency,
    entitlementProducts: entitlementProductsList,
    products: productsList,
    isValid: productsList.length > 0,
  };
};

/**
 * check product is FullDayPassProduct or not.
 * @param {Object} product
 * @returns {boolean}
 */
export const isFullDayPassProduct = (product) => {
  return (
    product?.product_type === "X-HOURPASS" &&
    (product?.redemption?.duration_hours === "24" ||
      product?.redemption?.duration_hours === "8")
  );
};

/**
 * check product is MSP or not.
 * @param {Object} product
 * @returns {boolean}
 */
export const isMonthlySubscriptionPlanProduct = (product) => {
  return (
    product?.product_type === "MONTHLY" || product?.product_type === "ANNUALSUB"
  );
};

const sortByPresentationPriority = (productsList) => {
  productsList = productsList.sort((a, b) =>
    a?.presentationPriority > b?.presentationPriority ? 1 : -1
  );
  return productsList;
};

const generateProductAttributes = (product) => {
  return {
    dataAutomation: `${product?.product_name
      .split(" ")
      .join("-")
      .toLowerCase()}-select`,
    durationHours: product?.redemption?.duration_hours,
    durationMins: product?.redemption?.duration_mins,
    id: product?.id,
    isBOGONinetyMinPlan: parseInt(product?.redemption?.duration_mins) === 90,
    isBOGOOneHourPlan: parseInt(product?.redemption?.duration_hours) === 1,
    isDomesticPlan: product?.redemption?.rdm_region === "DOM",
    isFullDayPass: isFullDayPassProduct(product),
    isFullFlightPass:
      product?.product_type === "SEGMENT" ||
      product?.redemption?.duration_hours === "18" ||
      product?.redemption?.duration_hours === "8",
    isGlobalPlan: product?.redemption?.rdm_region === "any",
    isHourlyPass: product?.product_type === "X-HOURPASS",
    isMonthlySubscriptionPlan: isMonthlySubscriptionPlanProduct(product),
    isMultiDevicePass: parseInt(product?.redemption?.concr_devices) > 1,
    isOneDevicePass: parseInt(product?.redemption?.concr_devices) === 1,
    isStreamingDevicePass:
      product?.service_code === "STRMSD" && product?.product_type === "MONTHLY",
    isTwoDevicePass: parseInt(product?.redemption?.concr_devices) === 2,
    longDescription: product?.long_description,
    presentationPriority: parseInt(
      product?.presentation?.presentation_priority,
      10
    ),
    price: parseFloat(product?.price),
    productCode: product?.product_code,
    englishProductName: product?.english_product_name,
    productName: product?.product_name,
    productType: product?.product_type,
    rdmRegion: product?.redemption?.rdm_region,
    rewardPoints: parseInt(product?.reward_points, 10) || null,
    serviceCode: product?.service_code,
    shortDescription: product?.short_description,
    totalDurationSeconds:
      parseInt(product?.redemption?.duration_hours, 10) * 3600 +
      parseInt(product?.redemption?.duration_mins, 10) * 60,
  };
};
