import { axios, getAirborneMediaASPBaseUrl } from "portal-services";

/**
 * gets media catalog
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getCatalogSummary = ({ locale, status }) => {
  return axios({
    method: "get",
    url: `/v1/${locale}/collections/summary`,
    baseURL: getAirborneMediaASPBaseUrl(status),
    data: {
      credentials: "include",
    },
  });
};
