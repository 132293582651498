/**
 * @const {String}
 */
export const QUERY_ACTIVATION_STATUS = "activationStatus";

/**
 * @const {String}
 */
export const QUERY_AIRLINE_CODE = "airlineCode";

/**
 * @const {String}
 */
export const QUERY_IS_INDIVIDUAL_PIPELINE = "isIndividualPipeline";

/**
 * @const {String}
 */
export const QUERY_IS_UPGRADE_PRODUCT = "isUpgradeProduct";

/**
 * @const {String}
 */
export const QUERY_PRODUCT = "product";

/**
 * @const {String}
 */
export const QUERY_RETURN_URL = "returnUrl";

/**
 * @const {String}
 */
export const QUERY_TAIL_NUMBER = "tailNumber";

/**
 * @const {String}
 */
export const QUERY_REDIRECT_URI = "redirect_uri";

/**
 * @const {String}
 */
export const QUERY_PARTNER_AUTH_CODE = "code";

/**
 * @const {String}
 */
export const QUERY_SWITCH_SESSION_STATE = "state";

/**
 * @const {String}
 */
export const QUERY_ACTIVE_PRODUCT = "active_product";

/**
 * @const {String}
 */
export const QUERY_SOURCE = "source";

/**
 * @const {String}
 */
export const QUERY_SOURCE_URL = "sourceUrl";
