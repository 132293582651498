/**
 * Check if the THEME_ID is CMS development THEME_ID
 * @returns {Boolean}
 */
export const isCmsDevelopmentThemeID = () => {
  switch (process.env.THEME) {
    case "amx":
      return process.env.THEME_ID === "15971";
    case "jlj":
      return process.env.THEME_ID === "16583";
    case "waw":
      return process.env.THEME_ID === "16128";
    default:
      return false;
  }
};
