import { call, put, takeLatest } from "redux-saga/effects";

import { getActiveDevicePin } from "portal-services";

import { COOKIE_JWT_TOKEN, COOKIE_UXDID, getCookie } from "portal-utilities";

export function* switchDeviceSaga(action) {
  const meta = action.meta;
  const { status } = action.payload;
  let switchActiveDeviceDetails = {
    data: { uxdId: "", emailAddress: "", pin: "" },
  };

  try {
    const uxdId = getCookie(COOKIE_UXDID);
    const jwtToken = getCookie(COOKIE_JWT_TOKEN);

    switchActiveDeviceDetails = yield call(getActiveDevicePin, {
      uxdId,
      status,
      jwtToken,
    });

    yield put({
      type: "GET_ACTIVE_DEVICE_PIN_SUCCESS",
      payload: {
        ...switchActiveDeviceDetails.data,
      },
      meta: meta,
    });
  } catch (error) {
    yield put({ type: "GET_ACTIVE_DEVICE_PIN_FAIL", payload: error, meta });
  }
}

export function* switchDeviceWatcher() {
  yield takeLatest("GET_ACTIVE_DEVICE_PIN", switchDeviceSaga);
}
