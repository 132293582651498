import DataModel from "../DataModel";
import { PAGE_EVENT } from "../../constants";

const REQUIRED_PROPS = ["pageName"];

class PageEventDataModel extends DataModel {
  constructor(data = {}) {
    super(data);
    this.displayName = PAGE_EVENT;
    this.validateProps(REQUIRED_PROPS, data);
  }
}

export default PageEventDataModel;
