import omit from "lodash/omit";
import { setCookie, URLS } from "portal-utilities";

const SCRIPT_ID = "adobe-analytics-script";

const isScriptActive = (scriptId) => !!document.getElementById(scriptId);
const invokeCallback = (callback) =>
  typeof callback === "function" && callback();

const getAdobeAnalyticsUrl = () => {
  switch (true) {
    case window.location.href.includes("https://airbornesecure"):
      return "https://${URLS?.airborneSecure}/adobe/satelliteLib-f80d620286957e28c3f38583c2d26f8e28fcd5e8.js?e3090a3ef91cf8790fbd";
    case window.location.protocol === "https:":
      return `https://${URLS?.wifi}/app/analytics/satelliteLib-f80d620286957e28c3f38583c2d26f8e28fcd5e8.js`;
    default:
      return `http://${URLS?.airborne}/adobe/satelliteLib-f80d620286957e28c3f38583c2d26f8e28fcd5e8.js`;
  }
};

export const loadAdobeAnalyticsScript = (callback) => {
  // if script is active already, no need to reload script
  // but have to invoke the callback function again and exit
  if (isScriptActive(SCRIPT_ID)) {
    invokeCallback(callback);
    return;
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = SCRIPT_ID;
  script.async = true;
  script.src = getAdobeAnalyticsUrl();

  document.body.appendChild(script);

  script.onload = () => {
    invokeCallback(callback);
  };
};

export const trackCustomEventOnDynatrace = (actionName) => {
  try {
    const actionId = window.dtrum.enterAction(actionName, "Custom");
    window.dtrum.leaveAction(actionId);
  } catch (e) {
    /* Catch will be reached if the Dynatrace library fails for some reason,
    but these shouldn't affect the app */
  }
};

export const trackCustomEventOnDynatraceWithDuration = (
  actionName,
  stopTime,
  startTime,
  sourceUrl
) => {
  try {
    const actionId = window.dtrum.enterAction(
      actionName,
      "Custom",
      undefined,
      sourceUrl
    );
    window.dtrum.leaveAction(actionId, stopTime, startTime);
  } catch (e) {
    /* Catch will be reached if the Dynatrace library fails for some reason,
    but these shouldn't affect the app */
  }
};

export const trackCustomEventOnDynatraceWithDurationAndError = (
  actionName,
  stopTime,
  startTime,
  sourceUrl,
  error
) => {
  try {
    const actionId = window.dtrum.enterAction(
      actionName,
      "Custom",
      undefined,
      sourceUrl
    );
    window.dtrum.reportCustomError(actionName, error, undefined, actionId);
    window.dtrum.leaveAction(actionId, stopTime, startTime);
  } catch {
    /**
     * Prevent site crash if Dynatrace library fails to download
     * or dtrum does not get injected.
     */
  }
};

export const trackUser = (uxdId) => {
  try {
    if (uxdId) {
      window.dtrum.identifyUser(uxdId);
    }
  } catch (e) {
    /**
     * Prevent site crash if Dynatrace lib fails to download
     * or dtum does not get injected.
     *  */
  }
};

export const trackSessionProperties = (props) => {
  try {
    const cleanProps = omit(props, ["isNewSessionProperties"]);
    const prefixProps = Object.keys(cleanProps).reduce((acc, cur) => {
      if (!cleanProps[cur]) return acc;
      return {
        ...acc,
        [`rum_api_${cur}`]: {
          value: cleanProps[cur],
        },
      };
    }, {});
    ["airline", "tailNumber"].forEach((key) => setCookie(key, cleanProps[key]));
    window.dtrum.sendSessionProperties(null, null, prefixProps);
  } catch (err) {
    /**
     * Prevent site crash if Dynatrace lib fails to download
     * or dtum does not get injected.
     *  */
  }
};
