import {
  axios,
  getAirborneABPEdgeBaseUrl,
  transformAuthenticateCustomer,
} from "portal-services";
import { COOKIE_JWT_TOKEN, getCookie } from "portal-utilities";

/**
 * gets customer information
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getCustomer = ({ userName, uxdId, status }) => {
  const token = getCookie(COOKIE_JWT_TOKEN);
  return axios({
    method: "get",
    url: `v3/customer?data_types=PERSONAL,PMTINSTRUMENTS,GROUP_ATTRIBUTES&requester=GOGO_INTERNET&tracking_id=${uxdId}&user_name=${userName}&uxd_id=${uxdId}`,
    headers: {
      Authorization: token,
    },
    baseURL: getAirborneABPEdgeBaseUrl(status),
    transformResponse: [
      function (data) {
        return transformAuthenticateCustomer(data);
      },
    ],
  });
};
