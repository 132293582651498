import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
export const weatherReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WEATHER_SCALE":
      return {
        ...state,
        data: { ...state.data, isCelsius: action.payload },
      };
    case "TOGGLE_WEATHER_SCALE":
      return {
        ...state,
        data: { ...state.data, isCelsius: !state.data.isCelsius },
      };
    case "GET_WEATHER":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: true,
      };
    case "GET_WEATHER_SUCCESS":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: false,
      };
    case "GET_WEATHER_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
