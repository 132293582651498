import isPlainObject from "lodash/isPlainObject";

class DataModel {
  constructor(data) {
    this.displayName = "";
    this.data = isPlainObject(data) ? { ...data } : {};
  }

  validateProps(requiredProps, data = {}) {
    requiredProps.forEach(
      (prop) => (this.data[prop] = data[prop] ?? this.logError(prop))
    );
  }

  logError(prop) {
    console.warn(
      `WARNING: The analytics ${this.displayName} property "${prop}" does not have a value.`
    );
    return "";
  }

  toJSON() {
    return {
      ...this.data,
    };
  }
}

export default DataModel;
