import React from "react";
import { Provider } from "react-redux";

import RenderApp from "./app/renderApp";
import "./utilities/validations";
import { airlineStore as store } from "./store";

function Airline() {
  return (
    <Provider store={store}>
      <RenderApp />
    </Provider>
  );
}

export default Airline;
