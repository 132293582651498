import { call, put, takeLatest } from "redux-saga/effects";
import {
  getServiceStatus,
  getSessionInfo,
  postSessionState,
} from "portal-services";
import {
  isCaptiveApp,
  isCaptiveNonIOSBrowser,
  isChromeOS,
} from "portal-utilities";

/**
 * @param {Object} action
 */
// eslint-disable-next-line complexity
export function* statusSaga(action) {
  const meta = action.meta;

  switch (action.type) {
    case "GET_STATUS":
      try {
        const serviceStatus = yield call(getServiceStatus);

        let sessionInfo = null;

        /*
          if bypass enabled and url is present, preventing api call
          for getSessionInfo and postSessionInfo
        */

        if (!serviceStatus?.data?.bypassMaintenanceUrl) {
          sessionInfo = yield call(getSessionInfo);
          const captiveChrome = isChromeOS() && isCaptiveApp();
          const captiveNonIos = isCaptiveNonIOSBrowser() && isCaptiveApp();
          const isNewUser = sessionInfo?.data?.userStatus === "new";
          const linkStatus = serviceStatus?.data?.linkStatus;

          /**
           * Sets userStatus to "not_authenticated" if user is new and linkStatus is up and
           * is not in a Chromebook browser and not in a non-IOS captive browser.
           * Many non-IOS captive browsers need userStatus to remain as "new"
           * in order to enter the captive portal flow. IOS Captive Browsers will
           * get a the one-click experience when user is "not_authenticated" first.
           */
          if (isNewUser && !captiveChrome && !captiveNonIos && linkStatus) {
            yield call(postSessionState);
            sessionInfo = yield call(getSessionInfo);
          }
        }

        yield put({
          type: "GET_STATUS_SUCCESS",
          payload: {
            ...sessionInfo?.data,
            ...serviceStatus?.data,
          },
          meta: meta,
        });
      } catch (e) {
        yield put({ type: "GET_STATUS_FAIL", payload: e, meta });
      }
      return;
    case "SET_STATUS":
      yield put({
        type: "SET_STATUS_SUCCESS",
        payload: action.payload,
        meta: meta,
      });
      return;
  }
}

/**
 get status watcher used for testing purposes
 * @function getStatusWatcher
 */
export function* getStatusWatcher() {
  yield takeLatest("GET_STATUS", statusSaga);
}

/**
 set status watcher used for testing purposes
 * @function setStatusWatcher
 */
export function* setStatusWatcher() {
  yield takeLatest("SET_STATUS", statusSaga);
}
