import { axios, getAirborneBaseUrl, getGBPBaseUrl } from "portal-services";
import { URLS } from "portal-utilities";

export const getRedirectingPageUrl = ({ gbpCaptchaPassed, status, uxdId }) => {
  let encodedDomain = status.edgeCacheStatus
    ? btoa(`https://${URLS?.wifi}`)
    : btoa(`http://${URLS?.airborne}`);

  encodedDomain = encodedDomain.split("").reverse().join("");

  return axios({
    method: "POST",
    url: "/gbp/v1/splash",
    baseURL: status?.edgeCacheStatus
      ? getGBPBaseUrl(status)
      : getAirborneBaseUrl(status),
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      encodedDomain,
      gbpCaptchaPassed,
      uxdId,
    },
    transformResponse: [
      function (data) {
        return transformRedirectingPageUrl(data);
      },
    ],
  });
};

export const transformRedirectingPageUrl = (data) => {
  const response = JSON.parse(data);

  let output = {
    redirectUrl: response?.redirectUrl ?? null,
  };

  return output;
};
