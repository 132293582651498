import { axios } from "portal-services";

/**
 * gets session info for captive
 * @return {Promise}
 */
export const getSessionInfo = () => {
  return axios({
    method: "get",
    url: "/abp/rest/getSessionInfo",
    transformResponse: [
      function (data) {
        return transformSessionInfo(data);
      },
    ],
  });
};

/**
 * transforms session info to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformSessionInfo = (data) => {
  const response = JSON.parse(data);

  let output = {
    ipAddress: response?.ip_address,
    macAddress: response?.user_mac,
    userStatus: response?.state,
    product_code: response?.product_code,
  };

  return output;
};
