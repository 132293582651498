/**
 * get config redux action
 * @return {Object}
 */
export const getConfig = ({ airlineCode = "" }) => {
  return {
    type: "GET_CONFIG",
    payload: {
      airlineCode,
    },
    meta: {
      thunk: true,
    },
  };
};
