/* eslint complexity: ["error", 15] */

/**
 * creates label in native language for each locale
 * @param {string} locale
 * @param  {String} language
 * @param  {Number} characters
 * @return {String}
 */
export const formatLanguage = ({
  locale = null,
  language = "",
  characters = null,
}) => {
  if (!language) {
    return formatLanguageFallback({ locale, characters });
  }
  return formatLanguageCharacters({ language, characters });
};

/**
 * extracts first x characters from string
 * @param  {String} language
 * @param  {Number} characters
 * @return {String}
 */
const formatLanguageCharacters = ({ language = "", characters = null }) => {
  return characters
    ? language.substring(0, characters).toUpperCase()
    : language;
};

/**
 * creates label in native language for each locale
 * @function formatLanguageFallback
 * @param {string} locale
 * @returns {string} language
 */

export const formatLanguageFallback = ({
  locale = null,
  characters = null,
}) => {
  switch (locale) {
    case "ar-QA":
      return formatLanguageCharacters({ language: "لغة", characters });
    case "en-US":
      return formatLanguageCharacters({ language: "English", characters });
    case "de-DE":
      return formatLanguageCharacters({ language: "Deutsch", characters });
    case "es-BO":
      return formatLanguageCharacters({ language: "Español", characters });
    case "es-ES":
      return formatLanguageCharacters({ language: "Español", characters });
    case "es-MX":
      return formatLanguageCharacters({ language: "Español", characters });
    case "fr-CA":
      return formatLanguageCharacters({ language: "Français", characters });
    case "fr-FR":
      return formatLanguageCharacters({ language: "Français", characters });
    case "ja-JP":
      return formatLanguageCharacters({ language: "日本語", characters });
    case "ko-KR":
      return formatLanguageCharacters({ language: "한국어", characters });
    case "pt-BR":
      return formatLanguageCharacters({ language: "Português", characters });
    case "zh-CN":
      return formatLanguageCharacters({ language: "中文", characters });
    case "zh-HK":
      return formatLanguageCharacters({ language: "繁體中文", characters });
    default:
      return "Language";
  }
};
