import { isCmsDevelopmentThemeID, URLS } from "portal-utilities";

/**
 * generates http endpoint and handles local proxy
 * @param  {String}  hostname
 * @param  {String}  protocol
 * @param  {String}  url
 * @param  {Boolean} redirect
 * @return {String}
 */
export const httpEndpoint = (
  hostname,
  protocol = "https",
  url = "",
  redirect = false
) => {
  return process.env.NODE_ENV === "development" && !redirect
    ? url
    : `${protocol}://${hostname}${url}`;
};

/**
 * generates abp base url
 * @return {String}
 */
export const getAirborneBaseUrl = (status) => {
  return httpEndpoint(URLS?.airborne, status?.protocol, "");
};

/**
 * generates abp base url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneABPBaseUrl = (status) => {
  return httpEndpoint(status?.abpHostName, status?.protocol, "");
};

/**
 * generates abp base edge url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneABPEdgeBaseUrl = (status) => {
  return status?.edgeCacheStatus
    ? getGBPApiDecoratorBaseUrl(status)
    : httpEndpoint(status?.abpHostName, status?.protocol, "/edge/apidecorator");
};

/**
 * generates abp base edgecaching url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneABPEdgeCacheBaseUrl = (status) => {
  return httpEndpoint(status?.abpHostName, status?.protocol, "/abp/edge");
};

/**
 * generates airborne redirect base url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneRedirectBaseUrl = (status) => {
  return httpEndpoint(status?.abpHostName, status?.protocol, "", true);
};

/**
 * generates airborne edge base url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneEdgeBaseUrl = (status) => {
  return httpEndpoint(status?.abpHostName, status?.protocol, "/edge/cpapi");
};

/**
 * generates airborne media base url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneMediaBaseUrl = (status) => {
  return httpEndpoint(status?.mediaHostName, status?.protocol, "", true);
};

/**
 * generates airborne media asp base url
 * @param  {Object} status
 * @return {String}
 */
export const getAirborneMediaASPBaseUrl = (status) => {
  return httpEndpoint(
    status?.mediaHostName,
    status?.protocol,
    "/asp/api/media"
  );
};

/**
 * generates airborne secure base url
 * @return {String}
 */
export const getAirborneSecureBaseUrl = () => {
  return httpEndpoint(URLS?.airborneSecure, "https");
};

/**
 * generates airborne secure redirect base url
 * @return {String}
 */
export const getAirborneSecureRedirectBaseUrl = () => {
  return httpEndpoint(URLS?.airborneSecure, "https", "", true);
};

/**
 * generates logo Endpoint
 * @return {String}
 */
export const getUploadBaseUrl = (url) => {
  if (!url) return;
  if (
    process.env.NODE_ENV === "development" ||
    process.env.TEST === "cypress"
  ) {
    if (process.env.THEME_ID) {
      return isCmsDevelopmentThemeID()
        ? `/AirlineAssets/${url}`
        : `/uploads/${url}`;
    } else {
      const assetsLocation = `uploads/${url.split("/")[1]}`;
      return require(`@theme/${assetsLocation}`)?.default;
    }
  } else if (process.env.HAS_ABP_CONFIG === "true") {
    return `${getThemeBaseUrl()}/ui-config/uploads/${url}`;
  } else {
    return `${process.env.PUBLIC_URL}/configuration/${url}`;
  }
};

/**
 * generates theme configuration, dictionaries from the ACPUs
 * @return {String}
 */
export const getThemeBaseUrl = () => {
  return window.location.origin;
};

/**
 * generates cs video base url
 * @param  {Object} status
 * @return {String}
 */
export const getCSVideoBaseUrl = (status) => {
  return httpEndpoint(URLS?.csVideo, status?.protocol);
};

/**
 * generates aws.cloud base url
 * @return {String}
 */
export const getAWSCloudBaseUrl = (status) => {
  return process.env.NODE_ENV === "development"
    ? httpEndpoint(URLS?.nginx, "http")
    : httpEndpoint(URLS?.gbp, status?.protocol);
};

/**
 * generates gbp base url
 * @param  {Object} status
 * @return {String}
 */
export const getGBPBaseUrl = (status) => {
  return httpEndpoint(URLS?.gbp, status?.protocol, "");
};

/**
 * generates secure ground gateway base url
 * @param  {String} cookie
 * @return {String}
 */
export const getSecureGroundGatewayBaseUrl = (cookie) => {
  if (cookie === "dev") {
    return httpEndpoint(URLS?.secureGroundGatewayDev, "https", "");
  } else if (cookie === "stage") {
    return httpEndpoint(URLS?.secureGroundGatewayStage, "https", "");
  }
  return httpEndpoint(URLS?.secureGroundGatewayProd, "https", "");
};

/**
 * generates gbp api decorator base url
 * @param  {Object} status
 * @return {String}
 */
export const getGBPApiDecoratorBaseUrl = (status) => {
  return httpEndpoint(URLS?.gbp, status?.protocol, "/edge/apidecorator");
};

/**
 * generates wifi base url for airborne HTTPS
 * @return {String}
 */
export const getWifiBaseUrl = () => {
  return httpEndpoint(URLS?.wifi, "https", "");
};

/**
 * returns api mock url
 * @return {String}
 */
export const getApiMockUrl = () => {
  return "http://apimock.portalui.stage.gogoair.com";
};

/**
 * generates buy.wifionboard.com base url
 * @return {String}
 */
export const getBuyWifiOnboardBaseUrl = () => {
  return httpEndpoint(URLS?.groundportal, "https", "/ground/gateway", true);
};
