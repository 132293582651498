import { getProductDuration } from "portal-utilities";

/**
 * tranforms products to readable format.
 * @param {Object} data
 * @returns {Object}
 */
export const transformProductGround = ({ data }) => {
  const response = JSON.parse(data);
  let productdata = {};
  if (response) {
    const attributes = generateProductAttributes(response);

    productdata = {
      ...attributes,
      duration: getProductDuration(attributes),
    };
  }

  return {
    currency: response?.currency,
    entitlementProducts: [],
    products: productdata,
    isValid: !!productdata.productCode,
  };
};

/**
 * check product is FullDayPassProduct or not.
 * @param {Object} product
 * @returns {boolean}
 */
export const isFullDayPassProduct = (product) => {
  return product?.duration_hours === "24";
};

/**
 * check product is MSP or not.
 * @param {Object} product
 * @returns {boolean}
 */
export const isASubscriptionPlanProduct = (product) => {
  return (
    product?.product_type === "MONTHLY" || product?.product_type === "ANNUALSUB"
  );
};

/**
 * check product is 1hr or not.
 * @param {Object} product
 * @returns {boolean}
 */
export const isOneHrPass = (product) => {
  return product?.duration_minutes === "60";
};

const generateProductAttributes = (product) => {
  return {
    dataAutomation: `${product?.product_name
      .replaceAll(" ", "-")
      .toLowerCase()}-select`,
    durationHours: product?.duration_hours,
    durationMins: product?.duration_minutes,
    id: product?.product_id,
    isBOGONinetyMinPlan: parseInt(product?.duration_minutes) === 90,
    isBOGOOneHourPlan: parseInt(product?.duration_hours) === 1,
    isDomesticPlan: product?.redemption?.rdm_region === "DOM",
    isFullDayPass: isFullDayPassProduct(product),
    isFullFlightPass:
      product?.product_type === "SEGMENT" ||
      product?.duration_hours === "18" ||
      product?.duration_hours === "8",
    isGlobalPlan: product?.redemption?.rdm_region === "any",
    isHourlyPass: isOneHrPass(product),
    isMonthlySubscriptionPlan: isASubscriptionPlanProduct(product),
    isMultiDevicePass: parseInt(product?.redemption?.concr_devices) > 1,
    isOneDevicePass: parseInt(product?.redemption?.concr_devices) === 1,
    isStreamingDevicePass:
      product?.service_code === "STRMSD" && product?.product_type === "MONTHLY",
    isTwoDevicePass: parseInt(product?.redemption?.concr_devices) === 2,
    longDescription: product?.long_description,
    presentationPriority: parseInt(
      product?.presentation?.presentation_priority,
      10
    ),
    price: parseFloat(product?.price),
    productCode: product?.product_code,
    englishProductName: product?.ui_text,
    productName: product?.product_name,
    productType: product?.product_type,
    rdmRegion: product?.redemption?.rdm_region,
    rewardPoints: parseInt(product?.reward_points, 10) || null,
    serviceCode: product?.service_code,
    shortDescription: product?.short_description,
    totalDurationSeconds:
      parseInt(product?.duration_hours, 10) * 3600 +
      parseInt(product?.duration_minutes, 10) * 60,
  };
};
