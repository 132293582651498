/**
 * returns boolean if app is IFC app
 * @param  {String} locale
 * @return {Boolean}
 */
export const isIFCApp = () => process.env.APP === "ifc";

/**
 * returns boolean if app is IFC app route
 * @param  {String} locale
 * @return {Boolean}
 */
export const isRouteIFCApp = () => window.location.pathname.includes("app/ifc");
