import queryString from "query-string";

import { axios, loggerInterceptor, postCaptiveLog } from "portal-services";

import {
  COOKIE_CURRENCY,
  COOKIE_LOCALE,
  getCookie,
  importLocalePluralRules,
  importLocaleRelativeTimeFormat,
  LOCAL_STORAGE_QUERY,
  setDomainUrls,
} from "portal-utilities";
import { Analytics } from "portal-analytics";

/**
 * add react intl locale polyfills
 * @param {Array} locales
 */
const addLocales = async (locales) => {
  if (!Intl.PluralRules) {
    require("@formatjs/intl-pluralrules/polyfill");

    locales.forEach((locale) => {
      importLocalePluralRules(locale);
    });
  }

  if (!Intl.RelativeTimeFormat) {
    require("@formatjs/intl-relativetimeformat/polyfill");

    locales.forEach((locale) => {
      importLocaleRelativeTimeFormat(locale);
    });
  }
};

/**
 * adds axios interceptors for logging API calls and errors
 * @return {Object} response or error
 */

const addApiInterceptors = (...interceptors) => {
  // pass axios' instance to decorate it with interceptors
  interceptors.forEach((interceptor) => interceptor(axios));
};

const captiveInterceptors = (apiClient) => {
  apiClient.interceptors.response.use(null, (error) => {
    if (process.env.APP === "captive") {
      postCaptiveLog({ apiURL: error.response.config.url });
    }
    return Promise.reject(error);
  });
};

/**
 * airline application initializer
 * @param  {Object} config
 */
export const initGlobalApp = async ({ config }) => {
  document.title = config?.name || "Wi-Fi Onboard";

  setDomainUrls({
    domainConfigs: config?.domainConfigs,
    domainDefault: config?.domainDefault,
  });

  const airlineCode = config?.key;

  Analytics.init(airlineCode);

  addLocales(config?.locales);

  addApiInterceptors(
    captiveInterceptors,
    loggerInterceptor,
    Analytics.apiInterceptors.bind(null, airlineCode)
  );

  if (navigator.cookieEnabled) {
    const query = localStorage.getItem(LOCAL_STORAGE_QUERY);

    if (query) {
      let search = window.location.search;

      search = queryString.stringify({
        ...queryString.parse(search),
        ...queryString.parse(query),
      });

      window.history.pushState(null, null, "?" + search);
      localStorage.removeItem(LOCAL_STORAGE_QUERY);
    }
  }
};

/**
 * Generate locale on initial load of the page
 * @param {Object} config
 * @returns
 */
export const getLocale = ({ config }) => {
  let locale, localeCookie;
  let languages = config?.locales;
  const defaultLocale = config?.defaultLocale
    ? config?.defaultLocale
    : languages?.[0];

  if (navigator.cookieEnabled) {
    localeCookie = getCookie(COOKIE_LOCALE);
  }

  locale =
    localeCookie ||
    (config?.hasLanguageAutoSelect
      ? getBrowserLocal({ languages })
      : defaultLocale);

  if (!config?.locales?.includes(locale)) {
    locale = config?.locales?.[0];
  }

  return locale;
};

/**
 * Get Browser langager if match with our locales
 * @param {Object} config
 * @returns
 */
const getBrowserLocal = ({ languages }) => {
  let browserLanguage = navigator?.language;
  let checkLanguage = languages.find(
    (language) =>
      language === browserLanguage ||
      language.substring(0, 2) === browserLanguage.substring(0, 2)
  );
  return checkLanguage || languages?.[0];
};

/**
 * Generate currency on initial load of the page
 * @param {Object} config
 * @returns
 */
export const getCurrency = ({ config }) => {
  let currency, currencyCookie;

  if (navigator.cookieEnabled) {
    currencyCookie = getCookie(COOKIE_CURRENCY);
  }

  currency = currencyCookie || config?.currencies?.[0];

  if (!config?.currencies?.includes(currency)) {
    currency = config?.currencies?.[0];
  }

  return currency;
};
