/**
 * get intl redux action
 * @param  {String} locale
 * @return {Object}
 */
export const getIntl = ({ airlineCode, locale }) => {
  return {
    type: "GET_INTL",
    payload: {
      airlineCode,
      locale,
    },
    meta: {
      thunk: true,
    },
  };
};
