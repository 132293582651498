import AdapterInterface from "../AdapterInterface";

class NoopAdapter extends AdapterInterface {
  bootstrap() {}
  setup() {}
  track() {}
  static apiInterceptors() {}
}

export default NoopAdapter;
