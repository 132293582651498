import isUndefined from "lodash/isUndefined";
import { COOKIE_IS_DARK_MODE, getCookie } from "portal-utilities";

/**
 * checks both dark mode cookie or OS preference and if sunrise/sunset
 * @param {Object}
 * @return {Boolean}
 */

export const darkMode = (config, flightInfo) => {
  return getIsDarkOutside(flightInfo) || getIsDarkModePreference(config);
};

const getIsDarkOutside = (flightInfo = null) => {
  let isDark = false;
  if (flightInfo) {
    const current = flightInfo?.utcTime;
    const latitude = flightInfo?.current?.latitude;
    const longitude = flightInfo?.current?.longitude;
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    // TODO: handle edge case where start and end are in same day (e.g. late flights)
    let e1 = solar_events(today, latitude, longitude);
    const darkStart = e1.sunset;
    let e2 = solar_events(tomorrow, latitude, longitude);
    const darkEnd = e2.sunrise;
    // is current date between sunset and sunrise date
    isDark = darkStart < current && darkEnd > current;
  }
  return isDark;
};

const getIsDarkModePreference = (config = null) => {
  const isDarkModeCookie = getCookie(COOKIE_IS_DARK_MODE);
  const userPrefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
    .matches;

  if (
    isUndefined(isDarkModeCookie) &&
    userPrefersDarkMode &&
    config?.hasDarkMode
  ) {
    return true;
  }
  return isDarkModeCookie === "true";
};

const zeniths = {
  official: 90.833333,
  // 90deg 50'
  civil: 96,
  nautical: 102,
  astronomical: 108,
};

const solar_event = (date, latitude, longitude, rising, zenith) => {
  let year = date.getUTCFullYear(),
    month = date.getUTCMonth() + 1,
    day = date.getUTCDate();
  let floor = Math.floor,
    degtorad = function (deg) {
      return (Math.PI * deg) / 180;
    },
    radtodeg = function (rad) {
      return (180 * rad) / Math.PI;
    },
    sin = function (deg) {
      return Math.sin(degtorad(deg));
    },
    cos = function (deg) {
      return Math.cos(degtorad(deg));
    },
    tan = function (deg) {
      return Math.tan(degtorad(deg));
    },
    asin = function (x) {
      return radtodeg(Math.asin(x));
    },
    acos = function (x) {
      return radtodeg(Math.acos(x));
    },
    atan = function (x) {
      return radtodeg(Math.atan(x));
    },
    modpos = function (x, m) {
      return ((x % m) + m) % m;
    };
  // 1. first calculate the day of the year
  let N1 = floor((275 * month) / 9),
    N2 = floor((month + 9) / 12),
    N3 = 1 + floor((year - 4 * floor(year / 4) + 2) / 3),
    N = N1 - N2 * N3 + day - 30;
  // 2. convert the longitude to hour value and calculate an approximate time
  let lngHour = longitude / 15,
    t = N + ((rising ? 6 : 18) - lngHour) / 24;
  // 3. calculate the Sun's mean anomaly
  let M = 0.9856 * t - 3.289;
  // 4. calculate the Sun's true longitude
  let L = M + 1.916 * sin(M) + 0.02 * sin(2 * M) + 282.634;
  L = modpos(L, 360); // NOTE: L potentially needs to be adjusted into the range [0,360) by adding/subtracting 360
  // 5a. calculate the Sun's right ascension
  let RA = atan(0.91764 * tan(L));
  RA = modpos(RA, 360); // NOTE: RA potentially needs to be adjusted into the range [0,360) by adding/subtracting 360
  // 5b. right ascension value needs to be in the same quadrant as L
  let Lquadrant = floor(L / 90) * 90,
    RAquadrant = floor(RA / 90) * 90;
  RA = RA + (Lquadrant - RAquadrant);
  // 5c. right ascension value needs to be converted into hours
  RA = RA / 15;
  // 6. calculate the Sun's declination
  let sinDec = 0.39782 * sin(L),
    cosDec = cos(asin(sinDec));
  // 7a. calculate the Sun's local hour angle
  let cosH = (cos(zenith) - sinDec * sin(latitude)) / (cosDec * cos(latitude));
  let H;
  if (cosH > 1) {
    return undefined; // the sun never rises on this location (on the specified date)
  } else if (cosH < -1) {
    return undefined; // the sun never sets on this location (on the specified date)
  }
  // 7b. finish calculating H and convert into hours
  if (rising) {
    H = 360 - acos(cosH);
  } else {
    H = acos(cosH);
  }
  H = H / 15;
  // 8. calculate local mean time of rising/setting
  let T = H + RA - 0.06571 * t - 6.622;
  // 9. adjust back to UTC
  let UT = T - lngHour;
  UT = modpos(UT, 24); // NOTE: UT potentially needs to be adjusted into the range [0,24) by adding/subtracting 24
  let hours = floor(UT),
    minutes = Math.round(60 * (UT - hours));
  let result = new Date(Date.UTC(year, month - 1, day, hours, minutes));
  return result;
};

const sunrise = (date, latitude, longitude, type) => {
  let zenith = zeniths[type] || zeniths["official"];
  return solar_event(date, latitude, longitude, true, zenith);
};
const sunset = (date, latitude, longitude, type) => {
  let zenith = zeniths[type] || zeniths["official"];
  return solar_event(date, latitude, longitude, false, zenith);
};

const solar_events = (date, latitude, longitude) => {
  return {
    dawn: sunrise(date, latitude, longitude, "civil"),
    sunrise: sunrise(date, latitude, longitude, "official"),
    sunset: sunset(date, latitude, longitude, "official"),
    dusk: sunset(date, latitude, longitude, "civil"),
  };
};
