import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./_index.module.scss";

const CookiesRequired = () => (
  <div className="app">
    <div className="app-content">
      <div className={styles["cookies-required"]}>
        <h4>
          <FormattedMessage
            id="pages.cookies_required.header"
            defaultMessage="Cookies Required"
          />
        </h4>
        <p>
          <FormattedMessage
            id="pages.cookies_required.description"
            defaultMessage="We're sorry, but a Wi-Fi Onboard inflight internet session requires your browser to accept cookies. Please select 'accept cookies from sites' in your browser preferences, then try again."
          />
        </p>
      </div>
    </div>
  </div>
);

export default CookiesRequired;
