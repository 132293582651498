import { ROUTE_IFC_SPLASH } from "portal-utilities";

const getSplashLoadTime = (user) => {
  if (
    window.location.pathname.includes(ROUTE_IFC_SPLASH) &&
    !user?.activePass
  ) {
    window.performance.mark("GET_PRODUCTS_SUCCESS");
    const getProductsSuccess = window.performance.getEntriesByName(
      "GET_PRODUCTS_SUCCESS"
    );
    return `splashLoadTime: ${getProductsSuccess?.[0]?.startTime}`;
  }
};

export default getSplashLoadTime;
