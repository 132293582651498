import { axios, getAirborneABPBaseUrl } from "portal-services";

/**
 * activates user session (pass)
 * @param  {String} options.uxdId
 * @param  {String} options.status
 * @param  {String} options.state
 * @return {Promise}
 */

export const activateUserSession = async ({
  hasSwitchSession,
  status,
  state,
  uxdId,
}) => {
  if (hasSwitchSession) {
    const addStateValue = `${state ? `&state=${state}` : ""}`;
    const headers = {
      "X-Client-Id": "IFC",
      "X-Request-ID": uxdId,
      "X-API-Key": "IFC",
    };

    try {
      return await axios({
        method: "post",
        url: `/abp/v1/session/activate?gbpsessionid=${uxdId}${addStateValue}`,
        baseURL: getAirborneABPBaseUrl(status),
        headers,
      });
    } catch (e) {
      await axios({
        method: "post",
        url: `/abp/v1/session/activate`,
        baseURL: getAirborneABPBaseUrl(status),
        headers,
        data: {
          gbp_session_id: `${uxdId}`,
          state: `${state ? `${state}` : ""}`,
        },
      });
    }
  } else {
    await axios({
      method: "get",
      url: `/abp/rest/aaa/activate?gbpsessionid=${uxdId}`,
      baseURL: getAirborneABPBaseUrl(status),
    });
  }
};
