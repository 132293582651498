/**
 * @const {String}
 */
export const LOCAL_STORAGE_IS_CELSIUS = "IS_CELSIUS";

/**
 * @const {String}
 */
export const LOCAL_STORAGE_QUERY = "QUERY";

/**
 * @const {String}
 */
export const HAS_DYNATRACE_API_TRACKING = "has_dynatrace_api_tracking";
