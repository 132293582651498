import { COOKIE_JSESSIONID, Cookies, getCookieDomain } from "portal-utilities";

export const getGlobalWindow = () => {
  return window;
};

export const getLogger = () => {
  return Logger;
};

/**
 * Returns flight data in the format required for Adobe logging.
 * @param {object} reduxState
 * @returns {object} adobe info to be appended to
 */
export const getAnalyticsInfo = ({ flightInfo, user, intl, products }) => {
  const flightInfoData = flightInfo?.data;
  const userData = user?.data;
  const url = getGlobalWindow().location.href;

  return {
    pageURL: url,
    connectivity: flightInfo?.data?.system?.is2KU ? "2KU" : "ATG",
    uxdId: userData?.uxdId,
    jsessionid: Cookies.get(COOKIE_JSESSIONID, { domain: getCookieDomain() }),
    airlineCode: flightInfoData?.airlineCode,
    flightNumber: flightInfoData?.flightNumber,
    tailNumber: flightInfoData?.tailNumber,
    flightDetails: `${flightInfoData?.departure?.airportCode}to${flightInfoData?.arrival?.airportCode}`,
    mac: flightInfoData?.userData?.macAddress,
    language: intl?.locale,
    currency: products?.data?.currency,
    abpVersion: flightInfoData?.abpVersion,
    activeSession: userData?.activePass,
    acftType: flightInfoData?.aircraftType,
    eventSource: url,
    userAgent: getGlobalWindow().navigator.userAgent,
  };
};

/**
 * Returns flight data in the format required for Adobe/Ground logging.
 * @param {object} reduxState
 * @returns {object} flight data to be logged
 */
export const getFlightInfo = (flightInfo) => {
  return {
    airlineCode: flightInfo?.data?.airlineCode,
    airlineCodeIata: flightInfo?.data?.airlineCodeIata,
    departureCity: flightInfo?.data?.departure?.city,
    departureAirportCode: flightInfo?.data?.departure?.airportCodeIcao,
    departureAirportCodeIata: flightInfo?.data?.departure?.airportCode,
    destinationAirportCode: flightInfo?.data?.arrival?.airportCodeIcao,
    destinationAirportCodeIata: flightInfo?.data?.arrival?.airportCode,
    expectedArrival: flightInfo?.data?.expectedArrival,
    flightNumberInfo: flightInfo?.data?.flightNumberInfo,
    flightNumAlpha: flightInfo?.data?.flightNumAlpha,
    tailNumber: flightInfo?.data?.tailNumber,
    macAddress: flightInfo?.data?.user?.macAddress,
    acftType: flightInfo?.data?.aircraftType,
    departureName: flightInfo?.data?.departure?.name,
    departureCountry: flightInfo?.data?.departure?.country,
    departureZip: flightInfo?.data?.departure?.zip,
    departureTime: flightInfo?.data?.departure?.time,
    departureLat: flightInfo?.data?.departure?.latitude,
    departureLong: flightInfo?.data?.departure?.longitude,
    destinationName: flightInfo?.data?.arrival?.name,
    destinationState: flightInfo?.data?.arrival?.state,
    destinationCountry: flightInfo?.data?.arrival?.country,
    destinationZip: flightInfo?.data?.arrival?.zip,
    destinationLat: flightInfo?.data?.arrival?.latitude,
    destinationLong: flightInfo?.data?.arrival?.longitude,
    expectedArrivalTime: flightInfo?.data?.expectedArrival,
    flightNum: flightInfo?.data?.flightNumber,
    connectivityType: flightInfo?.data?.system?.is2KU ? "2KU" : "ATG",
  };
};
