/**
 * get active switch device redux action
 * @param {String} uxdId
 * @param {String} status
 * @return {Object}
 */

export const getActiveDevicePin = ({ uxdId, status }) => {
  return {
    type: "GET_ACTIVE_DEVICE_PIN",
    payload: { uxdId, status },
    meta: {
      thunk: true,
    },
  };
};
