import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCatalog,
  getIntl,
  getProducts,
  getWeather,
} from "portal-state-management";
import { Select } from "portal-components";
import {
  COOKIE_LOCALE,
  Cookies,
  formatLanguage,
  getCookieDomain,
  getIsCelsius,
  importFlag,
  isIFCApp,
  isIFEApp,
  isLocalhostApp,
  isPaymentApp,
} from "portal-utilities";

import "./_index.scss";

class LanguageSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocaleOption: {
        value: props?.intl?.locale,
        label: formatLanguage({
          locale: props?.intl?.locale,
          language:
            props?.intl?.messages[`language_name_label.${props?.intl?.locale}`],
          characters: this.props?.characters,
        }),
      },
      localeOptions: props?.config?.data?.locales?.map((locale) => {
        return {
          value: locale,
          label: formatLanguage({
            locale: locale,
            language: props?.intl?.messages[`language_name_label.${locale}`],
            characters: this.props?.characters,
          }),
        };
      }),
    };
  }

  dispatchCatalog = (locale) => {
    const { dispatch, status } = this.props;

    if (
      (isIFEApp() || isIFCApp() || isLocalhostApp()) &&
      status?.data?.ifeStatus
    ) {
      dispatch(getCatalog({ locale, status: status?.data }));
    }
  };

  dispatchProducts = (locale) => {
    const { config, dispatch, flightInfo, products, status, user } = this.props;
    if (isIFCApp() || isPaymentApp() || isLocalhostApp()) {
      dispatch(
        getProducts({
          airlineCode: flightInfo?.data?.airlineCode,
          connectivityType: flightInfo?.data?.system?.type,
          currency: products?.data?.currency,
          config: config?.data,
          departureCodeIcao: flightInfo?.data?.departure?.airportCodeIcao,
          destinationCodeIcao: flightInfo?.data?.arrival?.airportCodeIcao,
          flightNumber: flightInfo?.data?.flightNumber,
          locale,
          region: flightInfo?.data?.arrival?.region,
          status: status?.data,
          tailNumber: flightInfo?.data?.tailNumber,
          uxdId: user?.data?.uxdId,
          user: user?.data,
        })
      );
    }
  };

  dispatchWeather = (locale) => {
    const { config, dispatch, flightInfo, status } = this.props;

    if (isIFCApp() || isLocalhostApp()) {
      dispatch(
        getWeather({
          airportCode: flightInfo?.data?.arrival?.airportCode,
          airportCodeIcao: flightInfo?.data?.arrival?.airportCodeIcao,
          isCelsius: getIsCelsius(config?.data?.isCelsiusDefault),
          locale,
          status: status?.data,
        })
      );
    }
  };

  selectLocaleOption = (locale) => {
    const { dispatch, intl, status } = this.props;

    dispatch(getIntl({ locale }));

    if (!status.error) {
      this.dispatchCatalog(locale);
      this.dispatchProducts(locale);
      this.dispatchWeather(locale);
    }

    Cookies.set(COOKIE_LOCALE, locale, {
      domain: getCookieDomain(),
    });

    this.setState({
      selectedLocaleOption: {
        value: locale,
        label: formatLanguage({
          locale: locale,
          language: intl?.messages[`language_name_label.${locale}`],
          characters: this.props?.characters,
        }),
      },
    });
  };

  render() {
    const { characters, hasFlag } = this.props;
    const { selectedLocaleOption, localeOptions } = this.state;
    const flag = importFlag(selectedLocaleOption?.value);

    return (
      <div className={`language-select ${characters ? "capitalize" : ""}`}>
        {hasFlag && <img src={flag} alt="flag" />}

        <Select
          value={selectedLocaleOption?.value}
          onChange={this.selectLocaleOption}
          options={localeOptions}
        />
      </div>
    );
  }
}

LanguageSelect.propTypes = {
  /**
   * Number of characters in language string.
   */
  characters: PropTypes.number,
  config: PropTypes.object,
  dispatch: PropTypes.func,
  flightInfo: PropTypes.object,
  /**
   * Includes flag image (optional).
   */
  hasFlag: PropTypes.bool,
  intl: PropTypes.object,
  products: PropTypes.object,
  status: PropTypes.object,
  user: PropTypes.object,
};

LanguageSelect.defaultProps = {
  characters: null,
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    flightInfo: state.flightInfo,
    intl: state.intl,
    products: state.products,
    status: state.status,
    user: state.user,
  };
};

export default connect(mapStateToProps)(LanguageSelect);
