export const DEFAULT_DATA = "DEFAULT_DATA";
export const PAGE_EVENT = "PAGE_EVENT";
export const CLICK_EVENT = "CLICK_EVENT";
export const LOG_EVENT = "LOG_EVENT";

export const APP_INFO = "appinfo";
export const PRODUCT = "product";
export const UPGRADE_PRODUCT = "upgrade product";

const CAPTIVE = "CAPTIVE";

export const NO_ANALYTICS_FOR_THESE_APPS = [CAPTIVE];

export const AIRLINE_CODES = {
  QTR: "QTR",
  UAL: "UAL",
};

export const LOG_LEVELS = {
  INFO: "info",
  ERROR: "error",
};

export const PAGE_URLS = {
  FEEDBACK: "/ifc/feedback",
  GOBROWSE: "/ifc/gobrowse",
  PARTNERS: "/ifc/partners",
  PASS_DETAILS: "/ifc/pass_details",
  PAYMENT: "/payment",
  PAYMENT_CHECKOUT: "/payment/checkout",
  PAYMENT_CREATE_ACCOUNT: "/payment/create_account",
  PAYMENT_PARTNER_LOGIN: "/payment/partner_login",
  PAYMENT_SIGN_IN: "/payment/sign_in",
  SPLASH: "/ifc/splash",
  SUPPORT: "/ifc/support",
  SWITCH_DEVICE: "/ifc/switch_device",
};

export const PAGE_NAMES = {
  [PAGE_URLS.FEEDBACK]: "Portal | IFC | Feedback",
  [PAGE_URLS.GOBROWSE]: "Portal | IFC | GoBrowse",
  [PAGE_URLS.PARTNERS]: "Portal | IFC | Partners",
  [PAGE_URLS.PASS_DETAILS]: "Portal | IFC | Pass Details",
  [PAGE_URLS.PAYMENT]: "Portal | Payment",
  [PAGE_URLS.PAYMENT_CHECKOUT]: "Portal | Payment | Checkout",
  [PAGE_URLS.PAYMENT_CREATE_ACCOUNT]: "Portal | Payment | Create Account",
  [PAGE_URLS.PAYMENT_PARTNER_LOGIN]: "Portal | Payment | Partner Login",
  [PAGE_URLS.PAYMENT_SIGN_IN]: "Portal | Payment | Sign In",
  [PAGE_URLS.SPLASH]: "Portal | IFC | Splash",
  [PAGE_URLS.SUPPORT]: "Portal | IFC | Support",
  [PAGE_URLS.SWITCH_DEVICE]: "Portal | IFC | Switch Device",
};

export const DEFAULT_PAGE_NAME = "Portal";
