import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import { COOKIE_JWT_TOKEN, getCookie } from "portal-utilities";

/**
 * activates user subscription
 * @param  {String} options.serviceId
 * @param  {String} options.userName
 * @param  {String} options.uxdId
 * @param  {String} options.status
 * @return {Promise}
 */
export const activateLoyaltySubscription = ({
  serviceId,
  userName,
  uxdId,
  status,
}) => {
  const token = getCookie(COOKIE_JWT_TOKEN);

  return axios({
    method: "post",
    url: "/v4/subscription/activation/",
    headers: {
      Authorization: token,
    },
    baseURL: getAirborneABPEdgeBaseUrl(status),
    data: {
      service_id: serviceId,
      user: userName,
      uxdid: uxdId,
    },
  });
};
