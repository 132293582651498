import { put, takeLatest } from "redux-saga/effects";

/**
 * @param {Object} action
 */
export function* captiveSaga(action) {
  const meta = action.meta;

  yield put({
    type: "GET_CAPTIVE_SUCCESS",
    payload: action.payload,
    meta: meta,
  });
}

/**
 * get captive watcher used for testing purposes
 */
export function* getCaptiveWatcher() {
  yield takeLatest("GET_CAPTIVE", captiveSaga);
}
