import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { middleware as thunkMiddleware } from "redux-saga-thunk";
import { all, takeLatest } from "redux-saga/effects";

import {
  catalogReducer,
  catalogSaga,
  configReducer,
  configSaga,
  flightInfoReducer,
  flightInfoSaga,
  intlReducer,
  intlSaga,
  productsReducer,
  productsSaga,
  statusReducer,
  statusSaga,
  switchDeviceReducer,
  switchDeviceSaga,
  themeReducer,
  themeSaga,
  toastsReducer,
  userReducer,
  userWatcher,
  weatherReducer,
  weatherSaga,
} from "portal-state-management";

import { captiveSaga } from "../state_management/captive/saga.js";
import { groundSaga } from "../state_management/ground/saga.js";
import { inFlightSaga } from "../state_management/in_flight/saga.js";

function* rootSaga() {
  yield all([
    takeLatest("GET_CATALOG", catalogSaga),
    takeLatest("GET_CONFIG", configSaga),
    takeLatest("GET_CAPTIVE", captiveSaga),
    takeLatest("GET_GROUND", groundSaga),
    takeLatest("GET_IN_FLIGHT", inFlightSaga),
    takeLatest("GET_INTL", intlSaga),
    takeLatest("GET_PRODUCTS", productsSaga),
    takeLatest("GET_FLIGHT_INFO", flightInfoSaga),
    takeLatest("UPDATE_FLIGHT_INFO", flightInfoSaga),
    takeLatest("SET_STATUS", statusSaga),
    takeLatest("GET_STATUS", statusSaga),
    takeLatest("GET_ACTIVE_DEVICE_PIN", switchDeviceSaga),
    takeLatest("GET_THEME", themeSaga),
    takeLatest("GET_WEATHER", weatherSaga),
    userWatcher(),
  ]);
}

export const rootReducer = combineReducers({
  catalog: catalogReducer,
  config: configReducer,
  flightInfo: flightInfoReducer,
  intl: intlReducer,
  products: productsReducer,
  status: statusReducer,
  switchDevice: switchDeviceReducer,
  theme: themeReducer,
  toasts: toastsReducer,
  user: userReducer,
  weather: weatherReducer,
});

// CREATE SAGA MIDDLEWARE

const sagaMiddleware = createSagaMiddleware();

// REDUX DEV TOOLS

let reduxDevTools;

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__();
} else {
  reduxDevTools = (x) => x;
}

// CREATE REDUX STORE W/ REDUCER AND MIDDLEWARE

export const airlineStore = createStore(
  rootReducer,
  compose(applyMiddleware(thunkMiddleware, sagaMiddleware), reduxDevTools)
);

// CREATE MOCK REDUX STORE W/ REDUCER AND MIDDLEWARE

export const airlineMockStore =
  process.env.NODE_ENV === "development" || process.env.PUBLIC_URL === "." // TREE SHAKES MOCK STORE FROM NORMAL BUILDS
    ? createStore(
        rootReducer,
        require("./__fixtures__/mockReducer").mockReducer
      )
    : null;

// RUN SAGA

sagaMiddleware.run(rootSaga);
