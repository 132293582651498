import {
  LEGAL_CONTENT_APP_NAME,
  ROUTE_ACCOUNT,
  ROUTE_CAPTIVE,
  ROUTE_IFC,
  ROUTE_IFE,
  ROUTE_LEGAL_CONTENT,
  ROUTE_OAUTH,
  ROUTE_PAYMENT,
} from "portal-utilities";

export const getDefaultRoute = (defaultRoute = ROUTE_IFC) => {
  const routes = {
    account: ROUTE_ACCOUNT,
    captive: ROUTE_CAPTIVE,
    ifc: ROUTE_IFC,
    ife: ROUTE_IFE,
    oauth: ROUTE_OAUTH,
    payment: ROUTE_PAYMENT,
    [LEGAL_CONTENT_APP_NAME]: ROUTE_LEGAL_CONTENT,
  };

  return routes[process.env.APP] || defaultRoute;
};
