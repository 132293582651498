import {
  getConfig,
  getFlightInfo,
  getIntl,
  getProducts,
  getTheme,
  getUser,
  setStatus,
} from "portal-state-management";
import { applyTargetTheme, loadThemeStyles, URLS } from "portal-utilities";
import { getCurrency, getLocale, initGlobalApp } from "../index";

/**
 * Payment initializer
 * @param {*} dispatch
 * @returns
 */
export const initPaymentApp = async ({ dispatch }) => {
  const serviceStatus = {
    abpHostName: URLS?.airborneSecure,
    protocol: "https",
    serviceStatus: true,
  };

  let flightInfo = null;

  flightInfo = await dispatch(
    getFlightInfo({
      status: serviceStatus,
    })
  );

  if (!flightInfo || !flightInfo?.tailNumber) {
    flightInfo = await dispatch(
      getFlightInfo({
        isTailNumberApi: true,
        status: serviceStatus,
      })
    );
  }

  const airlineCode = flightInfo?.airlineCode?.toLowerCase();

  const config = await dispatch(getConfig({ airlineCode }));

  await initGlobalApp({ config: config?.data });

  let locale = await getLocale({ config: config?.data });

  let currency = await getCurrency({ config: config?.data });

  await dispatch(getIntl({ airlineCode, locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(
      getTheme({
        airlineCode,
        isTargetTheme: applyTargetTheme({ config, flightInfo }),
      })
    );
    loadThemeStyles(theme);
  }

  await dispatch(setStatus({ ...serviceStatus }));
  const user = await dispatch(
    getUser({ config: config?.data, locale, status: serviceStatus })
  );

  try {
    await dispatch(
      getProducts({
        airlineCode: flightInfo?.airlineCode,
        connectivityType: flightInfo?.system?.type,
        currency,
        config: config?.data,
        departureCodeIcao: flightInfo?.departure?.airportCodeIcao,
        destinationCodeIcao: flightInfo?.arrival?.airportCodeIcao,
        flightNumber: flightInfo?.flightNumber,
        locale,
        region: flightInfo?.arrival?.region,
        status: serviceStatus,
        tailNumber: flightInfo?.tailNumber,
        uxdId: user?.uxdId,
        user,
      })
    );
  } catch (e) {
    await dispatch(
      getProducts({
        airlineCode: flightInfo?.airlineCode,
        connectivityType: flightInfo?.system?.type,
        currency,
        config: {},
        departureCodeIcao: flightInfo?.departure?.airportCodeIcao,
        destinationCodeIcao: flightInfo?.arrival?.airportCodeIcao,
        flightNumber: flightInfo?.flightNumber,
        locale,
        region: flightInfo?.arrival?.region,
        status: serviceStatus,
        tailNumber: flightInfo?.tailNumber,
        uxdId: user?.uxdId,
        user,
      })
    );
  }
};

/**
 * Payment initializer status
 * @param {*} props
 * @returns
 */
export const isPaymentInitialized = (props) => {
  // TODO: Check for error.
  if (
    props?.flightInfo?.isLoading === false &&
    props?.config?.isLoading === false &&
    props?.theme?.isLoading === false
  ) {
    return true;
  }

  return false;
};
