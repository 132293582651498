/**
 * returns boolean if app is Payment app
 * @param  {String} locale
 * @return {Boolean}
 */
export const isPaymentApp = () => process.env.APP === "payment";

/**
 * returns boolean if app is Payment route app
 * @param  {String} locale
 * @return {Boolean}
 */
export const isRoutePaymentApp = () =>
  window.location.pathname.includes("app/payment");
