import { call, put } from "redux-saga/effects";
import { trackCustomEventOnDynatrace } from "portal-analytics/src/services";
import { abpLogger, getCatalog, getCatalogLocales } from "portal-services";

/**
 * @param {Object} action
 */
export function* catalogSaga(action) {
  const meta = action.meta;
  const { flightInfo, locale, status } = action.payload;

  try {
    let locales = yield call(getCatalogLocales, status);

    try {
      let localeCatalog;
      locales.data.includes(locale)
        ? (localeCatalog = locale)
        : (localeCatalog = locales.data[0]);

      const response = yield call(getCatalog, {
        flightInfo,
        locale: localeCatalog,
        status,
      });

      yield put({
        type: "GET_CATALOG_SUCCESS",
        payload: response,
        meta: meta,
      });
    } catch (e) {
      yield put({ type: "GET_CATALOG_FAIL", payload: e, meta });
    }
  } catch (e) {
    yield put({ type: "GET_CATALOG_FAIL", payload: e, meta });
    abpLogger({
      message: "IFE Default - Localelist failed",
      flightInfo,
    });
    trackCustomEventOnDynatrace("IFE Default - Localelist Failure");
  }
}
