import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { LanguageSelect } from "portal-components";
import { changeDocumentTitle, isRTL } from "portal-utilities";

import styles from "./_index.module.scss";

const ServiceNotAvailable = ({ intl }) => {
  changeDocumentTitle("Service not available");

  return (
    <div className="app" dir={isRTL(intl.locale) ? "rtl" : ""}>
      <div className="app-content">
        <div className={styles["service-not-available"]}>
          <div
            className={styles["service-not-available-language"]}
            data-automation="service-not-available"
          >
            <LanguageSelect hasFlag isNative />
          </div>
          <p>
            <FormattedMessage
              id="pages.service_not_available.description"
              defaultMessage="Our inflight experience has been interrupted. Thank you for your patience."
            />
          </p>
        </div>
      </div>
    </div>
  );
};

ServiceNotAvailable.propTypes = {
  intl: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    intl: state.intl,
  };
};

export default connect(mapStateToProps)(ServiceNotAvailable);
