import { axios, getAirborneABPBaseUrl, getWifiBaseUrl } from "portal-services";
import { getHeaders, isPaymentApp } from "portal-utilities";

/**
 * gets new uxdId for user
 * @param  {Object} status
 * @return {Promise}
 */
export const getSessionID = ({ config, status }) => {
  if (config?.isReduceSplashLoadTime) {
    return axios({
      method: "post",
      url: "/abp/v1/session",
      baseURL: isPaymentApp()
        ? getWifiBaseUrl()
        : getAirborneABPBaseUrl(status),
      headers: getHeaders(),
      transformResponse: [
        function (data) {
          return transformUxdID(data);
        },
      ],
    });
  } else {
    return axios({
      method: "get",
      url: "/abpRoaming",
      baseURL: isPaymentApp()
        ? getWifiBaseUrl()
        : getAirborneABPBaseUrl(status),
      transformResponse: [
        function (data) {
          return transformSessionID(data);
        },
      ],
    });
  }
};

/**
 * tranforms session ID to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformSessionID = (data) => {
  const response = JSON.parse(data);

  let output = {
    uxdId: response?.gbpSessionId || null,
    redirectionUrl: response?.bypassMaintenanceUrl || null,
  };

  return output;
};

/**
 * tranforms uxd ID to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformUxdID = (data) => {
  const response = JSON.parse(data);

  let output = {
    uxdId: response?.gbp_session_id || null,
    redirectionUrl: response?.bypass_maintenance_url || null,
  };

  return output;
};
