import isEqual from "lodash/isEqual";
import {
  abpLogger,
  axios,
  getAirborneABPBaseUrl,
  getAirborneSecureBaseUrl,
  getWifiBaseUrl,
} from "portal-services";
import {
  COOKIE_AIRPORT_LATLON_INVALID,
  COOKIE_FLIGHT_TRACKER_STORED,
  COOKIE_UXDID,
  getCookie,
  removeCookie,
  setCookie,
  URLS,
} from "portal-utilities";
import { trackCustomEventOnDynatrace } from "portal-analytics/src/services";

/**
 * gets flight information, including airport codes, altitude, departure/arrival times
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getStatusTray = ({
  config = {},
  isTailNumberApi = false,
  status,
}) => {
  let url;
  let transformData;
  let baseURL;
  const isPayment = status.abpHostName === URLS?.airborneSecure;
  const requiresFig2 = config?.requiresFig2;

  if (isPayment && isTailNumberApi) {
    url = "/flightInfo/v1/client/tail/tailNumber";
    baseURL = getAirborneSecureBaseUrl(status);
    transformData = transformFlightInfo;
  } else if (requiresFig2) {
    url = "/abp/v2/statusTray?fig2=true";
    baseURL = isPayment ? getWifiBaseUrl() : getAirborneABPBaseUrl(status);
    transformData = transformStatusTrayFig2;
  } else {
    url = "/abp/v2/statusTray";
    baseURL = isPayment ? getWifiBaseUrl() : getAirborneABPBaseUrl(status);
    transformData = transformStatusTray;
  }

  return axios({
    method: "get",
    url,
    baseURL,
    transformResponse: [
      function (data) {
        return transformData(data);
      },
    ],
  });
};

/**
 * tranforms status tray with Fig2 to readable format
 * @param  {Object} data
 * @return {Object}
 */
/* eslint-disable complexity */
export const transformStatusTrayFig2 = (data) => {
  const { Response } = JSON.parse(data);

  const { flightInfo, flightInfoFIG2, serviceInfo, systemInfo } = Response;

  let output = {
    abpVersion: flightInfo?.abpVersion,
    acpuVersion: flightInfo?.acpuVersion,
    airlineCode: flightInfo?.airlineCode,
    airlineCodeIata: flightInfo?.airlineCodeIata,
    tailNumber: flightInfo?.tailNumber,
    flightNumber:
      flightInfoFIG2?.flight?.flight_identifier || flightInfo?.flightNumberInfo,
    system: {
      is2KU: systemInfo?.systemType === "2KU",
      isSATCOM:
        systemInfo?.systemType === "2KU" || systemInfo?.systemType === "KU",
      type: systemInfo?.systemType,
      ifcPaxServiceState: systemInfo?.ifcPaxServiceState === "UP" ?? false,
    },
    current: {
      latitude: flightInfo?.latitude,
      longitude: flightInfo?.longitude,
    },
    arrival: {
      airportCode:
        flightInfoFIG2?.arrival?.airport?.iata ||
        flightInfo?.destinationAirportCodeIata ||
        flightInfoFIG2?.arrival?.airport?.icao,
      city: flightInfoFIG2?.arrival?.airport?.mailing_address?.municipality,
      latitude:
        flightInfoFIG2?.arrival?.airport?.location?.latitude ||
        flightInfo?.destinationAirportLatitude,
      longitude:
        flightInfoFIG2?.arrival?.airport?.location?.longitude ||
        flightInfo?.destinationAirportLongitude,
      region: flightInfoFIG2?.arrival?.airport?.attributes?.product_region,
      time: flightInfoFIG2?.arrival?.time?.computed,
      timeZoneOffset: flightInfoFIG2?.arrival?.airport?.time_zone_offset,
      airportCodeIcao:
        flightInfoFIG2?.arrival?.airport?.icao ||
        flightInfo?.destinationAirportCode,
      state:
        flightInfoFIG2?.arrival?.airport?.mailing_address?.administrative_area,
      name: flightInfoFIG2?.arrival?.airport?.name,
      country: flightInfoFIG2?.arrival?.airport?.mailing_address?.country,
      zip: flightInfoFIG2?.arrival?.airport?.mailing_address?.postal_code,
    },
    departure: {
      airportCode:
        flightInfoFIG2?.departure?.airport?.iata ||
        flightInfo?.departureAirportCodeIata ||
        flightInfoFIG2?.departure?.airport?.icao,
      city: flightInfoFIG2?.departure?.airport?.mailing_address?.municipality,
      latitude:
        flightInfoFIG2?.departure?.airport?.location?.latitude ||
        flightInfo?.departureAirportLatitude,
      longitude:
        flightInfoFIG2?.departure?.airport?.location?.longitude ||
        flightInfo?.departureAirportLongitude,
      time: flightInfoFIG2?.departure?.time?.computed,
      timeZoneOffset: flightInfoFIG2?.departure?.airport?.time_zone_offset,
      airportCodeIcao:
        flightInfoFIG2?.departure?.airport?.icao ||
        flightInfo?.departureAirportCode,
      state:
        flightInfoFIG2?.departure?.airport?.mailing_address
          ?.administrative_area,
      name: flightInfoFIG2?.departure?.airport?.name,
      country: flightInfoFIG2?.departure?.airport?.mailing_address?.country,
      zip: flightInfoFIG2?.departure?.airport?.mailing_address?.postal_code,
    },
    user: {
      ipAddress: Response?.ipAddress,
      macAddress: Response?.macAddress,
      deviceId: Response?.device_iid,
      activePass: serviceInfo?.service === "Active",
      activeProductCode: serviceInfo?.productCode,
      sessionRemainingSeconds: Math.max(0, serviceInfo?.remaining - 90), // remove the 90 second buffer added to timed product passes
      sessionRemainingSecondsActual: serviceInfo?.remaining,
    },
    status: {
      serviceQuality: serviceInfo?.quality,
    },
    utcTime: new Date(flightInfo?.utcTime),
    // Info for Logging
    aircraftType: flightInfoFIG2?.aircraft?.aircraft_type,
    expectedArrival: flightInfo?.expectedArrival,
    flightNumAlpha: flightInfo?.flightNumAlpha,
    flightNumberInfo: flightInfo?.flightNumberInfo,
    route: `${flightInfo?.departureAirportCode}-${flightInfo?.destinationAirportCode}`,
  };

  output.isValid = isFlightInfoValid(output);

  return output;
};

/**
 * tranforms status tray to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformStatusTray = (data) => {
  const { Response } = JSON.parse(data);

  const { flightInfo, serviceInfo, systemInfo } = Response;

  let output = {
    abpVersion: flightInfo?.abpVersion,
    acpuVersion: flightInfo?.acpuVersion,
    airlineCode: flightInfo?.airlineCode,
    airlineCodeIata: flightInfo?.airlineCodeIata,
    tailNumber: flightInfo?.tailNumber,
    flightNumber: flightInfo?.flightNumberInfo,
    system: {
      is2KU: systemInfo?.systemType === "2KU",
      isSATCOM:
        systemInfo?.systemType === "2KU" || systemInfo?.systemType === "KU",
      type: systemInfo?.systemType,
      ifcPaxServiceState: systemInfo?.ifcPaxServiceState === "UP" ?? false,
    },
    current: {
      latitude: flightInfo?.latitude,
      longitude: flightInfo?.longitude,
    },
    arrival: {
      airportCode: flightInfo?.destinationAirportCodeIata,
      city: flightInfo?.destinationCity,
      latitude:
        flightInfo?.destinationAirportLatitude === 0 ||
        flightInfo?.destinationAirportLongitude === null
          ? undefined
          : flightInfo?.destinationAirportLatitude,
      longitude:
        flightInfo?.destinationAirportLongitude === 0 ||
        flightInfo?.destinationAirportLongitude === null
          ? undefined
          : flightInfo?.destinationAirportLongitude,
      region: flightInfo?.product_region,
      time: flightInfo?.expectedArrival,
      timeZoneOffset: flightInfo?.destinationTimeZoneOffset,
      airportCodeIcao: flightInfo?.destinationAirportCode,
    },
    departure: {
      airportCode: flightInfo?.departureAirportCodeIata,
      city: flightInfo?.departureCity,
      latitude:
        flightInfo?.departureAirportLatitude === 0 ||
        flightInfo?.departureAirportLatitude === null
          ? undefined
          : flightInfo?.departureAirportLatitude,
      longitude:
        flightInfo?.departureAirportLongitude === 0 ||
        flightInfo?.departureAirportLongitude === null
          ? undefined
          : flightInfo?.departureAirportLongitude,
      time: flightInfo?.departureTime,
      airportCodeIcao: flightInfo?.departureAirportCode,
    },
    user: {
      ipAddress: Response?.ipAddress,
      macAddress: Response?.macAddress,
      deviceId: Response?.device_iid,
      activePass: serviceInfo?.service === "Active",
      activeProductCode: serviceInfo?.productCode,
      sessionRemainingSeconds: Math.max(0, serviceInfo?.remaining - 90), // remove the 90 second buffer added to timed product passes
      sessionRemainingSecondsActual: serviceInfo?.remaining,
    },
    status: {
      serviceQuality: serviceInfo?.quality,
    },
    utcTime: new Date(flightInfo?.utcTime),
    // Info for Logging
    aircraftType: systemInfo?.aircraftType,
    expectedArrival: flightInfo?.expectedArrival,
    flightNumAlpha: flightInfo?.flightNumAlpha,
    flightNumberInfo: flightInfo?.flightNumberInfo,
    route: `${flightInfo?.departureAirportCode}-${flightInfo?.destinationAirportCode}`,
  };

  handleFlightTrackerDataChange(flightInfo);

  output.isValid = isFlightInfoValid(output);

  if (output?.isValid === false) {
    // Only log invalid airport latlon bad data if COOKIE_AIRPORT_LATLON_INVALID does not exist
    if (!getCookie(COOKIE_AIRPORT_LATLON_INVALID)) {
      abpLogger({ message: "invalid airport latlon", flightInfo });
      trackCustomEventOnDynatrace("invalid airport latlon");
    }
    setCookie(COOKIE_AIRPORT_LATLON_INVALID, "invalid_latlon");
  } else if (output?.isValid === true) {
    // Only log valid airport latlon bad data recovery if COOKIE_AIRPORT_LATLON_INVALID does exist
    if (getCookie(COOKIE_AIRPORT_LATLON_INVALID)) {
      abpLogger({ message: "valid airport latlon recovery", flightInfo });
      trackCustomEventOnDynatrace("valid airport latlon recovery");
      removeCookie(COOKIE_AIRPORT_LATLON_INVALID);
    }
  }

  return output;
};

/**
 * This applies to apps hosted on Payment app hostname. Since the API
 * response is a different shape we handle it differently, but preserve the flight
 * info shape returned to the redux store.
 * @param {object} axiosResponse axios response data object
 */
export const transformFlightInfo = (axiosResponse) => {
  const json = JSON.parse(axiosResponse);
  const parseLocation = (data) => {
    return {
      airportCode: data?.iataCode,
      city: data?.city,
      latitude: Number(data?.lat),
      longitude: Number(data?.lon),
      time: data?.arrivalTime || data?.departureTime,
      airportCodeIcao: data?.icaoCode,
      state: data?.state,
      name: data?.name,
      country: data?.country,
      zip: data?.zip,
    };
  };

  return {
    airlineCode: json?.airline?.codes?.icao,
    airlineCodeIata: json?.airline?.codes?.iata,
    tailNumber: json?.tailNumber,
    flightNumber: json?.flightNum,
    system: {
      is2KU: json?.connectivityType?.[0] === "2KU",
      isSATCOM:
        json?.connectivityType?.[0] === "2KU" ||
        json?.connectivityType?.[0] === "KU",
      type: json?.connectivityType?.[0],
    },
    arrival: parseLocation(json?.destination),
    departure: parseLocation(json?.departure),
    user: {
      macAddress: json?.macAddress,
    },
  };
};

/**
 * validity check for flight info to display
 * @param {Object} statusTray
 * @returns {Boolean}
 */
export const isFlightInfoValid = (statusTray) => {
  return (
    statusTray?.airlineCode !== "" &&
    statusTray?.airlineCode !== null &&
    statusTray?.current?.latitude !== undefined &&
    statusTray?.current?.longitude !== undefined &&
    statusTray?.arrival?.latitude !== undefined &&
    statusTray?.arrival?.longitude !== undefined &&
    statusTray?.departure?.latitude !== undefined &&
    statusTray?.departure?.longitude !== undefined
  );
};

/**
 * Logs to ABP when statusTray response is different from
 * the last xhr poll. ("/abp/v2/statusTray" is polled every 60000ms).
 * Checks if any of these properties below have changed in the last minute:
 *  - departureAirportCode
 *  - destinationAirportCode,
 *  - flightNumber
 *  - departureTime
 *  - arrivalTime
 *  - visitorId(uxdId)
 * @function handleFlightTrackerDataChange
 */

const handleFlightTrackerDataChange = (flightInfo) => {
  const currentFlightTracker = {
    departureAirportCode: flightInfo?.departureAirportCodeIata,
    destinationAirportCode: flightInfo?.destinationAirportCodeIata,
    flightNumber: flightInfo?.flightNumberInfo,
    departureTime: flightInfo?.departureTime,
    arrivalTime: flightInfo?.expectedArrival,
    visitorId: getCookie(COOKIE_UXDID),
  };

  const flightTrackerChanged = didFlightTrackerChange(flightInfo);

  if (flightTrackerChanged) {
    abpLogger({
      message: "flightTracker data has changed",
      flightInfo,
      flightInfoPrev: JSON.parse(getCookie(COOKIE_FLIGHT_TRACKER_STORED)),
    });
  }

  /**
   * Checks departureAirportCode, destinationAirportCodeIata,
   * flightNumber, departureTime, and arrivalTime against same values
   * stored in previously set cookie: "COOKIE_FLIGHT_TRACKER_STORED"
   * @return {Boolean} true if any prop/values do not match
   */
  function didFlightTrackerChange() {
    if (getCookie(COOKIE_FLIGHT_TRACKER_STORED)) {
      let cookieFlightTracker = JSON.parse(
        getCookie(COOKIE_FLIGHT_TRACKER_STORED)
      );
      return !isEqual(currentFlightTracker, cookieFlightTracker);
    }
    return false;
  }

  setCookie(COOKIE_FLIGHT_TRACKER_STORED, JSON.stringify(currentFlightTracker));
};
