import axios from "axios";
import {
  trackCustomEventOnDynatraceWithDuration,
  trackCustomEventOnDynatraceWithDurationAndError,
} from "portal-analytics/src/services";
import { HAS_DYNATRACE_API_TRACKING } from "portal-utilities";

const DEFAULT_RETRIES = 2;

const NO_RETRY_URLS = ["/abp/v1/session/activate", "/abp/rest/aaa/activate"];
const NO_TRACKING_URLS = ["/abp/v2/statusTray", "/abp/rest/v1/data"];

/**
 * @const {Function}
 */
export const apiClient = axios.create({
  timeout: 30000,
});

const noRetry = (apiUrl = "") =>
  NO_RETRY_URLS.some((url) => apiUrl?.match(url));

/* eslint complexity: ["error", 11] */
const trackError = (error = {}) => {
  const { config, response } = error;

  const endpoint =
    `${config?.baseURL || ""}${config?.url || ""}` || "unknown endpoint";

  const statusCode =
    response?.data?.status_code ||
    response?.data?.statusCode ||
    response?.status ||
    "unknown status code";

  const errorMessage =
    response?.data?.error_msg ||
    response?.data?.errorMsg ||
    response?.statusText ||
    error?.message ||
    "unknown error";

  error?.config?.trackOnDynatrace({
    actionName: `${endpoint} - ${statusCode}`,
    error: errorMessage,
    sourceUrl: endpoint,
  });
};

const trackDuration = (response = {}) => {
  const { config } = response;

  const endpoint =
    `${config?.baseURL || ""}${config?.url || ""}` || "unknown endpoint";

  config?.trackOnDynatrace({ actionName: endpoint, sourceUrl: endpoint });
};

apiClient.interceptors.request.use((config) => {
  const startTime = Date.now();

  config.trackOnDynatrace = ({ actionName, error, sourceUrl } = {}) => {
    const noTracking = () =>
      NO_TRACKING_URLS.some((url) => sourceUrl?.includes(url));

    if (noTracking()) return; // exit at this point if url is excluded from tracking

    const stopTime = Date.now();

    if (error) {
      trackCustomEventOnDynatraceWithDurationAndError(
        actionName,
        stopTime,
        startTime,
        sourceUrl,
        error
      );
    } else {
      trackCustomEventOnDynatraceWithDuration(
        actionName,
        stopTime,
        startTime,
        sourceUrl
      );
    }
  };

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    if (localStorage.getItem(HAS_DYNATRACE_API_TRACKING) === "true") {
      trackDuration(response);
    }
    return response;
  },
  (error) => {
    if (localStorage.getItem(HAS_DYNATRACE_API_TRACKING) === "true") {
      trackError(error);
    }

    if (noRetry(error?.config?.url)) {
      return Promise.reject(error);
    }

    const retries = error?.config?.retries ?? DEFAULT_RETRIES;
    const is5xxError = error?.response?.status >= 500;

    return is5xxError && retries > 0
      ? apiClient.request({
          ...error.config,
          retries: retries - 1,
        })
      : Promise.reject(error);
  }
);
