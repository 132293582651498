import DataModel from "../DataModel";
import { CLICK_EVENT } from "../../constants";

const REQUIRED_PROPS = ["clickName"];

class ClickEventDataModel extends DataModel {
  constructor(data = {}) {
    super(data);
    this.displayName = CLICK_EVENT;
    this.validateProps(REQUIRED_PROPS, data);
  }
}

export default ClickEventDataModel;
