import React from "react";
import { FormattedMessage } from "react-intl";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import queryString from "query-string";

import {
  activateLoyaltySubscription,
  activateUserSession,
  authenticateCustomer,
  authenticateLoyaltyUser,
  getAvailablePartnerPasses,
  getAvailablePasses,
  getCustomer,
  getLoginStatus,
  getLoyaltyUrl,
  getRedirectingPageUrl,
  getSessionID,
  logOut,
} from "portal-services";

import {
  COOKIE_GG_SIGNIN,
  COOKIE_JSESSIONID,
  COOKIE_USERNAME,
  COOKIE_UXDID,
  getCookie,
  isIFCApp,
  isLocalhostApp,
  isPaymentApp,
  isPaymentGroundApp,
  isRoutePaymentApp,
  QUERY_PARTNER_AUTH_CODE,
  removeCookie,
  setCookie,
  URLS,
} from "portal-utilities";
import { addToast } from "portal-state-management";

/* eslint-disable complexity */
/**
 * creates user session chaining session id, session status, and login status
 * @param {Object} action
 */
function* createSession(action) {
  const meta = action.meta;
  const { config, flightInfo, locale, status } = action.payload;

  let loginStatus = { data: {} };
  let loyaltyUrl = { data: { loyaltyUrl: null } };
  let loyaltyProfile = { data: { loyaltyProfile: null } };
  let loyaltyAvailablePasses = { data: { loyaltyAvailablePasses: null } };
  let productsPurchased = { data: { productsPurchased: [] } };
  let redirectUrl = { data: { redirectUrl: null } };
  let availablePasses = {
    data: { activePasses: [], availablePasses: [], unavailablePasses: [] },
  };
  let userInformation = { data: {} };

  try {
    const oAuthCode = queryString.parse(window.location.search)[
      QUERY_PARTNER_AUTH_CODE
    ];
    const userName = getCookie(COOKIE_USERNAME);
    let uxdId = getCookie(COOKIE_UXDID);
    let bypassMaintenanceUrl = status?.bypassMaintenanceUrl;
    const isPaymentAppWithoutUxdId = isPaymentApp() && !uxdId;

    let session;
    if (isIFCApp() || isLocalhostApp() || isPaymentAppWithoutUxdId) {
      session = yield refreshUxdId({ config, status });
      uxdId = session.sessionId;
      bypassMaintenanceUrl = session.redirectionUrl;
    }

    if (
      !bypassMaintenanceUrl &&
      uxdId &&
      flightInfo?.user &&
      !config?.isReduceSplashLoadTime &&
      !isPaymentGroundApp()
    ) {
      const gbpCaptchaPassed = flightInfo?.user?.activePass;

      try {
        redirectUrl = yield call(getRedirectingPageUrl, {
          gbpCaptchaPassed,
          status,
          uxdId,
        });
      } catch {
        yield put({ type: "GET_GBP_V1_SPLASH_FAILED" });
      }
    }

    if (!bypassMaintenanceUrl) {
      let session;
      if (isIFCApp() || isLocalhostApp() || isPaymentAppWithoutUxdId) {
        if (!uxdId) {
          session = yield refreshUxdId({ config, status });
          uxdId = session.sessionId;
          bypassMaintenanceUrl = session.redirectionUrl;
        }
      }

      try {
        if (uxdId && !isPaymentGroundApp()) {
          loginStatus = yield call(getLoginStatus, { uxdId, status });
        }
      } catch (e) {
        yield put({ type: "GET_LOGIN_STATUS_FAIL" }); // TODO: This is not being used in reducer.
      }
    }

    if (oAuthCode && (isPaymentApp() || isRoutePaymentApp())) {
      try {
        loyaltyProfile = yield call(authenticateLoyaltyUser, {
          airlineCode: config?.key,
          authCode: oAuthCode,
          locale,
          partnerName: config?.partnerName,
          uxdId,
          status,
          config,
        });

        loyaltyAvailablePasses = yield call(getAvailablePartnerPasses, {
          accountId: loyaltyProfile?.loyaltyProfile?.accountId,
          partnerName: config?.partnerName,
          uxdId,
          status,
        });

        const hasActiveLoyaltyServices =
          loyaltyAvailablePasses?.data?.loyaltyAvailablePasses?.activeServices
            ?.length >= 1;

        if (hasActiveLoyaltyServices) {
          const [activeLoyaltyService = {}] =
            loyaltyAvailablePasses?.data?.loyaltyAvailablePasses
              ?.activeServices;

          yield call(activateLoyaltySubscription, {
            serviceId: activeLoyaltyService?.service_id,
            userName: loyaltyProfile?.data?.loyaltyProfile?.accountId,
            uxdId,
            status,
          });

          yield call(activateUserSession, {
            uxdId,
            status: {
              abpHostName: URLS?.wifi,
              protocol: "https",
            },
          });
        }
      } catch (error) {
        loyaltyProfile.data = {
          loyaltyProfile: { error: "Error retrieving loyalty information." },
        };

        loyaltyAvailablePasses.data = {
          loyaltyProfile: { error: "Error retrieving loyalty information." },
        };

        removeUserCookies();

        yield put({ type: "GET_LOYALTY_PROFILE_FAIL" });
      }
    }
    if (
      config?.hasLoyaltyIntegration &&
      (isPaymentApp() || isRoutePaymentApp())
    ) {
      try {
        loyaltyUrl = yield call(getLoyaltyUrl, {
          config,
          uxdId,
          status,
        });
      } catch (e) {
        yield put({ type: "GET_LOYALTY_REDIRECT_URL_FAIL" });
      }
    }
    if (userName && !bypassMaintenanceUrl && loginStatus?.data?.authenticated) {
      try {
        availablePasses = yield call(getAvailablePasses, {
          uxdId,
          userName,
          status,
        });
      } catch (error) {
        yield put({ type: "GET_AVAILABLE_PASSES_FAIL" });
      }

      try {
        userInformation = yield call(getCustomer, {
          uxdId,
          userName,
          status,
        });
      } catch (error) {
        yield put({ type: "GET_USER_INFORMATION_FAIL" });
      }
    }

    yield put({
      type: "GET_USER_SUCCESS",
      payload: {
        ...loyaltyUrl.data,
        ...loyaltyProfile.data,
        ...loyaltyAvailablePasses.data,
        ...productsPurchased.data,
        ...redirectUrl.data,
        ...availablePasses.data,
        ...flightInfo?.user,
        ...userInformation.data,
        ...loginStatus.data,
        uxdId,
        bypassMaintenanceUrl,
        userName,
      },
      meta: meta,
    });

    return;
  } catch (error) {
    yield put({ type: "GET_USER_FAIL", payload: error, meta });
  }
}

/**
 * @param {Object} action
 */
export function* userSaga(action) {
  const meta = action.meta;
  const uxdId = getCookie(COOKIE_UXDID);
  const { config } = action.payload;

  switch (action.type) {
    case "GET_USER":
      yield createSession(action);
      return;
    case "SET_USER":
      yield put({
        type: "SET_USER_SUCCESS",
        payload: action.payload,
        meta: action.meta,
      });
      return;
    case "LOGIN_USER":
      try {
        yield call(authenticateCustomer, {
          airlineCode: config?.key,
          status: action.payload,
          uxdId,
        });
      } catch (e) {
        yield put({
          type: "LOGIN_USER_FAIL",
          payload: e.message ? e.message : e,
          meta,
        });
      }
      return;
    case "LOGOUT_USER":
      try {
        yield call(logOut, { status: action?.payload?.status?.data, uxdId });
        removeUserCookies();
        window.location.reload();
      } catch (e) {
        yield put({
          type: "LOGOUT_USER_FAIL",
          payload: e.message ? e.message : e,
          meta,
        });

        yield put(
          addToast({
            type: "error",
            msg: (
              <FormattedMessage
                id="errors.generic"
                defaultMessage="Something went wrong..."
              />
            ),
          })
        );
      }
      return;

    case "LOGOUT_LOYALTY_USER":
      yield put({
        type: "LOGOUT_LOYALTY_USER_SUCCESS",
        payload: action.payload,
        meta: action.meta,
      });
      return;
  }
}

/**
 * get user watcher user
 */
export function* getUserWatcher() {
  yield takeLatest("GET_USER", userSaga);
}

/**
 * set user watcher user
 */
export function* setUserWatcher() {
  yield takeLatest("SET_USER", userSaga);
}

/**
 * logout watcher loyalty user
 */
export function* logOutLoyaltyUserWatcher() {
  yield takeLatest("LOGOUT_LOYALTY_USER", userSaga);
}

/**
 * logout watcher user
 */
export function* logOutUserWatcher() {
  yield takeLatest("LOGOUT_USER", userSaga);
}

/**
 * removes user cookies
 */
const removeUserCookies = () => {
  removeCookie(COOKIE_USERNAME);
  removeCookie(COOKIE_GG_SIGNIN);
};

/**
 * Performs an API call for a new uxdId and persists the result in the uxdId
 * cookie
 * @param   {Object} status
 * @returns {String} refreshed uxdId
 */
function* refreshUxdId({ config, status }) {
  try {
    const responsePayload = yield call(getSessionID, { config, status });
    const uxdId = responsePayload?.data?.uxdId;

    if (uxdId) {
      setUxdId(uxdId);
      return {
        sessionId: uxdId,
        redirectionUrl: responsePayload?.data?.redirectionUrl,
      };
    } else {
      return {
        sessionId: getCookie(COOKIE_UXDID),
        redirectionUrl: responsePayload?.data?.redirectionUrl,
      };
    }
  } catch (error) {
    yield put({ type: "REFRESH_UXDID_REQUEST_FAIL" });
    // TODO: Redirect to link down page / SNA page.
  }

  return {
    sessionId: getCookie(COOKIE_UXDID),
    redirectionUrl: null,
  };
}

/**
 * set uxdId from response
 * @param  {String} uxdId
 */
const setUxdId = (uxdId) => {
  setCookie(COOKIE_UXDID, uxdId);
  setCookie(COOKIE_JSESSIONID, uxdId);
};

/**
 * Primary export of module. This should be used in a client's root reducer, to
 * ensure they are modifying user state as a whole
 */
export function* userWatcher() {
  yield fork(getUserWatcher);
  yield fork(setUserWatcher);
  yield fork(logOutLoyaltyUserWatcher);
  yield fork(logOutUserWatcher);
}
