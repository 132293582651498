import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { defineMessages, injectIntl } from "react-intl";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import "./_index.scss";

defineMessages({
  select_value: {
    id: "components.select.select_value",
    defaultMessage: "Select {value}...",
  },
});

const Select = ({
  dataAutomation,
  field,
  form,
  intl,
  isDisabled,
  label,
  onChange,
  options,
  placeholder,
  theme,
  value,
}) => {
  const selectLabel = label || placeholder;

  /* eslint-disable jsx-a11y/no-onchange */
  return (
    <React.Fragment>
      {selectLabel && (
        <label className="select-label" htmlFor="selectLabel">
          {selectLabel}
        </label>
      )}
      <select
        className="select"
        {...field}
        data-automation={dataAutomation}
        disabled={isDisabled}
        aria-label={placeholder}
        id={selectLabel}
        onChange={(value) => {
          field
            ? (field.onChange(value), onChange && onChange(value.target.value))
            : onChange(value.target.value);
        }}
        value={value || field?.value || placeholder}
      >
        {placeholder && (
          <option value="">
            {intl.formatMessage(
              {
                id: "components.select.select_value",
              },
              {
                value: placeholder.toLowerCase(),
              }
            )}
          </option>
        )}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {typeof option?.label === "object"
                ? intl.formatMessage(
                    {
                      id: option?.label?.props?.id,
                    },
                    {
                      ...option?.label?.props?.values,
                    }
                  )
                : option.label}
            </option>
          );
        })}
      </select>
      {form?.touched[field.name] && form?.errors[field.name] && (
        <div
          className="select-error"
          data-automation={`${dataAutomation}-error`}
          style={{ color: theme?.data?.color_error }}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} /> {selectLabel}{" "}
          {form?.errors[field.name]}
        </div>
      )}
    </React.Fragment>
  );
};

Select.propTypes = {
  dataAutomation: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
  intl: PropTypes.object,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  theme: PropTypes.object,
  value: PropTypes.string,
};

Select.defaultProps = {
  options: [{ label: "Label", value: "Label" }],
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(injectIntl(Select));
