import { logMaintenanceBypass } from "portal-services";

/**
 * Logs into ABP catalina.
 * redirect to a page when bypass url is present
 * @param  {String} url
 * @param  {object} logData
 */
export const redirectIfByPassEnabled = async (url, logData = {}) => {
  if (url) {
    await logMaintenanceBypass(logData);
    window.location.href = url;
  }
};
