import {
  getConfig,
  getIntl,
  getProducts,
  getTheme,
  getUser,
  setStatus,
} from "portal-state-management";
import { applyTargetTheme, loadThemeStyles, URLS } from "portal-utilities";
import { getCurrency, getLocale, initGlobalApp } from "../index";

/**
 * Ground Payment initializer
 * @param {*} dispatch
 * @returns
 */
export const initGroundPaymentApp = async ({ dispatch }) => {
  const serviceStatus = {
    abpHostName: URLS?.groundportal,
    protocol: "https",
    serviceStatus: true,
  };

  const airlineCode = "all";

  const config = await dispatch(getConfig({ airlineCode }));

  await initGlobalApp({ config: config?.data });

  let locale = await getLocale({ config: config?.data });

  let currency = await getCurrency({ config: config?.data });

  await dispatch(getIntl({ airlineCode, locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(
      getTheme({
        airlineCode,
        isTargetTheme: applyTargetTheme({ config }),
      })
    );
    loadThemeStyles(theme);
  }

  await dispatch(setStatus({ ...serviceStatus }));

  const user = await dispatch(
    getUser({ config: config?.data, locale, status: serviceStatus })
  );

  try {
    await dispatch(
      getProducts({
        airlineCode: airlineCode,
        currency,
        config: config?.data,
        locale,
        status: serviceStatus,
        uxdId: user?.uxdId,
        user,
      })
    );
  } catch (e) {
    await dispatch(
      getProducts({
        airlineCode: airlineCode,
        currency,
        config: {},
        locale,
        status: serviceStatus,
        uxdId: user?.uxdId,
        user,
      })
    );
  }
};

/**
 * GroundPayment initializer status
 * @param {*} props
 * @returns
 */
export const isGroundPaymentInitialized = (props) => {
  // TODO: Check for error.
  return (
    props?.config?.isLoading === false && props?.theme?.isLoading === false
  );
};
