import { put, putResolve } from "redux-saga/effects";

import { getProducts, getUser, setStatus } from "portal-state-management";

/**
 * @param {Object} action
 */
export function* groundSaga(action) {
  const meta = action.meta;
  const { config, currency, flightInfo, locale, serviceStatus } =
    action.payload;

  yield putResolve(setStatus({ ...serviceStatus }));

  const user = yield putResolve(
    getUser({ config, locale, status: serviceStatus })
  );

  yield putResolve(
    getProducts({
      airlineCode: flightInfo?.airlineCode,
      connectivityType: flightInfo?.system?.type,
      currency,
      config,
      departureCodeIcao: flightInfo?.departure?.airportCodeIcao,
      destinationCodeIcao: flightInfo?.arrival?.airportCodeIcao,
      flightNumber: flightInfo?.flightNumber,
      locale,
      region: flightInfo?.arrival?.region,
      status: serviceStatus,
      tailNumber: flightInfo?.tailNumber,
      uxdId: user?.uxdId,
      user,
    })
  );

  yield put({
    type: "GET_GROUND_SUCCESS",
    payload: action.payload,
    meta: meta,
  });
}
