/**
 * returns boolean if locale is RTL
 * @param  {String} locale
 * @return {Boolean}
 */
export const isRTL = (locale = null) => {
  switch (locale) {
    case "ar-QA":
      return true;
    default:
      return false;
  }
};
