import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ReactComponent as PlaneIcon } from "./assets/gg-icon-plane.svg";
import { ReactComponent as SpinnerIcon } from "./assets/gg-icon-spinner.svg";

import "./_index.scss";

const Loader = ({ className, isContainer, isOverlay, content }) => (
  <div
    className={classNames("loader", className, {
      "is-overlay": isOverlay,
      "is-container": isContainer,
    })}
  >
    {content ? (
      content
    ) : (
      <React.Fragment>
        <PlaneIcon data-automation="loader-plane" className="loader-plane" />
        <SpinnerIcon data-automation="loader-spinner" className="loader-spinner" />
      </React.Fragment>
    )}
  </div>
);

Loader.propTypes = {
  /**
   * Changes loader to page overlay.
   */
  isOverlay: PropTypes.bool,
  /**
   * Adapts loader to size of container.
   */
  isContainer: PropTypes.bool,
  /**
   * Adapts loader to size of container.
   */
  content: PropTypes.element,
  /**
   * Optional class name.
   */
  className: PropTypes.string,
};

Loader.defaultProps = {
  isOverlay: false,
};

export default Loader;
