/**
 * get status redux action
 * @param  {Object}
 * @return {Object}
 */

export const getStatus = () => {
  return {
    type: "GET_STATUS",
    meta: {
      thunk: true,
    },
  };
};

/**
 * set status redux action
 * @param  {Object} options.status
 * @return {Object}
 */
export const setStatus = (status) => {
  return {
    type: "SET_STATUS",
    payload: status,
    meta: {
      thunk: true,
    },
  };
};
