/**
 * imports flag icon from global assets based on locale
 * @function importFlag
 * @param {string} locale
 * @returns {module} svg asset
 */
// TODO: Fix code complexity
/* eslint-disable complexity */
/* istanbul ignore next */
export const importFlag = (locale) => {
  switch (locale) {
    case "de-DE":
      return require(`../../../../../assets/icons/flags/ger-flag.svg`).default;
    case "en-US":
      return require(`../../../../../assets/icons/flags/usa-flag.svg`).default;
    case "es-ES":
      return require(`../../../../../assets/icons/flags/esp-flag.svg`).default;
    case "es-AR":
      return require(`../../../../../assets/icons/flags/arg-flag.svg`).default;
    case "es-CL":
      return require(`../../../../../assets/icons/flags/chl-flag.svg`).default;
    case "es-DO":
      return require(`../../../../../assets/icons/flags/dom-flag.svg`).default;
    case "es-BO":
      return require(`../../../../../assets/icons/flags/esp-flag.svg`).default;
    case "es-MX":
      return require(`../../../../../assets/icons/flags/mex-flag.svg`).default;
    case "es-PY":
      return require(`../../../../../assets/icons/flags/pry-flag.svg`).default;
    case "es-UY":
      return require(`../../../../../assets/icons/flags/ury-flag.svg`).default;
    case "en-GB":
      return require(`../../../../../assets/icons/flags/gbr-flag.svg`).default;
    case "en-CA":
      return require(`../../../../../assets/icons/flags/can-flag.svg`).default;
    case "fr-CA":
      return require(`../../../../../assets/icons/flags/can-flag.svg`).default;
    case "fr-FR":
      return require(`../../../../../assets/icons/flags/fra-flag.svg`).default;
    case "ko-KR":
      return require(`../../../../../assets/icons/flags/kr-flag.svg`).default;
    case "pt-BR":
      return require(`../../../../../assets/icons/flags/bra-flag.svg`).default;
    case "pt-PT":
      return require(`../../../../../assets/icons/flags/prt-flag.svg`).default;
    case "ja-JP":
      return require(`../../../../../assets/icons/flags/jpn-flag.svg`).default;
    case "zh-CN":
      return require(`../../../../../assets/icons/flags/ch-flag.svg`).default;
    case "zh-HK":
      return require(`../../../../../assets/icons/flags/ch-flag.svg`).default;
    case "ar-QA":
      return require(`../../../../../assets/icons/flags/qtr-flag.svg`).default;
  }
};
export default importFlag;
