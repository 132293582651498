import { axios } from "portal-services";
import { isCaptiveApp, isIFCApp, isPaymentApp } from "portal-utilities";
/**
 * gets config build info from public directory
 * @return {Promise}
 */
export const getConfigBuildInfo = (airlineCode) => {
  if (isPaymentApp()) {
    return axios({
      method: "get",
      url: "/ui-config/buildinfo.json",
      headers: {
        "airline-code": airlineCode,
      },
      transformResponse: [
        function (data) {
          return transformConfigBuildInfo(data);
        },
      ],
    });
  } else if (
    (isCaptiveApp() || isIFCApp()) &&
    process.env.HAS_ABP_CONFIG === "true"
  ) {
    return axios({
      method: "get",
      url: "/app/buildinfo/layoutengine-build.json",
      transformResponse: [
        function (data) {
          return transformConfigBuildInfo(data);
        },
      ],
    });
  } else {
    return Promise.resolve(null);
  }
};

/**
 * transforms config build info to needed fields
 * @param  {Object} data
 * @return {Object}
 */
export const transformConfigBuildInfo = (data) => {
  const response = JSON.parse(data);
  return {
    airlineCode: response?.airlineCode,
    buildNumber: response?.buildNumber,
    dateOfBuild: response?.dateOfBuild,
    repo: response?.repo,
  };
};
