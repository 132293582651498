import { put, putResolve, takeLatest } from "redux-saga/effects";
import isEqual from "lodash/isEqual";

import { trackCustomEventOnDynatrace } from "portal-analytics/src/services";

import { abpLogger } from "portal-services";

import {
  getActiveDevicePin,
  getCatalog,
  getProducts,
  getTheme,
  getUser,
  getWeather,
} from "portal-state-management";

import {
  COOKIE_FLIGHT_IDENTIFIER,
  COOKIE_HMA,
  Cookies,
  darkMode,
  getCookieDomain,
  getIsCelsius,
  loadThemeStyles,
  removeCookies,
} from "portal-utilities";

/**
 * @param {Object} action
 */
export function* inFlightSaga(action) {
  const meta = action.meta;

  const { config, currency, flightInfo, locale, status } = action.payload;

  let bypassMaintenanceUrl = null;

  // USER SAGA CHECKS TO SEE IF FLIGHT HAS CHANGED AND RETRIEVES NEW UXDID IF NECESSARY
  const flightChanged = isFlightChanged(flightInfo);

  if (flightChanged) {
    deleteStoredItemsFromOldFlight();
  }

  storeFlightIdentifierInCookie(getFlightIdentifier(flightInfo));

  const user = yield putResolve(
    getUser({
      config,
      flightChanged,
      flightInfo,
      locale,
      status,
    })
  );

  bypassMaintenanceUrl = user?.bypassMaintenanceUrl;

  if (!bypassMaintenanceUrl) {
    if (status.ifeStatus) {
      yield put(getCatalog({ flightInfo, locale, status }));
    } else {
      yield put({ type: "GET_CATALOG_FAIL", payload: "IFE not available" });
      abpLogger({ message: "IFE Not Available", flightInfo });
      trackCustomEventOnDynatrace("IFE Not Available");
    }

    if (config?.hasDarkMode) {
      const theme = yield putResolve(
        getTheme({ isDarkMode: darkMode(config, flightInfo) })
      );
      loadThemeStyles(theme);
    }

    yield putResolve(
      getProducts({
        airlineCode: flightInfo?.airlineCode,
        connectivityType: flightInfo?.system?.type,
        currency,
        config,
        departureCodeIcao: flightInfo?.departure?.airportCodeIcao,
        destinationCodeIcao: flightInfo?.arrival?.airportCodeIcao,
        flightNumber: flightInfo?.flightNumber,
        locale,
        region: flightInfo?.arrival?.region,
        status,
        tailNumber: flightInfo?.tailNumber,
        uxdId: user?.uxdId,
        user,
      })
    );

    yield putResolve(
      getWeather({
        airportCode: flightInfo?.arrival?.airportCode,
        airportCodeIcao: flightInfo?.arrival?.airportCodeIcao,
        isCelsius: getIsCelsius(config?.isCelsiusDefault),
        locale,
        status,
      })
    );

    if (config?.hasSwitchDevice && user?.activePass) {
      yield putResolve(
        getActiveDevicePin({
          uxdId: user?.uxdId,
          status,
        })
      );
    }
  }

  yield put({
    type: "GET_IN_FLIGHT_SUCCESS",
    payload: {
      bypassMaintenanceUrl,
      config,
      currency,
      flightInfo,
      locale,
      status,
    },
    meta: meta,
  });
}

/**
 * get in flight watcher used for testing purposes
 */
export function* getInFlightWatcher() {
  yield takeLatest("GET_IN_FLIGHT", inFlightSaga);
}

/**
 * Clean up old cookies, localStorage, sessionStorage e.t.c. from old flight
 */
function deleteStoredItemsFromOldFlight() {
  localStorage.clear();
  removeCookies([
    "AIRPORT_LATLON_INVALID",
    "Authorization",
    "dtThemeUsed",
    "DYNATRACE_THEME_USED",
    "FLIGHT_TRACKER_STORED",
    "GG_PORTAL_USERNAME",
    "GG_SIGNEDIN",
    "GG_SIGNIN",
    "PREFERRED_CURRENCY",
    "PREFERRED_LANGUAGE",
    "X-Request-Id",
    "activeSession",
    "amazon_Login_accessToken",
    "amazon_Login_state_cache",
    "hasconnectivity",
    "ifeSignoutRedirect",
    "iframeNewUxdidCheck",
    "order-success",
    "passV2RedirectAttempt",
    "passV3RedirectAttempt",
    "preferredCurrency",
    "preferredLanguage",
    "signoutFreeMsgPass",
    COOKIE_HMA,
  ]);
}

/**
 * Formats flight identifier object
 * @param {object} flightInfo
 */
function getFlightIdentifier(flightInfo) {
  return {
    tailNumber: flightInfo?.tailNumber,
    departureAirportCode: flightInfo?.departure?.airportCodeIcao,
    destinationAirportCode: flightInfo?.arrival?.airportCodeIcao,
  };
}

/**
 * Determines if a flight has changed, checking tail, origin and destination
 * in flightIdentifier cookie with latest flight info from API
 * @param {object} flightInfo
 */
function isFlightChanged(flightInfo) {
  if (Cookies.get(COOKIE_FLIGHT_IDENTIFIER)) {
    let cookieFlightData = JSON.parse(Cookies.get(COOKIE_FLIGHT_IDENTIFIER));
    let currentFlightData = getFlightIdentifier(flightInfo);

    return !isEqual(currentFlightData, cookieFlightData);
  }

  return false;
}

/**
 * Stores a flight identifier in cookie to be used for checking if the user's flight has changed.
 * @param {object} flightIdentifier the flight identifier object to stored.
 */
function storeFlightIdentifierInCookie(flightIdentifier) {
  Cookies.set(COOKIE_FLIGHT_IDENTIFIER, JSON.stringify(flightIdentifier), {
    domain: getCookieDomain(),
  });
}
