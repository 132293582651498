import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { COOKIE_UXDID, getCookie, oauthUser } from "portal-utilities";
import { getAppBuildInfo, getConfigBuildInfo } from "portal-services";
import { trackSessionProperties, trackUser } from "portal-analytics";

const appBuildKey = `${process.env.APP || "ui"}AppBuild`;
const appVersionKey = `${process.env.APP || "ui"}AppVersion`;

//TODO: Remove transition tags in the future. (uiAppBuild, uiAppVersion)
export class MetaTagProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      airline: "not_available",
      abpVersion: null,
      acpuVersion: null,
      destination: null,
      deviceId: null,
      flightNumber: null,
      isNewSessionProperties: false,
      iid: null,
      oauthProfile: null,
      origin: null,
      tailNumber: "not_available",
      technology: null,
      [appBuildKey]: null,
      [appVersionKey]: null,
      uiConfigBuild: null,
      userStatus: null,
      uxdId: null,
    };
  }

  componentDidMount() {
    const { flightInfo, status } = this.props;

    if (!status?.isLoading) {
      this.setUserStatus(status);
    }

    this.setUxdIdDetails();

    if (!flightInfo?.isLoading) {
      this.setFlightInfoDetails(flightInfo);

      this.setAppBuildDetails(flightInfo?.data?.airlineCode);

      this.setConfigBuildDetails(flightInfo?.data?.airlineCode);
    }
  }

  componentDidUpdate(prevProps) {
    const { flightInfo, status } = this.props;

    if (prevProps?.status?.isLoading !== status?.isLoading) {
      this.setUserStatus(status);
    }

    if (prevProps?.flightInfo?.isLoading !== flightInfo?.isLoading) {
      this.setFlightInfoDetails(flightInfo);

      const airlineCode = flightInfo?.data?.airlineCode;
      if (airlineCode) {
        this.setAppBuildDetails(airlineCode);
        this.setConfigBuildDetails(airlineCode);
      }
    }

    if (this.state.isNewSessionProperties) {
      trackSessionProperties(this.state);
      this.setState({
        isNewSessionProperties: false,
      });
    }

    // Set the same tags as window var also.
    if (window["ANALYTICS"] === undefined) {
      window["ANALYTICS"] = {};
    }

    Object.keys(this.state).forEach((key) => {
      window["ANALYTICS"][key] = this.state[key];
    });
  }

  setUserStatus(status) {
    const userStatus = status?.data?.userStatus;
    this.setState({
      userStatus,
    });
  }

  setUxdIdDetails() {
    const uxdId = getCookie(COOKIE_UXDID);
    if (uxdId) {
      trackUser(uxdId);
      this.setState({
        isNewSessionProperties: true,
        uxdId,
      });
    }
  }

  setFlightInfoDetails(flightInfo) {
    const abpVersion = flightInfo?.data?.abpVersion;
    const acpuVersion = flightInfo?.data?.acpuVersion;
    const airline = flightInfo?.data?.airlineCode;
    const destination = flightInfo?.data?.arrival?.airportCodeIcao;
    const deviceId = flightInfo?.data?.user?.deviceId ?? "noID";
    const flightNumber = flightInfo?.data?.flightNumber;
    const iid = btoa(flightInfo?.data?.user?.macAddress);
    const origin = flightInfo?.data?.departure?.airportCodeIcao;
    const tailNumber = flightInfo?.data?.tailNumber;
    const technology = flightInfo?.data?.system?.type;

    this.setState({
      abpVersion,
      acpuVersion,
      airline,
      destination,
      deviceId,
      flightNumber,
      iid,
      isNewSessionProperties: true,
      origin,
      tailNumber,
      technology,
    });
  }

  setAppBuildDetails = async (airlineCode) => {
    if (process.env.NODE_ENV === "development") {
      return this.setState({
        [appBuildKey]: "local",
        [appVersionKey]: "local",
        isNewSessionProperties: true,
      });
    }

    try {
      const response = await getAppBuildInfo(airlineCode);

      const buildNumber = response?.data?.buildNumber;
      const version = response?.data?.version;

      this.setState({
        [appBuildKey]: buildNumber,
        [appVersionKey]: version,
        isNewSessionProperties: true,
      });
    } catch (error) {
      this.setState({
        [appBuildKey]: null,
        [appVersionKey]: null,
        isNewSessionProperties: true,
      });
    }
  };

  setConfigBuildDetails = async (airlineCode) => {
    if (process.env.NODE_ENV === "development") {
      return this.setState({
        uiConfigBuild: "local",
        isNewSessionProperties: true,
      });
    }

    try {
      const response = await getConfigBuildInfo(airlineCode.toLowerCase());

      const uiConfigBuild = response?.data?.buildNumber;

      this.setState({
        isNewSessionProperties: true,
        uiConfigBuild,
      });
    } catch (error) {
      this.setState({
        isNewSessionProperties: true,
        uiConfigBuild: null,
      });
    }
  };

  render() {
    const {
      abpVersion,
      acpuVersion,
      airline,
      destination,
      deviceId,
      flightNumber,
      iid,
      origin,
      tailNumber,
      technology,
      uiConfigBuild,
      userStatus,
      uxdId,
    } = this.state;

    const { intl } = this.props;
    const oauthProfile = oauthUser.getOauthUser();
    const pageName = `page-${window.location.pathname}`;

    const tags = [
      { name: "abpVersion", content: abpVersion },
      { name: "acpuVersion", content: acpuVersion },
      { name: "airline", content: oauthProfile?.airlineCode || airline },
      { name: "clientId", content: oauthProfile?.client_id },
      { name: "destination", content: destination },
      { name: "deviceId", content: deviceId },
      { name: "environment", content: "environment" },
      {
        name: "flightNumber",
        content: flightNumber || oauthProfile?.flight_number,
      },
      { name: "locale", content: intl?.locale },
      { name: "iid", content: iid },
      { name: "oauthTrackingId", content: oauthProfile?.tracking_id },
      { name: "origin", content: origin },
      { name: pageName, content: userStatus },
      { name: "partnerName", content: oauthProfile?.partner_name },
      { name: "tailNumber", content: oauthProfile?.tail_number || tailNumber },
      { name: "technology", content: technology },
      {
        name: appBuildKey,
        content: this.state[appBuildKey],
      },
      {
        name: appVersionKey,
        content: this.state[appVersionKey],
      },
      {
        name: "uiAppBuild",
        content: this.state[appBuildKey],
      },
      {
        name: "uiAppVersion",
        content: this.state[appVersionKey],
      },
      { name: "uiConfigBuild", content: uiConfigBuild },
      { name: "uiLocales", content: oauthProfile?.ui_locales },
      { name: "uxdId", content: uxdId },
    ];

    const metatags = [];
    tags.forEach((tag) => {
      if (tag.content) {
        const metatag = (
          <meta key={tag.name} name={tag.name} content={tag.content} />
        );
        metatags.push(metatag);
      }
    });

    return (
      <div>
        <Helmet>{metatags}</Helmet>
      </div>
    );
  }
}

MetaTagProvider.propTypes = {
  flightInfo: PropTypes.object,
  intl: PropTypes.object,
  oauthProfile: PropTypes.object,
  status: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    flightInfo: state.flightInfo,
    intl: state.intl,
    status: state.status,
  };
};

export default connect(mapStateToProps)(MetaTagProvider);
