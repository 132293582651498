import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ReactComponent as SpinnerIcon } from "./assets/gg-icon-spinner.svg";

import "./_index.scss";

export class Button extends Component {
  /* eslint-disable complexity */
  generateStyle() {
    const { theme, type, dynamicType } = this.props;
    const buttonType = type === "" ? theme?.data?.primary_cta : type;
    const dynamicTypeTag = theme?.data?.[dynamicType];

    if (dynamicType)
      return {
        borderColor: dynamicTypeTag?.btn_border_color,
        borderRadius: dynamicTypeTag?.btn_border_radius,
        backgroundColor: dynamicTypeTag?.btn_bg_color,
        color: dynamicTypeTag?.btn_color_txt,
        fontWeight: dynamicTypeTag?.btn_weight,
      };

    if (!theme) return null;

    switch (buttonType) {
      case "secondary":
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.color_secondary,
          backgroundColor: theme.data.color_secondary,
        };
      case "secondary-alt":
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.color_secondary,
          backgroundColor: theme.data.color_secondary,
          color: theme.data.color_primary,
        };
      case "ghost":
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.color_primary,
          color: theme.data.color_primary,
        };
      case "neutral":
        return {
          borderRadius: theme.data.border_radius,
          backgroundColor: theme.data.gray_dark,
          borderColor: theme.data.gray_dark,
        };
      case "neutral-ghost":
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.gray_dark,
          color: theme.data.gray_dark,
        };
      case "abstract":
        return {
          borderRadius: theme.data.border_radius,
          backgroundColor: theme.data.color_tertiary,
          borderColor: theme.data.color_tertiary,
          color: theme.data.color_primary,
        };
      case "tertiary":
        return {
          borderRadius: theme.data.border_radius,
          backgroundColor: theme.data.color_tertiary,
          borderColor: theme.data.color_tertiary,
        };
      case "tertiary-ghost":
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.color_tertiary,
          color: theme.data.color_tertiary,
        };
      case "loyalty":
        return {
          borderRadius: theme.data.border_radius,
          backgroundColor: theme.data.color_loyalty || theme.data.color_primary,
          borderColor: theme.data.color_loyalty || theme.data.color_primary,
        };
      case "activation-failed":
        return {
          backgroundColor:
            theme?.data?.activation_retry_feature?.error_notification
              ?.btn_background_color,
          borderRadius:
            theme?.data?.activation_retry_feature?.error_notification
              ?.btn_border_radius || theme?.data?.border_radius,
          color:
            theme?.data?.activation_retry_feature?.error_notification
              ?.btn_color,
        };
      default:
        return {
          borderRadius: theme.data.border_radius,
          borderColor: theme.data.color_primary,
          backgroundColor: theme.data.color_primary,
        };
    }
  }
  /* eslint-enable complexity */

  makeLink(defaultProps) {
    const { icon, href, target, label, onClick, other } = this.props;
    const handleOnClick = onClick || undefined;

    return (
      <a
        {...other}
        {...defaultProps}
        href={href}
        onClick={handleOnClick}
        target={target}
        rel="noopener noreferrer"
      >
        <span>{label}</span>
        {icon}
      </a>
    );
  }

  makeRouterLink(defaultProps) {
    const { icon, to, label, target, onClick, other } = this.props;
    const handleOnClick = onClick || undefined;

    return (
      <Link
        {...other}
        {...defaultProps}
        target={target}
        onClick={handleOnClick}
        to={to}
      >
        <span data-automation="router-link">{label}</span>
        {icon}
      </Link>
    );
  }

  makeButton(defaultProps) {
    const { icon, isLoading, onClick, label, isDisabled, other } = this.props;

    const handleOnClick = onClick || undefined;

    return (
      <button
        {...other}
        {...defaultProps}
        disabled={isDisabled || isLoading}
        onClick={handleOnClick}
        type="submit"
      >
        <div className="button-content">
          {isLoading && <SpinnerIcon />}
          <span>{label}</span>
          {icon}
        </div>
      </button>
    );
  }

  /* eslint-disable complexity */
  render() {
    const {
      dataAutomation,
      href,
      isDisabled,
      isLoading,
      size,
      to,
      type,
      theme,
    } = this.props;

    const buttonType =
      type === "" && theme?.data?.primary_cta ? theme?.data?.primary_cta : type;

    const defaultProps = {
      className: classNames(
        `button${buttonType ? "-" + buttonType : ""}${size ? "-" + size : ""}`,
        { "is-disabled": isDisabled, "is-loading": isLoading }
      ),
      style: this.generateStyle(),
      "data-automation": dataAutomation,
    };

    if (href) {
      return this.makeLink(defaultProps);
    } else if (to) {
      return this.makeRouterLink(defaultProps);
    } else {
      return this.makeButton(defaultProps);
    }
  }
  /* eslint-enable complexity */
}

Button.propTypes = {
  /**
   * Data automation tag for QE purposes.
   */
  dataAutomation: PropTypes.string,
  /**
   * customize button props in themes and pass in using dynamicType prop.
   */
  dynamicType: PropTypes.string,
  /**
   * Changes button to external link with href.
   */
  href: PropTypes.string,

  /**
   * (optional) Icon next to label
   */
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  other: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf([
    "",
    "small",
    "small-wide",
    "wide",
    "large",
    "large-wide",
  ]),
  /**
   * Changes target if external href.
   */
  target: PropTypes.string,
  /**
   * Changes button to react-router link.
   */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.object,
  type: PropTypes.oneOf([
    "",
    "primary",
    "secondary",
    "neutral",
    "ghost",
    "neutral-ghost",
    "abstract",
    "tertiary",
    "tertiary-ghost",
    "loyalty",
    "activation-failed",
  ]),
};

Button.defaultProps = {
  label: "Button",
  type: "",
};

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps)(Button);
