import React, { Component, useContext } from "react";
import PropTypes from "prop-types";

const BotDetectionContext = React.createContext();
const { Provider } = BotDetectionContext;

const useBotDetectionContext = () => useContext(BotDetectionContext);

const initialState = {
  isBotDetected: false,
  isValidatingCaptcha: false,
  honeypotValue: "",
};

class BotDetectionProvider extends Component {
  state = {
    ...initialState,
    captchaLink: React.createRef(),
    honeypotLink: React.createRef(),
  };

  isHoneypotDirty = () => {
    return !!this.getCurrentHoneypotLinkValue();
  };

  getCurrentHoneypotLinkValue = () => {
    if (!this.state.honeypotLink.current) {
      throw new Error(
        "BotDetectionProvider and Honeypot components must be initialized!"
      );
    }
    return this.state.honeypotLink.current.value || "";
  };

  isValid = () => {
    if (this.state.isBotDetected || this.isHoneypotDirty()) {
      this.setHoneypot(this.getCurrentHoneypotLinkValue());
      return false;
    }

    return true;
  };

  resetHoneypot = () => {
    this.setState(initialState);
  };

  setHoneypot = (value = "") => {
    this.setState({ isBotDetected: true, honeypotValue: value });
  };

  getCaptchaSchema = () => {
    if (!this.state.isBotDetected || !this.state.captchaLink.current) {
      return {};
    }

    return this.state.captchaLink.current.getCaptchaSchema();
  };

  validateHoneypot = (captchaInputValue = "") => {
    if (this.isValid()) {
      return Promise.resolve(true);
    }

    if (!this.state.captchaLink.current) {
      return Promise.resolve(false);
    }

    this.setState({ isValidatingCaptcha: true });

    return this.state.captchaLink.current
      .validate(captchaInputValue)
      .then((isValid) => {
        this.setState({ isValidatingCaptcha: false });
        return isValid;
      });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          getCaptchaSchema: this.getCaptchaSchema,
          isValid: this.isValid,
          resetHoneypot: this.resetHoneypot,
          setHoneypot: this.setHoneypot,
          validateHoneypot: this.validateHoneypot,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

BotDetectionProvider.propTypes = {
  children: PropTypes.node,
};

export { BotDetectionContext, BotDetectionProvider, useBotDetectionContext };
