let id = 0;

const createToast = (options) => {
  return {
    ...options,
    id: id++,
  };
};

export const addToast = (options = {}) => {
  return {
    payload: createToast(options),
    type: "ADD_TOAST",
  };
};

export const clearToasts = () => {
  return {
    type: "CLEAR_TOASTS",
  };
};

export const removeToast = (id) => {
  return {
    payload: id,
    type: "REMOVE_TOAST",
  };
};
