import { jVar } from "json-variables";
import { axios } from "portal-services";

/**
 * gets intl dictionaries from public directory
 * @param  {String} locale
 * @return {Promise}
 */
export const getIntl = ({ airlineCode = "", locale }) => {
  if (process.env.HAS_ABP_CONFIG === "true") {
    return axios({
      method: "get",
      url: `/ui-config/dictionaries/${locale}.json`,
      headers: {
        "airline-code": airlineCode,
      },
      params: {
        timestamp: new Date().getTime(),
      },
    });
  } else if (process.env.THEME_ID) {
    return axios({
      method: "get",
      url: "/cmsthemes",
      params: {
        airlineCode: process.env.THEME,
        filePath: "dictionaries",
        fileName: locale.replace("-", "_"),
        state: "obpdevelopment",
        theme_id: process.env.THEME_ID,
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformDictionaries(data);
        },
      ],
    });
  } else {
    return axios({
      method: "get",
      url: `/configuration/dictionaries/${locale}.json`,
      baseURL: process.env.PUBLIC_URL,
      params: {
        timestamp: new Date().getTime(),
      },
    });
  }
};

/**
 * tranforms dictionaries to readable format and includes json variables
 * @param {Object} data
 * @returns {Object}
 */
const transformDictionaries = (data) => {
  const response = JSON.parse(data);

  return typeof response === "string"
    ? jVar(JSON.parse(response))
    : jVar(response);
};
