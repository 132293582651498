export const toastsReducer = (state = [], action) => {
  const { payload, type } = action;

  switch (type) {
    case "ADD_TOAST":
      return [payload];

    case "CLEAR_TOASTS":
      return [];

    case "REMOVE_TOAST":
      return state.filter((toast) => toast.id !== payload);

    default:
      return state;
  }
};
