import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
/* eslint-disable complexity */
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
      };
    case "GET_USER_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_USER_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
      };
    case "SET_USER_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "LOGIN_USER":
      return {
        ...state,
      };
    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
      };
    case "LOGIN_USER_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGOUT_LOYALTY_USER":
      return {
        ...state,
        isLoading: true,
      };
    case "LOGOUT_LOYALTY_USER_SUCCESS":
      return {
        data: { ...state.data, ...action.payload },
        isLoading: false,
      };
    case "LOGOUT_USER_SUCCESS":
      return {
        data: { ...state.data, ...action.payload },
        isLoading: false,
      };
    case "LOGOUT_USER_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "SET_USER_ACTIVE_PASS":
      return { ...state, data: { ...state.data, activePass: action.payload } };
    default:
      return state;
  }
};
/* eslint-enable complexity */
