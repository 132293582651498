import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
/* eslint-disable complexity */
export const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATUS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_STATUS_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
      };
    case "GET_STATUS_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "SET_STATUS":
      return {
        ...state,
        isLoading: true,
      };
    case "SET_STATUS_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
      };
    case "SET_STATUS_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
/* eslint-enable complexity */
