/**
 * get weather redux action
 * @param  {String} options.airportCode
 * @param  {Boolean} options.status
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Object}
 */
export const getWeather = ({
  airportCode,
  airportCodeIcao,
  isCelsius,
  locale = "en-US",
  status,
}) => {
  return {
    type: "GET_WEATHER",
    payload: {
      airportCode,
      airportCodeIcao,
      isCelsius,
      locale,
      status,
    },
    meta: {
      thunk: true,
    },
  };
};

export const toggleScale = () => ({ type: "TOGGLE_WEATHER_SCALE" });

export const setScale = (scale) => ({
  type: "SET_WEATHER_SCALE",
  payload: scale,
});
