import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { AnalyticsProvider } from "portal-analytics";
import { BotDetectionProvider } from "portal-components";
import { LoggerProvider } from "portal-services";

import ErrorBoundary from "./components/ErrorBoundary";
import MetaTagProvider from "./components/MetaTagProvider";

import {
  getLocale,
  initCaptiveApp,
  initGroundPaymentApp,
  initIFCApp,
  initLegalContentApp,
  initOAuthApp,
  initPaymentApp,
  isCaptiveInitialized,
  isGroundPaymentInitialized,
  isIFCInitialized,
  isLegalContentAppInitialized,
  isOAuthInitialized,
  isPaymentInitialized,
} from "../initializer/index";
import "reset-css";

import "@assets/styles/_grid.scss";
import "@src/app/_index.scss";

import App from "./index";

class RenderApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAppInitialized: false,
      isLocaleInitialized: false,
      locale: "",
    };

    this.initializeApps();
  }

  initializeApps = async () => {
    switch (process.env.APP) {
      case "captive": {
        await initCaptiveApp({ dispatch: this.props?.mappedDispatch });
        this.setState({ isAppInitialized: isCaptiveInitialized(this.props) });
        break;
      }
      case "ifc": {
        await initIFCApp({ dispatch: this.props?.mappedDispatch });
        this.setState({ isAppInitialized: isIFCInitialized(this.props) });
        break;
      }
      case "oauth": {
        await initOAuthApp({ dispatch: this.props?.mappedDispatch });
        this.setState({ isAppInitialized: isOAuthInitialized(this.props) });
        break;
      }
      case "payment": {
        await initPaymentApp({ dispatch: this.props?.mappedDispatch });
        this.setState({ isAppInitialized: isPaymentInitialized(this.props) });
        break;
      }
      case "paymentground": {
        await initGroundPaymentApp({ dispatch: this.props?.mappedDispatch });
        this.setState({
          isAppInitialized: isGroundPaymentInitialized(this.props),
        });
        break;
      }
      case "legalcontent": {
        await initLegalContentApp({ dispatch: this.props?.mappedDispatch });
        this.setState({
          isAppInitialized: isLegalContentAppInitialized(this.props),
        });
        break;
      }
      default: {
        await initIFCApp({ dispatch: this.props?.mappedDispatch });
        this.setState({ isAppInitialized: isIFCInitialized(this.props) });
        break;
      }
    }
  };

  componentDidUpdate = () => {
    if (!this.state.isLocaleInitialized && this.state.isAppInitialized) {
      const locale = getLocale({ config: this.props?.config?.data });

      this.setState({
        locale: locale,
        isLocaleInitialized: true,
      });
    }
  };

  removeTabClass() {
    document.body.classList.remove("tabbing");
  }

  addTabClass() {
    document.body.classList.add("tabbing");
  }

  componentDidMount() {
    document.body.addEventListener("mousedown", this.removeTabClass);
    document.body.addEventListener("keydown", this.addTabClass);
  }

  componentWillUnmount() {
    document.body.removeEventListener("mousedown", this.removeTabClass);
    document.body.removeEventListener("keydown", this.addTabClass);
  }

  render() {
    const { config } = this.props;
    const { isLocaleInitialized, locale } = this.state;

    return (
      <>
        <MetaTagProvider />
        {isLocaleInitialized && (
          <IntlProvider
            locale={locale}
            textComponent="span"
            wrapRichTextChunksInFragment={true}
          >
            <AnalyticsProvider config={config.data}>
              <LoggerProvider>
                <Router basename={config.data.baseName}>
                  <ErrorBoundary>
                    <BotDetectionProvider>
                      <App />
                    </BotDetectionProvider>
                  </ErrorBoundary>
                </Router>
              </LoggerProvider>
            </AnalyticsProvider>
          </IntlProvider>
        )}
      </>
    );
  }
}

RenderApp.propTypes = {
  config: PropTypes.object,
  flightInfo: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
  mappedDispatch: PropTypes.func,
  status: PropTypes.object,
  theme: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    flightInfo: state.flightInfo,
    intl: state.intl,
    status: state.status,
    theme: state.theme,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mappedDispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RenderApp);
