/**
 * get user redux action
 * @param  {Object} options.config
 * @param  {Boolean} options.flightChanged
 * @param  {Object} options.flightInfo
 * @param  {Object} options.locale
 * @param  {Object} options.status
 * @return {Object}
 */
export const getUser = ({
  config,
  flightChanged,
  flightInfo,
  locale,
  status,
}) => {
  return {
    type: "GET_USER",
    payload: { config, flightChanged, flightInfo, locale, status },
    meta: {
      thunk: true,
    },
  };
};

/**
 * set user redux action
 * @param  {Object} options.user
 * @return {Object}
 */
export const setUser = (user) => {
  return {
    type: "SET_USER",
    payload: user,
    meta: {
      thunk: true,
    },
  };
};

/**
 * log out user redux action
 * @param  {Object} options.flightInfo
 * @param  {Object} options.status
 * @return {Object}
 */
export const logOutUser = ({ flightInfo, status }) => {
  return {
    type: "LOGOUT_USER",
    payload: { flightInfo, status },
    meta: {
      thunk: true,
    },
  };
};

/**
 * log in user redux action
 * @param  {Object} options.status
 * @return {Object}
 */
export const logInUser = ({ emailAddress, password, uxdId, status }) => {
  return {
    type: "LOGIN_USER",
    payload: { emailAddress, password, uxdId, status },
    meta: {
      thunk: true,
    },
  };
};

/**
 * set user active pass redux action
 * @param  {Object} options.data
 * @return {Object}
 */
export const setUserActivePass = (data) => {
  return {
    type: "SET_USER_ACTIVE_PASS",
    payload: data,
  };
};

/**
 * log out loyalty user redux action
 * @param  {Object} options.user
 * @return {Object}
 */
export const logOutLoyaltyUser = (user) => {
  return {
    type: "LOGOUT_LOYALTY_USER",
    payload: user,
    meta: {
      thunk: true,
    },
  };
};
