import queryString from "query-string";

import {
  isIFCApp,
  QUERY_AIRLINE_CODE,
  QUERY_IS_INDIVIDUAL_PIPELINE,
  QUERY_RETURN_URL,
  ROUTE_IFE,
} from "portal-utilities";

/**
 * gets external redirection given current location, new location, and list of possible redirects
 * @param  {Object} options.currentLocation
 * @param  {Objext} options.location
 * @param  {Array}  options.redirects       from redirects.js file in application
 * @return {Boolean|String}
 */
export const getExternalRedirection = ({
  config,
  currentLocation,
  location,
  redirects,
}) => {
  const pathname = location?.pathname;

  const currentApp = currentLocation?.pathname?.split("/")[1];
  const newApp = pathname?.split("/")[1];

  let dynamicId = false;

  const redirect = redirects.find((redirect) => {
    dynamicId = redirect?.to?.includes("/:id")
      ? pathname.substring(pathname.lastIndexOf("/") + 1)
      : false;

    if (dynamicId) {
      return redirect?.from === pathname.replace(`/${dynamicId}`, "");
    }

    return redirect?.from === pathname;
  });

  if (!redirect) {
    return false;
  }

  const redirectUrl = dynamicId
    ? redirect?.to.replace(":id", dynamicId)
    : redirect?.to;

  const search = queryString.parse(location?.search);
  const query = queryString.parse(redirect?.query);

  let returnUrl = isIFCApp()
    ? {
        [QUERY_RETURN_URL]: window.location.origin,
      }
    : {};

  if (isIFCApp() && process.env.HAS_ABP_CONFIG === "true") {
    returnUrl = {
      ...returnUrl,
      [QUERY_AIRLINE_CODE]: config?.key,
      [QUERY_IS_INDIVIDUAL_PIPELINE]:
        process.env.IS_INDIVIDUAL_PIPELINE === "true" ? "true" : "false",
    };
  }

  if (pathname.includes(ROUTE_IFE)) {
    returnUrl = {};
  }

  return {
    url: queryString.stringifyUrl({
      url: redirectUrl,
      query: { ...search, ...query, ...returnUrl },
    }),
    reload: process.env.NODE_ENV === "development" && currentApp !== newApp,
  };
};
