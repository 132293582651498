import queryString from "query-string";

import {
  getAirborneMediaBaseUrl,
  getAirborneSecureRedirectBaseUrl,
} from "portal-services";
import {
  getDefaultRoute,
  isIFCApp,
  isIFEApp,
  isLocalhostApp,
  isPaymentApp,
  QUERY_RETURN_URL,
  ROUTE_ACCOUNT,
  ROUTE_IFC,
  ROUTE_IFC_ACTIVATION_RETRY,
  ROUTE_IFC_PASS_DETAILS,
  ROUTE_IFC_PRE_PURCHASED_PASSES,
  ROUTE_IFC_SPLASH,
  ROUTE_IFE,
  ROUTE_IFE_ENTERTAINMENT,
  ROUTE_IFE_LIVE_TV,
  ROUTE_IFE_MEDIA,
  ROUTE_PAYMENT,
  URLS,
} from "portal-utilities";

/**
 * @const internalRedirects
 */
const internalRedirects = [{ from: "/", to: getDefaultRoute() }];

/**
 * @return {Array} redirects
 */
const externalIfeRedirects = () => {
  return [];
};

/**
 * @param  {Object} params
 * @return {Array}
 */
const externalIfcRedirects = (params) => {
  const { config, locale, status, uxdId } = params;

  return [
    {
      from: ROUTE_ACCOUNT,
      to: `${getAirborneSecureRedirectBaseUrl(
        status
      )}/app/gogo/account/#?uxdId=${uxdId}&lang=${locale.replace("-", "_")}`,
    },
    {
      from: ROUTE_IFE,
      to: `${getAirborneMediaBaseUrl(status)}/app/`,
    },
    {
      from: ROUTE_IFE_ENTERTAINMENT,
      to: `${getAirborneMediaBaseUrl(status)}/app/#/entertainment`,
    },
    {
      from: ROUTE_IFE_MEDIA,
      to: `${getAirborneMediaBaseUrl(status)}/app/#/storefront/:id`,
    },
    {
      from: ROUTE_IFE_LIVE_TV,
      to: `${getAirborneMediaBaseUrl(status)}/app/#/iptv`,
    },
    {
      from: ROUTE_PAYMENT,
      to: `${getAirborneSecureRedirectBaseUrl(status)}${
        config?.baseName
      }${ROUTE_PAYMENT}`,
    },
  ];
};

/**
 * @param {Object} params
 * @return {Array} redirects
 */
const externalPaymentRedirects = (params) => {
  const { config, currentLocation } = params;

  const returnUrl = queryString.parse(currentLocation?.search)[
    QUERY_RETURN_URL
  ];

  return [
    {
      from: ROUTE_IFC,
      to: returnUrl
        ? `${returnUrl}${config?.baseName}${ROUTE_IFC}`
        : `http://${URLS?.airborne}`,
    },
    {
      from: ROUTE_IFC_ACTIVATION_RETRY,
      to: returnUrl
        ? `${returnUrl}${config?.baseName}${ROUTE_IFC_ACTIVATION_RETRY}`
        : `http://${URLS?.airborne}`,
    },
    {
      from: ROUTE_IFC_SPLASH,
      to: returnUrl
        ? `${returnUrl}${config?.baseName}${ROUTE_IFC_SPLASH}`
        : `http://${URLS?.airborne}`,
    },
    {
      from: ROUTE_IFC_PASS_DETAILS,
      to: returnUrl
        ? `${returnUrl}${config?.baseName}${ROUTE_IFC_PASS_DETAILS}`
        : `http://${URLS?.airborne}`,
    },
    {
      from: ROUTE_IFC_PRE_PURCHASED_PASSES,
      to: returnUrl
        ? `${returnUrl}${config?.baseName}${ROUTE_IFC_PRE_PURCHASED_PASSES}`
        : `http://${URLS?.airborne}`,
    },
  ];
};

/**
 * @return {Array} redirects
 */
const externalAccountRedirects = () => {
  return [];
};

/**
 * @return {Array} redirects
 */
const externalOAuthRedirects = () => {
  return [];
};

/**
 * @param  {Object} params
 * @return {Array}
 */
/* eslint-disable complexity */
const externalRedirects = (params) => {
  let redirects = [];
  params.location.previousPathname = params?.currentLocation;

  if (isIFEApp() || isLocalhostApp()) {
    redirects = redirects.concat(externalIfeRedirects(params));
  }

  if (isIFCApp() || isLocalhostApp()) {
    redirects = redirects.concat(externalIfcRedirects(params));
  }

  if (isPaymentApp() || isLocalhostApp()) {
    redirects = redirects.concat(externalPaymentRedirects(params));
  }

  if (process.env.APP === "account" || !process.env.APP) {
    redirects = redirects.concat(externalAccountRedirects(params));
  }

  if (process.env.APP === "oauth" || !process.env.APP) {
    redirects = redirects.concat(externalOAuthRedirects(params));
  }

  return redirects;
};

export {
  internalRedirects,
  externalIfeRedirects,
  externalIfcRedirects,
  externalPaymentRedirects,
  externalAccountRedirects,
  externalOAuthRedirects,
  externalRedirects,
};
