import { axios, getAirborneMediaASPBaseUrl } from "portal-services";

/**
 * gets list of available media locales on ACPU
 * @param  {Boolean} status
 * @return {Promise}
 */
export const getCatalogLocales = (status) => {
  return axios({
    method: "get",
    url: "/localelist",
    baseURL: getAirborneMediaASPBaseUrl(status),
    transformResponse: [
      /* istanbul ignore next */
      function (data) {
        return transformLocaleList(data);
      },
    ],
  });
};

/**
 * tranforms service status to readable format
 * @param {Object} data
 * @return {Array|undefined}
 */
export const transformLocaleList = (data) => {
  const response = JSON.parse(data);

  return Array.isArray(response) ? response : undefined;
};
