/**
 * return flight data if data object value is present
 * @param  {object} data
 */
export const getByPassLogInfo = (data = null) => {
  if (data) {
    return {
      airlineCode: data?.airlineCode,
      arrival: data?.arrival,
      departure: data?.departure,
      flightNumber: data?.flightNumber,
      macAddress: data?.user?.macAddress,
      message: "Redirecting to Bypass maintainence.",
      tailNumber: data?.tailNumber,
    };
  }
  return null;
};
