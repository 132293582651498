import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        error: "",
        isLoading: false,
      };
    case "GET_PRODUCTS_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
