import { initialState } from "../index";
import merge from "lodash/merge";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
/* eslint-disable complexity */
export const flightInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FLIGHT_INFO":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_FLIGHT_INFO_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
        error: "",
      };
    case "GET_FLIGHT_INFO_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case "UPDATE_FLIGHT_INFO":
      return {
        ...state,
      };
    case "UPDATE_FLIGHT_INFO_SUCCESS":
      return merge({}, state, { data: { ...action.payload } }, { error: "" });

    case "UPDATE_FLIGHT_INFO_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
