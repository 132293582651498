// First 3 imports are polyfills
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-relativetimeformat/polyfill";
import matchAll from "string.prototype.matchall";

import React from "react";
import ReactDOM from "react-dom";
import { AirlineApp } from "portal-shells";
import config from "@theme/config.json";

// matchAll polyfill
matchAll.shim();

ReactDOM.render(
  <AirlineApp config={config} />,
  document.getElementById("root")
);
