import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import Loader from "@src/app/components/Loader";
import CookiesRequired from "@src/app/pages/CookiesRequired";
import ServiceNotAvailable from "@src/app/pages/ServiceNotAvailable";
import loadable from "@loadable/component";
import {
  externalRedirects,
  internalRedirects,
} from "@src/app/router/redirects";

import {
  getExternalRedirection,
  isIFCApp,
  isIFEApp,
  isLegalContentApp,
  isLocalhostApp,
  isPaymentApp,
  isRTL,
  LEGAL_CONTENT_APP_NAME,
  ROUTE_ACCOUNT,
  ROUTE_CAPTIVE,
  ROUTE_IFC,
  ROUTE_IFE,
  ROUTE_LEGAL_CONTENT,
  ROUTE_OAUTH,
  ROUTE_PAYMENT,
  ROUTE_ROUTES,
} from "portal-utilities";

import Tools from "./components/Tools";
import Routes from "./pages/Routes";

const IFE =
  isIFEApp() || isLocalhostApp()
    ? loadable(() => import(/* webpackChunkName: "ife" */ "@src/app/pages/IFE"))
    : null;

const IFC =
  isIFCApp() || isLocalhostApp()
    ? loadable(() => import(/* webpackChunkName: "ifc" */ "@src/app/pages/IFC"))
    : null;

const Legal =
  isLegalContentApp() || isLocalhostApp()
    ? loadable(() =>
        import(/* webpackChunkName: "ifc" */ "@src/app/pages/LegalContent")
      )
    : null;

const Payment =
  isPaymentApp() || isLocalhostApp()
    ? loadable(() =>
        import(/* webpackChunkName: "payment" */ "@src/app/pages/Payment")
      )
    : null;

const Account =
  process.env.APP === "account" || isLocalhostApp()
    ? loadable(() =>
        import(/* webpackChunkName: "account" */ "@src/app/pages/Account")
      )
    : null;

const Captive =
  process.env.APP === "captive" || isLocalhostApp()
    ? loadable(() =>
        import(/* webpackChunkName: "captive" */ "@src/app/pages/Captive")
      )
    : null;

const OAuth =
  process.env.APP === "oauth" || isLocalhostApp()
    ? loadable(() =>
        import(/* webpackChunkName: "oauth" */ "@src/app/pages/OAuth")
      )
    : null;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRedirecting: false,
    };
  }

  externalRedirect = (params) => {
    const { config, currentLocation, location } = params;

    const redirects = externalRedirects(params);

    const redirection = getExternalRedirection({
      config,
      currentLocation,
      location,
      redirects,
    });

    if (redirection && redirection?.reload) {
      this.props?.history?.block();
      this.setState({ isRedirecting: true });
      window.location.reload();
    } else if (redirection && process.env.NODE_ENV === "production") {
      this.props?.history?.block();
      this.setState({ isRedirecting: true });
      window.location.replace(redirection?.url);
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props?.location?.pathname !== nextProps?.location?.pathname) {
      window.scrollTo(0, 0);

      if (
        isIFCApp() &&
        !this.props?.status?.data?.linkStatus &&
        this.props?.status?.data?.ifeStatus
      ) {
        window.location.href = `${this.props?.status?.data?.protocol}://${this.props?.status?.data?.mediaHostName}/app/#/entertainment`;
      }

      if (!this.props?.user?.isLoading) {
        this.externalRedirect({
          config: this.props?.config?.data,
          currentLocation: this.props?.location,
          locale: this.props?.intl?.locale,
          location: this.props?.history?.location,
          status: this.props?.status?.data,
          uxdId: this.props?.user?.data?.uxdId,
        });
      }

      return false;
    }

    if (this.props?.user?.isLoading !== nextProps?.user?.isLoading) {
      this.externalRedirect({
        config: nextProps?.config?.data,
        currentLocation: nextProps?.location,
        locale: nextProps?.intl?.locale,
        location: nextProps?.history?.location,
        status: nextProps?.status?.data,
        uxdId: nextProps?.user?.data?.uxdId,
      });
    }

    return true;
  }

  /* eslint-disable complexity */
  render() {
    const { config, flightInfo, intl, location, status } = this.props;
    const { isRedirecting } = this.state;
    const app = process.env.APP;
    const isLegacySNA = location?.hash?.includes("sna-abp");
    const isSNAPage =
      app === "ifc"
        ? (!status?.data?.linkStatus ||
            !flightInfo?.data?.system?.ifcPaxServiceState) &&
          !status?.data?.ifeStatus
        : false;

    if (isRedirecting) {
      return <Loader />;
    } else if (!navigator.cookieEnabled) {
      return <CookiesRequired />;
    } else if (
      status?.error ||
      isLegacySNA ||
      (isSNAPage && !config?.data?.hasThirdPartyIFE)
    ) {
      return <ServiceNotAvailable />;
    } else if (
      isIFCApp() &&
      (!status?.data?.linkStatus ||
        !flightInfo?.data?.system?.ifcPaxServiceState) &&
      status?.data?.ifeStatus
    ) {
      return <Redirect to={ROUTE_IFE} />;
    } else if (!isRedirecting && navigator.cookieEnabled) {
      return (
        <div className="app" dir={isRTL(intl?.locale) ? "rtl" : ""}>
          <Switch>
            {(app === "ife" || !app) && (
              <Route path={ROUTE_IFE} component={IFE} />
            )}

            {(app === "ifc" || !app) && (
              <Route path={ROUTE_IFC} component={IFC} />
            )}

            {(app === LEGAL_CONTENT_APP_NAME || !app) && (
              <Route path={ROUTE_LEGAL_CONTENT} component={Legal} />
            )}

            {(app === "payment" || !app) && (
              <Route path={ROUTE_PAYMENT} component={Payment} />
            )}

            {(app === "account" || !app) && (
              <Route path={ROUTE_ACCOUNT} component={Account} />
            )}

            {(app === "captive" || !app) && (
              <Route path={ROUTE_CAPTIVE} component={Captive} />
            )}

            {(app === "oauth" || !app) && (
              <Route path={ROUTE_OAUTH} component={OAuth} />
            )}

            {process.env.NODE_ENV === "development" && (
              <Route path={ROUTE_ROUTES} component={Routes} />
            )}

            {internalRedirects.map((redirect) => (
              <Redirect
                key={redirect.from}
                to={{
                  ...location,
                  pathname: redirect.to,
                }}
                from={redirect.from}
                push
              />
            ))}
          </Switch>
          {process.env.NODE_ENV === "development" && <Tools config={config} />}
        </div>
      );
    }
  }
}

App.propTypes = {
  config: PropTypes.object,
  flightInfo: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
  status: PropTypes.object,
  theme: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
    flightInfo: state.flightInfo,
    intl: state.intl,
    status: state.status,
    theme: state.theme,
    user: state.user,
  };
};

export default compose(withRouter, connect(mapStateToProps))(App);
