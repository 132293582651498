import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";

/**
 * logs out the user
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const logOut = ({ uxdId, status }) => {
  if (!uxdId) return Promise.reject(new Error("uxdId is invalid or undefined"));

  return axios({
    method: "post",
    url: `/v2/logout/${uxdId}`,
    baseURL: getAirborneABPEdgeBaseUrl(status),
    transformResponse: [
      function (data) {
        return transformLogOut(data);
      },
    ],
  });
};

/**
 * tranforms logout response to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformLogOut = (data) => {
  const response = JSON.parse(data);

  if (response && response.statusCode === "200") {
    return { authenticated: false };
  } else {
    return {};
  }
};
