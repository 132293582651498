import React from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import { getTimes } from "timing.js";

import Analytics from "analytics";
import { AnalyticsProvider, useAnalytics, withAnalytics } from "use-analytics";

import { getWifiBaseUrl } from "portal-services";
import {
  COOKIE_UXDID,
  getCookie,
  getDeviceType,
  getUserAgent,
} from "portal-utilities";

import withPageLogging from "./utilities/withPageLogging";
import getSplashLoadTime from "./utilities/getSplashLoadTime";

import { version as packageVersion } from "@packageJson";

/**
 * @function calculateTimes
 * @summary Return times values only from getTimes().
 * @see {@link https://github.com/addyosmani/timing.js}
 * @returns {Object} TIMES
 */

const calculateTimes = () => {
  const times = getTimes();
  let TIMES = {};
  for (let key of Object.keys(times)) {
    if (key.includes("Time")) {
      TIMES[key] = times[key];
    }
  }
  return TIMES;
};

/**
 * @function logEntry
 * @summary Submits log entry to ABP catalina log.
 * @param {Object} DATA
 * @returns Promise
 */

const logEntry = (DATA) =>
  axios({
    data: DATA,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "post",
    url: `/abp/rest/v1/data`,
    baseURL: getWifiBaseUrl(),
  })
    .then(() => {})
    .catch(() => {});

/**
 * @function formatLogEntry
 * @summary Enriches DATA before logging to ABP.
 * @param {Object} DATA
 * @returns {Object} PROPERTIES
 */

const formatLogEntry = (DATA) => {
  const {
    browser,
    os,
    platform: { vendor },
  } = getUserAgent().parsedResult;

  const {
    airlineCode,
    arrival,
    connectivityType,
    departure,
    departureCodeIcao,
    destinationCodeIcao,
    flightNumber,
    headers,
    message,
    method,
    statusCode,
    system,
    tailNumber,
    timestamp,
    url,
    user,
  } = DATA;

  const PROPERTIES = {
    // NOTE values below are available from FIG enrichment on telemetry framework
    // and should be removed once parser is in place
    accessTechnology: connectivityType || system?.type,
    airlineCode: airlineCode?.toUpperCase(),
    destination: destinationCodeIcao || arrival?.airportCodeIcao,
    flightNumber,
    origin: departureCodeIcao || departure?.airportCodeIcao,
    tailNumber,

    // computed on front-end
    browser: `${browser?.name} ${browser?.version}`,
    browserType: vendor ? vendor : "",
    date: moment(timestamp).milliseconds(0).toISOString(),
    deviceType: getDeviceType(),
    headers,
    macAddress: user?.macAddress,
    message,
    method,
    operatingSystem: os?.version ? `${os?.name} ${os?.version}` : os?.name,
    osType: vendor ? `${vendor} ${os?.name}` : os?.name,
    packageVersion,
    pageName: window.location.href,
    serviceStatus: {
      ...DATA?.data,
    },
    statusCode,
    url,
    userAgent: getUserAgent()?._ua,
    visitorId: getCookie(COOKIE_UXDID),
  };

  return PROPERTIES;
};

/**
 * @function resultHandler
 * @summary loggerInterceptor result handler
 * @see {@link https://github.com/axios/axios#interceptors}
 * @param {Object} response or error
 * @returns {Function}
 */

const resultHandler = ({
  config: { headers, method, performance, timestamp, url },
  flightInfo,
  message,
  response: { status: statusCode } = {},
}) => {
  logEntry({
    "ui-log": {
      performance: performance,
      properties: formatLogEntry({
        ...flightInfo,
        headers,
        message: `${message}: ${url}`,
        method,
        statusCode,
        url,
      }),
      timestamp: timestamp,
      type: "apiCall",
    },
  });
};

let flightInfo = {};
/**
 * @function loggerInterceptor
 * @summary Adds axios interceptor to log API calls to ABP.
 * @see {@link https://github.com/axios/axios#interceptors}
 * @param {Object} AXIOS
 * @returns {Function}
 */

export const loggerInterceptor = (AXIOS) => {
  const { request, response } = AXIOS.interceptors;

  request.use((config) => {
    config.performance = calculateTimes();
    config.timestamp = Date.now();
    return config;
  });

  response.use(
    (response) => {
      if (
        response?.config?.url?.includes("statusTray") ||
        response?.config?.url?.includes("/flightInfo/v1/client/tail/tailNumber")
      ) {
        flightInfo = {
          ...flightInfo,
          ...response.data,
        };
      }
      return Promise.resolve(response);
    },
    (error) => {
      if (
        process.env.APP !== "oauth" &&
        process.env.NODE_ENV !== "development" &&
        process.env.TEST !== "cypress"
      ) {
        resultHandler({ ...error, flightInfo, message: "Failure" });
      }
      return Promise.reject(error);
    }
  );
};

/**
 * @function abpLogger
 * @param {string} message The message to search for in abp logs: ex. /usr/java/util/tomcat/logs/gogoabp-catalina.out
 * @param {Object} flightInfo The statusTray object to log.
 * @returns {Function} logEntry
 * @exports abpLogger
 */

export const abpLogger = ({ message, flightInfo, flightInfoPrev }) => {
  const {
    browser,
    os,
    platform: { vendor },
  } = getUserAgent().parsedResult;

  logEntry({
    "OBP-log": {
      message,
      flightInfo,
      flightInfoPrev,
      browser,
      os,
      userAgent: getUserAgent()?._ua,
      vendor,
      visitorId: getCookie(COOKIE_UXDID),
    },
  });
};

/**
 * @function Logger
 * @summary Alias for Analytics
 * @see {@link https://getanalytics.io/}
 * @returns {Object} Logger
 * @exports Logger
 */

export const Logger = Analytics({
  app: "logger",
  plugins: [
    {
      name: "logger-plugin",
      track: ({ payload }) => {
        const { event, properties } = payload;
        const timestamp = Date.now();
        logEntry({
          "ui-log": {
            performance: event === "pageLoad" ? calculateTimes() : {}, // adds performance metrics to pageLoad entries
            properties: formatLogEntry({
              ...flightInfo,
              ...properties,
              timestamp,
            }),
            timestamp: timestamp,
            type: event,
          },
        });
      },
    },
  ],
});

/**
 * @function RouteWithPageLogging
 * @return withPageLogging component
 * @exports RouteWithPageLogging
 */

export const RouteWithPageLogging = withPageLogging(Route);
/**
 * @function useLogger
 * @summary Alias for useAnalytics hook.
 * @see {@link https://getanalytics.io/utils/react-hooks/}
 * @returns Function useAnalytics
 */

export const useLogger = () => useAnalytics();

/**
 * @function withLogger
 * @return withAnalytics component
 * @exports withLogger
 */

export const withLogger = withAnalytics;

/**
 * @function LoggerProvider
 * @summary Alias for AnalyticsProvider
 * @see {@link https://getanalytics.io/utils/react-hooks/}
 * @param {React.Node} children
 * @returns React.Node
 * @exports LoggerProvider
 */

export const LoggerProvider = ({ children }) => (
  <AnalyticsProvider instance={Logger}>{children}</AnalyticsProvider>
);

/**
 * @function LoggerSplashLoadTime
 * @summary log splash load time after GET_PRODUCTS_SUCCESS
 * @exports LoggerSplashLoadTime
 */

export const logSplashLoadTime = (payload) => {
  const message = getSplashLoadTime(payload.user);
  if (message) {
    Logger.track("splashLoadTime", {
      ...payload,
      message,
    });
  }
};

/**
 * @functionlogUnavailableProducts
 * @summary log when products are unavailble
 * @exports logUnavailableProducts
 */

export const logUnavailableProducts = (status) => {
  Logger.track("unavailableProducts", {
    ...status,
  });
};

export const logMaintenanceBypass = async (payload) => {
  Logger.track("maintenanceBypass", {
    ...payload,
  });
};

LoggerProvider.propTypes = {
  children: PropTypes.node,
};
