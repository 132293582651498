import Bowser from "bowser";
import UAParser from "ua-parser-js";
import config from "./config.json";

const browser = () => Bowser.getParser(window.navigator.userAgent);
const parser = new UAParser();

/**
 * returns parsed user agent
 * @return {Object}
 */
export const getUserAgentInfo = () => {
  parser.setUA(window.navigator.userAgent);
  return parser.getResult();
};

/**
 * returns device type
 * @return {string}
 */
export const getDeviceType = () =>
  getUserAgentInfo()?.device?.type || "desktop";

/**
 * returns detailed device info
 * @return {object}
 */
export const getDeviceInfo = () => getUserAgentInfo();

/**
 * returns parsed user agent
 * @return {Object}
 */
export const getUserAgent = () => browser();

/**
 * returns boolean if user agent is supported browser
 * @return {Boolean}
 */
export const isSupportedBrowser = () => browser().satisfies(config) || false;

/**
 * returns boolean if user agent is Mac browser
 * @return {Boolean}
 */
export const isMacBrowser = () => browser().is("iOS") || browser().is("macOS");

/**
 * returns boolean if user agent is Chrome OS
 * @return {Boolean}
 */
export const isChromeOS = () => browser().is("Chrome OS");

/**
 * returns boolean if user agent is a Chrome browser and OS is Chromium OS
 * @return {Boolean}
 */
export const isChromeBrowser = () =>
  getUserAgentInfo()?.browser?.name === "Chrome" &&
  getUserAgentInfo()?.os?.name === "Chromium OS";

/**
 * returns boolean if user agent is mobile
 * @return {Boolean}
 */
export const isMobileBrowser = () => getDeviceType() === "mobile";

/**
 * returns boolean if user agent is tablet
 * @return {Boolean}
 */
export const isTabletBrowser = () => getDeviceType() === "tablet";

/**
 * returns boolean if user agent is desktop
 * @return {Boolean}
 */
export const isDesktopBrowser = () => getDeviceType() === "desktop";

/**
 * returns boolean if user agent is IOS captive browser
 * @return {Boolean}
 */
export const isCaptiveIOSBrowser = () =>
  process.env.NODE_ENV === "development"
    ? isMacBrowser()
    : isMacBrowser() && browser().getBrowserVersion() === undefined;

/**
 * returns boolean if user agent is NOT IOS captive browser
 * @return {Boolean}
 */
export const isCaptiveNonIOSBrowser = () =>
  process.env.NODE_ENV === "development"
    ? !isMacBrowser()
    : browser().getUA().includes("wv") && !isCaptiveIOSBrowser();
