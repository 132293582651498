/**
 * imports locale plural rules polyfill from formatjs
 * @param  {String} locale
 * @return {Module}
 */
// TODO: Fix code complexity
/* eslint-disable complexity */
export const importLocalePluralRules = (locale) => {
  let formattedLocale = locale.slice(0, 2);

  switch (formattedLocale) {
    case "en":
      return require(`@formatjs/intl-pluralrules/locale-data/en`);
    case "de":
      return require(`@formatjs/intl-pluralrules/locale-data/de`);
    case "es":
      return require(`@formatjs/intl-pluralrules/locale-data/es`);
    case "ja":
      return require(`@formatjs/intl-pluralrules/locale-data/ja`);
    case "fr":
      return require(`@formatjs/intl-pluralrules/locale-data/fr`);
    case "pt":
      return require(`@formatjs/intl-pluralrules/locale-data/pt`);
    case "ko":
      return require(`@formatjs/intl-pluralrules/locale-data/ko`);
    case "zh":
      return require(`@formatjs/intl-pluralrules/locale-data/zh`);
    case "ar":
      return require(`@formatjs/intl-pluralrules/locale-data/ar`);
  }
};

/**
 * imports locale relative time format polyfill from formatjs
 * @param  {String} locale
 * @return {Module}
 */
export const importLocaleRelativeTimeFormat = (locale) => {
  let formattedLocale = locale.slice(0, 2);

  switch (formattedLocale) {
    case "en":
      return require(`@formatjs/intl-relativetimeformat/locale-data/en`);
    case "de":
      return require(`@formatjs/intl-relativetimeformat/locale-data/de`);
    case "es":
      return require(`@formatjs/intl-relativetimeformat/locale-data/es`);
    case "ja":
      return require(`@formatjs/intl-relativetimeformat/locale-data/ja`);
    case "fr":
      return require(`@formatjs/intl-relativetimeformat/locale-data/fr`);
    case "pt":
      return require(`@formatjs/intl-relativetimeformat/locale-data/pt`);
    case "ko":
      return require(`@formatjs/intl-relativetimeformat/locale-data/ko`);
    case "zh":
      return require(`@formatjs/intl-relativetimeformat/locale-data/zh`);
    case "ar":
      return require(`@formatjs/intl-relativetimeformat/locale-data/ar`);
  }
};
