/**
 * get captive redux action
 * @param  {Object} options.serviceStatus  getServiceStatus response
 * @return {Object}
 */
export const getCaptive = ({ status }) => {
  return {
    type: "GET_CAPTIVE",
    payload: { status },
    meta: {
      thunk: true,
    },
  };
};
