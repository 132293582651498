import baseConfig from "../../../../../apps/multi_tenant/public/configuration/config.json";

// domain config store
export let URLS = {};

const setDomainConfig = (domainConfigs = {}, domainName) => {
  URLS = {
    ...URLS,
    ...domainConfigs[domainName],
  };
};

const isMatch = (domain, hostname) => new RegExp(`${domain}$`).test(hostname);

const findHost = (domainConfigs = {}) => {
  const domains = Object.keys(domainConfigs);
  const hostname = window.location.hostname;

  return domains.find((domain) => isMatch(domain, hostname));
};

const findHostAndSetDomainConfig = (domainConfigs, domainDefault) => {
  const matchedHostname = findHost(domainConfigs);

  return matchedHostname
    ? setDomainConfig(domainConfigs, matchedHostname)
    : setDomainConfig(domainConfigs, domainDefault);
};

const isLocalhost = () => window.location.hostname === "localhost";

const validateConfigs = (domainConfigs, domainDefault) => {
  if (!domainConfigs || !domainDefault) {
    console.warn(
      "Warning: domainConfigs or domainDefault property does not exist in config.json. Please deploy latest portal config."
    );
  }
};

/**
 * Set domain config store (URLS) values from airline config.
 * This should be called as soon as config is ready and available.
 * Best place to invoke this function is in global initializer.
 * @param  {Object} domainConfigs
 * @param {String} domainDefault
 */
export const setDomainUrls = ({ domainConfigs, domainDefault } = {}) => {
  validateConfigs(domainConfigs, domainDefault);

  if (isLocalhost()) {
    setDomainConfig(domainConfigs, domainDefault);
  } else {
    findHostAndSetDomainConfig(domainConfigs, domainDefault);
  }
};

const initialize = (config) => {
  setDomainUrls(config);
};

// Set domain config store with data
// from base config as initial values.
initialize(baseConfig);
