import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
export const intlReducer = (
  state = { ...initialState, messages: {} },
  action
) => {
  switch (action.type) {
    case "GET_INTL":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_INTL_SUCCESS":
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case "GET_INTL_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
