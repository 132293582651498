import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import { COOKIE_JWT_TOKEN, getHeaders, setCookie } from "portal-utilities";

/**
 * authenticates the customer
 * @param  {String} options.airlineCode
 * @param  {String} options.emailAddress
 * @param  {String} options.password
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */

export const authenticateCustomer = async ({
  airlineCode,
  emailAddress,
  password,
  uxdId,
  status,
}) => {
  const resp = await axios({
    method: "post",
    url: "v1/authenticate/",
    baseURL: getAirborneABPEdgeBaseUrl(status),
    headers: getHeaders(),
    transformResponse: [
      function (data) {
        return transformAuthenticateCustomer(data);
      },
    ],
    data: {
      airline_code: airlineCode,
      gogo_login: {
        data_types: ["PERSONAL", "PMTINSTRUMENTS", "GROUP_ATTRIBUTES"],
        password: password.trim(),
        user: emailAddress,
      },
      requester: "GOGO_INTERNET",
      uxd_id: uxdId,
    },
  });
  setCookie(COOKIE_JWT_TOKEN, resp?.headers?.authorization);
  return resp;
};

/**
 * tranforms authenticate customer response to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformAuthenticateCustomer = (data) => {
  let response = JSON.parse(data);

  if (response?.gogo_login_response) response = response?.gogo_login_response;
  const creditCardDetails = response?.credit_card_details?.[0];

  let output = {
    authenticated: true,
    creditCard: creditCardDetails?.last4_digits
      ? {
          billingAddress: {
            addressLine1: creditCardDetails?.billing_address?.address_line1,
            addressLine2: creditCardDetails?.billing_address?.address_line2,
            city: creditCardDetails?.billing_address?.city,
            country: creditCardDetails?.billing_address?.country,
            postalCode: creditCardDetails?.postal_code,
            state: creditCardDetails?.billing_address?.state,
          },
          cardID: creditCardDetails?.card_id,
          cardNumber: creditCardDetails?.last4_digits,
          cardType: creditCardDetails?.card_type,
          expMonth: creditCardDetails?.expiry_month,
          expYear: creditCardDetails?.expiry_year,
          nameOnCard: creditCardDetails?.name_on_card,
          paymentType: creditCardDetails?.payment_type,
        }
      : null,
    customerId: response?.customer_id,
    emailAddress: response?.email_address,
    firstName: response?.first_name,
    groups: response?.groups,
    lastName: response?.last_name,
    residentialAddress: response?.residential_address?.country
      ? {
          addressLine1: response?.residential_address?.address_line1,
          addressLine2: response?.residential_address?.address_line2,
          city: response?.residential_address?.city,
          country: response?.residential_address?.country,
          postalCode: response?.residential_address?.postal_code,
          state: response?.residential_address?.state,
        }
      : null,
    userName: response?.user_name,
  };

  return output;
};
