/**
 * get catalog redux action
 * flightInfo is optional
 * @param  {Object} options.flightInfo
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Object}
 */
export const getCatalog = ({ flightInfo = {}, locale = "en-US", status }) => {
  return {
    type: "GET_CATALOG",
    payload: { flightInfo, locale, status },
  };
};
