class AdapterInterface {
  bootstrap() {
    throw new Error("Adapter must implement 'bootstrap' method!");
  }

  setup() {
    throw new Error("Adapter must implement 'setup' method!");
  }

  track() {
    throw new Error("Adapter must implement 'track' method!");
  }
}

export default AdapterInterface;
