import uniq from "lodash/uniq";

import { getCatalogMedia } from "./media";
import { getCatalogStorefront } from "./storefront";
import { getCatalogMarquee } from "./marquee";
import { getCatalogSummary } from "./summary";

import { trackCustomEventOnDynatrace } from "portal-analytics/src/services";

import { abpLogger } from "portal-services";

const errorState = { isError: true, data: {} };

/**
 * catalog data for populating IFE movies and tv shows - flightInfo is only used for abpLogger
 * @param  {Object} options.flightInfo
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getCatalog = async ({
  flightInfo = {},
  locale = "en-US",
  status,
}) => {
  // Media, Summary
  const {
    genres,
    hasMultiStorefront,
    languages,
    media,
    mediaDictionary,
    storefrontId,
    summary,
  } = await getMediaSummary({ locale, status });

  if (hasMultiStorefront) {
    // Catalog Storefront & Marquee V2
    const { marquee, storefront } = getMarqueeAndStorefrontCollection({
      mediaCollection: media,
      mediaDictionary,
      storefrontId,
      summary,
    });
    return {
      genres,
      languages,
      marquee,
      media,
      storefront,
    };
  } else {
    // Catalog Storefront & Marquee V1
    const { marquee, storefront } = await getStoreFrontAndMarquee({
      flightInfo,
      locale,
      mediaDictionary,
      status,
    });
    return {
      genres,
      languages,
      marquee,
      media,
      storefront,
    };
  }
};

/**
 * media, summary for IFE movies and tv shows
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getMediaSummary = ({ locale = "en-US", status }) => {
  return Promise.all([
    getCatalogMedia({ locale, status }).catch((error) => {
      return { ...errorState, error: error };
    }),
    getCatalogSummary({ locale, status }).catch((error) => {
      return { ...errorState, error: error };
    }),
  ])
    .then((values) => {
      if (values?.[0]?.isError) {
        return new Error(values[0]?.error);
      } else if (values?.[1]?.isError) {
        return new Error(values?.[1]?.error);
      }
      return values;
    })
    .then((response) => {
      return transformMediaSummary(response);
    });
};

/**
 * Fetch storefront and marquee info - Catalog V1
 * flightInfo is only used for abpLogger
 * @param  {Object} options.flightInfo
 * @param  {String} options.locale
 * @param  {Object} options.mediaDictionary
 * @param  {Object} options.status,
 * @return {Promise}
 */
export const getStoreFrontAndMarquee = ({
  flightInfo = {},
  locale = "en-US",
  mediaDictionary = {},
  status,
}) => {
  return Promise.all([
    getCatalogStorefront({ locale, status }).catch((error) => {
      return { ...errorState, error: error };
    }),
    getCatalogMarquee({ locale, status }).catch((error) => {
      return { ...errorState, error: error };
    }),
  ])
    .then((values) => {
      if (values?.[0]?.isError && values?.[1]?.isError) {
        abpLogger({
          message: `IFE Default - Storefront and Marquee failed, Storefront msg - ${values?.[0].error.message}, Marquee msg - ${values?.[1].error.message}.`,
          flightInfo,
        });
        trackCustomEventOnDynatrace(
          "IFE Default - Marquee and Storefront Failure"
        );
        return new Error(values[0]?.error);
      }
      return values;
    })
    .then((response) => {
      return transformCatalog({ mediaDictionary, response });
    });
};

/**
 * transforms storefront and marquee to readable format.
 * @param  {Object} options.mediaDictionary
 * @param  {Array}  options.response
 * @returns {Object} catalog
 */
export const transformCatalog = ({ mediaDictionary = {}, response = [] }) => {
  const storefront = response[0].data;
  const marquee = response[1].data;

  // MARQUEE COLLECTION

  let marqueeCollection = [];

  if (
    marquee?.merchandisedOrder &&
    (marquee?.mediaRefList || marquee?.collectionRefList)
  ) {
    marqueeCollection = marquee?.merchandisedOrder?.map((id) => {
      return mediaDictionary[id];
    });
  }

  // STOREFRONT COLLECTION

  let storefrontCollections = storefront?.collectionRefList?.map(
    (collection) => {
      return {
        id: collection?.id,
        media: collection?.merchandisedOrder
          .filter((id) => mediaDictionary[id])
          .map((id) => mediaDictionary[id]),
        title: collection?.title,
      };
    }
  );

  return {
    marquee: marqueeCollection,
    storefront: storefrontCollections,
  };
};

/**
 * Retrieve storefront and marquee from summary - Catalog V2
 * @param   {Array}   options.mediaCollection
 * @param   {Object}  options.mediaDictionary
 * @param   {String}  options.storefrontId
 * @param   {Array}   options.summary
 * @returns {Object} marqueeAndStorefrontCollection
 */
export const getMarqueeAndStorefrontCollection = ({
  mediaCollection = [],
  mediaDictionary = {},
  storefrontId = "",
  summary = [],
}) => {
  // MARQUEE ID
  let marqueeId;
  let storefrontMerchandisedOrder = [];
  let storefrontObject = summary.find((collection) => {
    return collection.id.toLowerCase() === storefrontId.toLowerCase();
  });

  if (storefrontObject?.merchandisedOrder?.length) {
    storefrontMerchandisedOrder = storefrontObject.merchandisedOrder;
    marqueeId = storefrontObject.merchandisedOrder[0];
  }

  // MARQUEE COLLECTION

  let marqueeCollection = [];
  let marqueeObject = summary.find((collection) => {
    return collection.id.toLowerCase() === marqueeId.toLowerCase();
  });

  if (marqueeObject?.merchandisedOrder?.length) {
    marqueeCollection = manipulateMediaCollection({
      mediaCollection,
      mediaDictionary,
      merchandisedOrder: marqueeObject.merchandisedOrder,
    });
  }

  // STOREFRONT COLLECTION
  let storefrontCollection = [];

  if (!marqueeCollection.length) {
    let secondStorefrontObject = summary.find((collection) => {
      return collection.id.toLowerCase() === storefrontMerchandisedOrder[1];
    });

    if (secondStorefrontObject) {
      storefrontCollection = [
        {
          id: secondStorefrontObject.id,
          media: manipulateMediaCollection({
            mediaCollection,
            mediaDictionary,
            merchandisedOrder: secondStorefrontObject.merchandisedOrder,
          }),
          title: secondStorefrontObject.title,
        },
      ];
    }
  }

  return {
    marquee: marqueeCollection,
    storefront: storefrontCollection,
  };
};

/**
 * transforms summary and media to readable format.
 * @param   {Array} summary & media
 * @returns {Object} media
 */
export const transformMediaSummary = (response) => {
  const media = response[0].data;
  const summary = response[1].data;

  // CREATE MEDIA DICTIONARY

  let mediaDictionary = {};

  // CREATE MEDIA LIST

  let mediaList = [];

  // LANGUAGE FILTER OPTIONS

  let audioLanguages = [];
  let closedCaptionLanguages = [];
  let subTitleLanguages = [];

  // GENRE FILTER OPTIONS

  let genreOptions = [];

  // PROCESS MEDIA LIST

  /* eslint-disable complexity */
  media.mediaList.forEach((mediaItem) => {
    // FORMAT MEDIA

    mediaDictionary[mediaItem.id] = {
      boxArtImage: mediaItem?.boxArtImage,
      duration: mediaItem?.duration,
      id: mediaItem?.id,
      marquee: mediaItem?.marquee,
      mpaaRating: mediaItem?.mpaaRating ? mediaItem?.mpaaRating?.rating : null,
      title: mediaItem?.title,
      genres: mediaItem?.taxonomyRefs?.map((taxonomyRef) => {
        return taxonomyRef?.taxonomyRef;
      }),
      languages: [],
      synopsis: mediaItem?.synopsis,
      video: mediaItem?.videos[0].src,
      previewStillImage: mediaItem?.previewStillImage,
      productCode: mediaItem?.productCode?.productCode,
      physicalMediaId: mediaItem?.physicalMediaId,
      stillImage: mediaItem?.stillImage,
      type: mediaItem?.type,
      seriesId: mediaItem?.seriesId,
    };
    /* eslint-enable complexity */

    mediaList.push(mediaDictionary[mediaItem.id]);

    genreOptions = genreOptions.concat(mediaDictionary[mediaItem.id]?.genres);

    // LANGUAGE FILTER OPTIONS AND MEDIA LANGUAGES

    if (
      mediaItem?.videos[0]?.audioLanguages &&
      mediaItem?.videos[0]?.audioLanguages?.length > 0
    ) {
      let audioLanguagesArray = mediaItem?.videos[0]?.audioLanguages?.map(
        (language) => {
          return `${language} Audio`;
        }
      );

      mediaDictionary[mediaItem.id].languages =
        mediaDictionary[mediaItem?.id].languages?.concat(audioLanguagesArray);

      audioLanguages = audioLanguages?.concat(audioLanguagesArray);
    }

    if (
      mediaItem?.videos[0]?.closedCaptionLanguages &&
      mediaItem?.videos[0]?.closedCaptionLanguages?.length > 0
    ) {
      let closedCaptionLanguagesArray =
        mediaItem?.videos[0]?.closedCaptionLanguages?.map((language) => {
          return `${language} CC`;
        });

      mediaDictionary[mediaItem.id].languages = mediaDictionary[
        mediaItem?.id
      ].languages?.concat(closedCaptionLanguagesArray);

      closedCaptionLanguages = closedCaptionLanguages?.concat(
        closedCaptionLanguagesArray
      );
    }

    if (
      mediaItem?.videos[0]?.subTitleLanguages &&
      mediaItem?.videos[0]?.subTitleLanguages?.length > 0
    ) {
      let subTitleLanguagesArray = mediaItem?.videos[0]?.subTitleLanguages.map(
        (language) => {
          return `${language} Subtitles`;
        }
      );

      mediaDictionary[mediaItem?.id].languages = mediaDictionary[
        mediaItem?.id
      ].languages?.concat(subTitleLanguagesArray);

      subTitleLanguages = subTitleLanguages.concat(subTitleLanguagesArray);
    }
  });

  // LANGUAGE OPTIONS

  let languageOptions = uniq(audioLanguages).concat(
    uniq(closedCaptionLanguages),
    uniq(subTitleLanguages)
  );

  // GENRE OPTIONS

  genreOptions = uniq(genreOptions);

  // SUMMARY COLLECTION

  let hasMultiStorefront = false;
  let storefrontId = "storefront";
  let rootObject = summary?.find((collection) => {
    return collection?.type?.toLowerCase() === "root";
  });
  if (rootObject) {
    hasMultiStorefront = true;
    storefrontId = rootObject.merchandisedOrder[0];
  }

  return {
    genres: genreOptions,
    hasMultiStorefront,
    languages: languageOptions,
    media: mediaList,
    mediaDictionary,
    storefrontId,
    summary,
  };
};

/**
 * Find media based on merchandiseOrder.
 * @param {Array}   options.mediaCollection
 * @param {Object}  options.mediaDictionary
 * @param {Array}   options.merchandisedOrder
 * @returns {Array} media
 */
export const manipulateMediaCollection = ({
  mediaCollection = [],
  mediaDictionary = {},
  merchandisedOrder = [],
}) => {
  let mediaId = mediaCollection?.find((collection) => {
    return (
      (collection?.type?.toLowerCase() === "movie" &&
        collection?.id?.toLowerCase() ===
          merchandisedOrder[0]?.toLowerCase()) ||
      (collection?.type?.toLowerCase() === "tv" &&
        collection?.seriesId?.toLowerCase() ===
          merchandisedOrder[0]?.toLowerCase())
    );
  });
  return mediaId ? [mediaDictionary[mediaId.id]] : [];
};
