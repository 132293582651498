import { call, put, takeLatest } from "redux-saga/effects";
import { getProducts, logSplashLoadTime } from "portal-services";

/**
 * @param {Object} action
 */
export function* productsSaga(action) {
  const meta = action.meta;
  const {
    airlineCode,
    connectivityType,
    currency,
    config,
    departureCodeIcao,
    destinationCodeIcao,
    flightNumber,
    locale,
    region,
    status,
    tailNumber,
    user,
    uxdId,
  } = action.payload;

  try {
    const response = yield call(getProducts, {
      airlineCode,
      connectivityType,
      currency,
      config,
      departureCodeIcao,
      destinationCodeIcao,
      flightNumber,
      locale,
      region,
      status,
      tailNumber,
      uxdId,
    });

    yield put({
      type: "GET_PRODUCTS_SUCCESS",
      payload: response.data,
      meta: meta,
    });

    yield logSplashLoadTime({
      airlineCode,
      connectivityType,
      departureCodeIcao,
      destinationCodeIcao,
      flightNumber,
      tailNumber,
      user,
    });
  } catch (e) {
    yield put({ type: "GET_PRODUCTS_FAIL", payload: e, meta });
  }
}

/**
 * get products used for testing purposes
 */
export function* getProductsWatcher() {
  yield takeLatest("GET_PRODUCTS", productsSaga);
}
