/**
 * get in flight redux action
 * @param  {Object} options.config   airline config
 * @param  {Object} options.currency   airline default currency
 * @param  {Object} options.serviceStatus  getServiceStatus response
 * @param  {String} options.locale     airline default locale
 * @return {Object}
 */
export function getInFlight({ config, currency, flightInfo, locale, status }) {
  return {
    type: "GET_IN_FLIGHT",
    payload: { config, currency, flightInfo, locale, status },
    meta: {
      thunk: true,
    },
  };
}
