import {
  axios,
  getAirborneABPEdgeBaseUrl,
  getAirborneABPEdgeCacheBaseUrl,
  getBuyWifiOnboardBaseUrl,
  getWifiBaseUrl,
} from "portal-services";
import {
  getCurrentProductCode,
  getHeaders,
  isMobileBrowser,
  isPaymentApp,
  isPaymentGroundApp,
} from "portal-utilities";

import { transformProducts } from "./utilities/paymentHelpers";
import { transformProductGround } from "./utilities/paymentGroundHelpers";

/**
 * gets all pass products available for sale from ground & specific for Payment application
 * @param  {Object} options.config
 * @param  {Object} options.params
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getApiDecoratorProducts = ({
  currency = "USD",
  locale = "en-US",
  status,
  uxdId,
}) => {
  return axios({
    method: "get",
    url: `/v3/products/${uxdId}`,
    baseURL: getAirborneABPEdgeBaseUrl(status),
    headers: getHeaders(),
    params: {
      currency,
      locale: locale.replace("-", "_"),
    },
    transformResponse: [
      function (data) {
        return transformProducts({ data });
      },
    ],
  });
};

/**
 * gets all pass products available for sale
 * @param  {String} options.airlineCode
 * @param  {String} options.connectivityType
 * @param  {String} options.currency
 * @param  {Object} options.config
 * @param  {String} options.departureCodeIcao
 * @param  {String} options.destinationCodeIcao
 * @param  {String} options.flightNumber
 * @param  {String} options.locale
 * @param  {String} options.region
 * @param  {Object} options.status
 * @param  {String} options.tailNumber
 * @return {Promise}
 */
export const getProducts = ({
  airlineCode,
  connectivityType,
  currency = "USD",
  config,
  departureCodeIcao,
  destinationCodeIcao,
  flightNumber,
  locale = "en-US",
  region,
  status,
  tailNumber,
  uxdId,
}) => {
  if (isPaymentGroundApp()) {
    const baseURL = getBuyWifiOnboardBaseUrl();
    const productCode = getCurrentProductCode(location);

    return axios({
      method: "get",
      url: `/v1/product/code/${productCode}`,
      baseURL: baseURL,
      transformResponse: [
        function (data) {
          return transformProductGround({ data });
        },
      ],
    }).catch((error) => {
      throw error;
    });
  } else {
    const deviceType = isMobileBrowser() ? "mobile" : "laptop";
    const baseURL = isPaymentApp()
      ? `${getWifiBaseUrl()}/abp/edge`
      : getAirborneABPEdgeCacheBaseUrl(status);

    return axios({
      method: "get",
      url: "/product",
      baseURL: baseURL,
      headers: getHeaders(),
      params: {
        airline_code_icao: airlineCode?.toUpperCase(),
        connectivity_type: connectivityType,
        currency,
        departure_airport_icao: departureCodeIcao,
        destination_airport_icao: destinationCodeIcao,
        device_type: deviceType,
        flight_number: flightNumber,
        locale: locale.replace("-", "_"),
        region,
        tail_number: tailNumber,
        version: "3.0",
      },
      transformResponse: [
        function (data) {
          return transformProducts({ config, data });
        },
      ],
    }).catch((error) => {
      if (isPaymentApp() && uxdId && error?.response?.status !== 200) {
        return getApiDecoratorProducts({ currency, locale, status, uxdId });
      } else {
        // log error
        throw error;
      }
    });
  }
};
