import { CLICK_EVENT, LOG_LEVELS, PAGE_EVENT } from "../../constants";
import AdapterInterface from "../AdapterInterface";
import { getGlobalWindow, getLogger } from "../../helpers";

const Logger = getLogger();

const RULE_NAMES = {
  [PAGE_EVENT]: "pageViewed",
  [CLICK_EVENT]: "userAction",
};

const NO_CONSOLE = {
  development: true,
  production: true,
};

class LegacyAnalyticsAdapter extends AdapterInterface {
  static isLoggerReady = false;

  constructor() {
    super();
    this.bootstrap();
  }

  bootstrap() {
    Logger.getInstance(
      {
        app: process.env.APP,
        level: LOG_LEVELS.INFO,
      },
      { noConsole: NO_CONSOLE[process.env.NODE_ENV] }
    );
    LegacyAnalyticsAdapter.isLoggerReady = true;
  }

  setup({ data: { flightInfo = {}, analyticsInfo = {} } } = {}) {
    if (!LegacyAnalyticsAdapter.isLoggerReady) return false;

    Logger.setFlightData(flightInfo);
    getGlobalWindow().analyticsDataIFE = analyticsInfo;

    return true;
  }

  track({ type, data } = {}) {
    const { message, level, pageName, clickName } = data;

    const logData = {
      type: RULE_NAMES[type] || type,
      message: message || pageName || clickName,
      level,
      data,
    };

    try {
      Logger.log(logData);
    } catch {
      console.warn(`LoggerException: Unable to track ${logData.message}.`);
    }
  }

  static apiInterceptors(apiClient) {
    // Status tray calls go to the ground;
    // hence, they should only be logged every 5 mins for performance.
    let shouldLogStatusTrayCall = true;
    setInterval(() => {
      shouldLogStatusTrayCall = true;
    }, 5 * 60000);

    function handleInterceptorResponse(response) {
      logResponse(response);
      return response;
    }

    function handleInterceptorError(error) {
      logError(error);
      return Promise.reject(error);
    }

    function parseData(data) {
      if (!data) return data;

      try {
        return JSON.parse(data);
      } catch (error) {
        console.warn(
          "LegacyAnalyticsException: API Interceptor JSON.parse error. ",
          error
        );
        return data;
      }
    }

    /**
     * Logs the Axios API response
     * @param {object} response - Axios interceptor response object
     */

    function logResponse(response) {
      if (!LegacyAnalyticsAdapter.isLoggerReady) return;

      const logInfo = {
        type: "ajaxFetch",
        message: `api call | ${response?.config?.url}`,
        level: "info",
        data: { data: parseData(response?.request?.response) },
      };
      if (response?.config?.url?.includes("statusTray")) {
        if (shouldLogStatusTrayCall) {
          shouldLogStatusTrayCall = false;
          Logger.log(logInfo);
        }
      } else {
        Logger.log(logInfo);
      }
    }

    /**
     * Logs the Axios API error
     * @param {object} error - Axios interceptor error object
     */
    function logError(error) {
      if (!LegacyAnalyticsAdapter.isLoggerReady) return;

      const type = "ajaxFetch";
      const level = "error";
      if (error.isAxiosError) {
        const res = error?.response;
        const url = res?.config?.url;
        const method = res?.config?.method;
        const requestBody = res?.config?.data;
        const requestHeaders = res?.config?.headers;
        const responseBody = res?.request?.response;

        // It is an axios API error e.g. 404, 500 e.t.c.
        Logger.log({
          type,
          message: `api call error | ${url}`,
          level,
          data: { url, method, requestBody, requestHeaders, responseBody },
        });
      } else {
        // JS error e.g. accessing undefined within a transform function
        Logger.log({
          type,
          message: "JS error",
          level,
          data: { message: error?.message },
        });
      }
    }

    apiClient.interceptors.response.use(
      handleInterceptorResponse,
      handleInterceptorError
    );
  }
}

export default LegacyAnalyticsAdapter;
