import { Cookies } from "portal-utilities";

/**
 * Returns the host's domain name with a leading dot.
 * @returns {string} domainName
 */
export function getCookieDomain() {
  const domain = window.location.hostname.split(".").slice(-2).join(".");
  return process.env.NODE_ENV === "production" ? `.${domain}` : null;
}

/**
 * Retrieves a cookie within the host's domain.
 * @param {string} cookieKey
 * @returns cookie value
 */
export function getCookie(cookieKey) {
  return Cookies.get(cookieKey);
}

/**
 * Retrieves a cookie without specifying domain. Domain not needed here.
 * @param {string} cookieKey
 * @returns cookie value
 */
export function getOauthCookie(cookieKey) {
  return Cookies.get(cookieKey);
}

/**
 * Sets a cookie within the host's domain.
 * @param {string} cookieKey
 * @param {string} cookieValue
 */
export function setCookie(cookieKey, cookieValue) {
  Cookies.set(cookieKey, cookieValue, {
    domain: getCookieDomain(),
  });
}

/**
 * Sets a cookie with Expiry within the host's domain.
 * @param {string} cookieKey
 * @param {string} cookieValue
 * @param {number} expiry
 */
export function setCookieWithExpiry(cookieKey, cookieValue, expiry) {
  Cookies.set(cookieKey, cookieValue, {
    domain: getCookieDomain(),
    expires: expiry,
  });
}

/**
 * Sets a cookie without specifying domain. Domain not needed here.
 * @param {string} cookieKey
 * @param {string} cookieValue
 */
export function setOauthCookie(cookieKey, cookieValue) {
  Cookies.set(cookieKey, cookieValue);
}

/**
 * Removes a cookie within the host's domain.
 * @param {string} cookieKey
 */
export function removeCookie(cookieKey) {
  return Cookies.remove(cookieKey, { domain: getCookieDomain() });
}

/**
 * Removes specified cookies within the host's domain.
 * @param {array} cookieKeys array of cookies to be removed e.g. ["abc", "xyz"] would remove "abc" and "xyz" cookies.
 */
export function removeCookies(cookieKeys) {
  cookieKeys.forEach((cookieKey) => removeCookie(cookieKey));
}
