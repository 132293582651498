import { call, put, takeLatest } from "redux-saga/effects";
import { getWeather } from "portal-services";

/**
 * @param {Object} action
 */
export function* weatherSaga(action) {
  const meta = action.meta;
  const { airportCode, airportCodeIcao, isCelsius, locale, status } =
    action.payload;

  try {
    if (
      (status.edgeCacheStatus && airportCodeIcao) ||
      (!status.edgeCacheStatus && airportCode)
    ) {
      let response;

      try {
        response = yield call(getWeather, {
          airportCode,
          airportCodeIcao,
          isCelsius,
          locale,
          status,
        });
      } catch {
        response = yield call(getWeather, {
          airportCode,
          airportCodeIcao,
          isCelsius,
          locale: undefined,
          status,
        });
      }

      yield put({
        type: "GET_WEATHER_SUCCESS",
        payload: response.data,
        meta: meta,
      });
    } else {
      yield put({
        type: "GET_WEATHER_FAIL",
        payload: `Missing airportCode or airportCodeIcao`,
        meta,
      });
    }
  } catch (e) {
    yield put({ type: "GET_WEATHER_FAIL", payload: e, meta });
  }
}

/**
 get flight info watcher used for testing purposes
 */
export function* weatherWatcher() {
  yield takeLatest("GET_WEATHER", weatherSaga);
}
