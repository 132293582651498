/**
 * oauthUser setter and getter
 * @property {object}
 *    @keys {{airline_code, airlineCode, client_id, flight_number, locale, response_type,
 *          redirect_uri, scope, state, tail_number, tracking_id, ui_locales }} oauthQS description
 * @property {function} - setter
 *    @params {object}
 * @property {function} - getter
 *    @returns {object}
 */
export const oauthUser = {
  oauthUser: {},

  setOauthUser: function (oauthQS) {
    this.oauthUser = oauthQS;
  },

  getOauthUser: function () {
    return this.oauthUser;
  },
};
