/**
 * @const {String}
 */
export const COOKIE_ENV = "env";

/**
 * @const {String}
 */
export const COOKIE_FLIGHT_IDENTIFIER = "FLIGHT_IDENTIFIER";

/**
 * @const {String}
 */
export const COOKIE_FORGOT_PASSWORD_OAUTH_TOKEN = "FORGOT_PASSWORD_OAUTH_TOKEN";

/**
 * @const {String}
 */
export const COOKIE_OAUTH_ENV = "OAUTH_ENV";

/**
 * @const {String}
 */
export const COOKIE_USERNAME = "GG_PORTAL_USERNAME";

/**
 * @const {String}
 */
export const COOKIE_JSESSIONID = "JSESSIONID";

/**
 * @const {String}
 */
export const COOKIE_UXDID = "uxdId";

/**
 * @const {String}
 */
export const COOKIE_JWT_TOKEN = "JWT_TOKEN";

/**
 * @const {String}
 */
export const COOKIE_COOKIES_NOTIFICATION = "COOKIES_NOTIFICATION";

/**
 * @const {String}
 */
export const COOKIE_LOCALE = "PREFERRED_LANGUAGE";

/**
 * @const {String}
 */
export const COOKIE_CURRENCY = "PREFERRED_CURRENCY";

/**
 * @const {String}
 */
export const COOKIE_FLIGHT_NUMBER = "FLIGHT_NUMBER";

/**
 * @const {String}
 */
export const COOKIE_FICOOKIE = "FICOOKIE";

/**
 * @const {String}
 */
export const COOKIE_HMA = "hma";

/**
 * @const {String}
 */
export const COOKIE_GG_SIGNIN = "GG_SIGNIN";

/**
 * @const {String}
 */
export const COOKIE_LOGGER_DATA = "LOGGER_DATA";

/**
 * @const {String}
 */
export const COOKIE_IS_CELSIUS = "IS_CELSIUS";

/**
 * @const {String}
 */
export const COOKIE_IS_DARK_MODE = "IS_DARK_MODE";

/**
 * @const {String}
 */
export const COOKIE_DYNATRACE_THEME_USED = "DYNATRACE_THEME_USED";

/**
 * @const {String}
 */
export const COOKIE_AIRPORT_LATLON_INVALID = "AIRPORT_LATLON_INVALID";

/**
 * @const {String}
 */
export const COOKIE_FLIGHT_TRACKER_STORED = "FLIGHT_TRACKER_STORED";

/**
 * @const {String}
 */
export const COOKIE_PRIVACY_POLICY = "POLICY_PAGE";
