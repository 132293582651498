import isUndefined from "lodash/isUndefined";
import { COOKIE_IS_CELSIUS, getCookie } from "portal-utilities";
/**
 * checks for isCelsiusDefault, else checks cookie for is celsius and parses to boolean
 * @param  {Boolean} isCelsiusDefault
 * @return {Boolean}
 */
export const getIsCelsius = (isCelsiusDefault = false) => {
  const isCelsiusCookie = getCookie(COOKIE_IS_CELSIUS);
  if (isUndefined(isCelsiusCookie)) {
    return isCelsiusDefault;
  } else {
    return isCelsiusCookie === "true";
  }
};
