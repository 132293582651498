import { axios } from "portal-services";
import { getHeaders } from "portal-utilities";

/**
 * post user session state, updates user session state from new to not_authenticated
 * @return {Promise}
 */
export const postSessionState = () => {
  return axios({
    method: "post",
    url: "/abp/sessionState",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      ...getHeaders(),
    },
    data: {},
  });
};
