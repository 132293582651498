import { axios } from "portal-services";
import { HAS_DYNATRACE_API_TRACKING } from "portal-utilities";

/**
 * gets config from public directory(non OBP airlines) or pimcore(local development for CMS enabled airlines) or from abp(for OBP dev/stage/production)
 * @return {Promise}
 */
export const getConfig = ({ airlineCode = "" }) => {
  if (process.env.HAS_ABP_CONFIG === "true") {
    return axios({
      method: "get",
      url: "/ui-config/shared/config.json",
      headers: {
        "airline-code": airlineCode,
      },
      params: {
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformConfig(data);
        },
      ],
    });
  } else if (process.env.THEME_ID) {
    return axios({
      method: "get",
      url: "/cmsthemes",
      params: {
        airlineCode: process.env.THEME,
        filePath: "shared",
        fileName: "config.json",
        state: "obpdevelopment",
        theme_id: process.env.THEME_ID,
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformConfig(data);
        },
      ],
    });
  } else {
    return axios({
      method: "get",
      url: "/configuration/config.json",
      baseURL: process.env.PUBLIC_URL,
      params: {
        timestamp: new Date().getTime(),
      },
      transformResponse: [
        function (data) {
          return transformConfig(data);
        },
      ],
    });
  }
};

/* eslint complexity: ["error", 11] */
/**
 * tranforms config to readable format
 * @param  {Object} data
 * @return {Object}
 */

/* eslint-disable complexity */
export const transformConfig = (data) => {
  let response = typeof data === "string" ? JSON.parse(data) : data;

  if (process.env.THEME_ID) {
    response = typeof response === "string" ? JSON.parse(response) : response;
  }

  const validateDynatraceApiTracking = (isEnabled) => {
    const hasDynatraceApiTracking = !!isEnabled ?? false;

    localStorage.setItem(
      HAS_DYNATRACE_API_TRACKING,
      `${hasDynatraceApiTracking}`
    );

    return hasDynatraceApiTracking;
  };

  const validateLocales = (locales, defaultLocale) => {
    const localeList = locales || [
      "en-US",
      "de-DE",
      "es-MX",
      "ja-JP",
      "fr-CA",
      "pt-BR",
      "ko-KR",
      "zh-CN",
      "zh-HK",
      "ar-QA",
    ];

    if (!defaultLocale) return localeList;

    // when defaultLocale is defined, make it always the first one in the list
    return [
      defaultLocale,
      ...localeList?.filter((locale) => locale !== defaultLocale),
    ];
  };

  return {
    advertisements: response?.advertisements,
    analytics: response?.analytics,
    apiMock: response?.apiMock || {},
    baseName: response?.baseName || "/app",
    creditCardPayments: response?.creditCardPayments || [
      "visa",
      "master",
      "elo",
      "discov",
      "amex",
      "jcb",
    ],
    currencies: response?.currencies || ["USD"],
    defaultCountryCode: response?.defaultCountryCode || "US",
    defaultLocale: response?.defaultLocale || "",
    domainDefault: response?.domainDefault,
    domainConfigs: response?.domainConfigs ?? {},
    hasAcceptDataCheckbox: response?.hasAcceptDataCheckbox ?? false,
    hasActivationRetry: response?.hasActivationRetry ?? true,
    hasAirlineLogoAndLinksOnFooter:
      response?.hasAirlineLogoAndLinksOnFooter ?? true,
    hasDarkMode: response?.hasDarkMode ?? false,
    hasDynatraceApiTracking: validateDynatraceApiTracking(
      response?.hasDynatraceApiTracking
    ),
    hasEmailOnFreeMessaging: response?.hasEmailOnFreeMessaging ?? true,
    hasGogoAccounts: response?.hasGogoAccounts ?? true,
    hasGogoBrandingOnFooter: response?.hasGogoBrandingOnFooter ?? true,
    hasGogoLogo: response?.hasGogoLogo ?? false,
    hasGogoLogoAndLinksOnFooter: response?.hasGogoLogoAndLinksOnFooter ?? true,
    hasGuestCheckout: response?.hasGuestCheckout ?? true,
    hasHelpOption: response?.hasHelpOption ?? true,
    hasLanguageAutoSelect: response?.hasLanguageAutoSelect ?? false,
    hasLanguageModal: response?.hasLanguageModal ?? false,
    hasLoyaltyIntegration: response?.hasLoyaltyIntegration ?? false,
    hasLoyaltyPoints: response?.hasLoyaltyPoints ?? false,
    hasProductExpandCollapse: response?.hasProductExpandCollapse ?? false,
    hasQualtricsSurvey: response?.hasQualtricsSurvey ?? true,
    hasStackTraceErrorLogging: response?.hasStackTraceErrorLogging ?? false,
    hasSwitchDevice: response?.hasSwitchDevice ?? false,
    hasSwitchSession: response?.hasSwitchSession ?? false,
    hasThirdPartyIFE: response?.hasThirdPartyIFE ?? false,
    isCelsiusDefault: response?.isCelsiusDefault ?? true,
    isEntitlementProductsFromThemes:
      response?.isEntitlementProductsFromThemes ?? false,
    isReduceSplashLoadTime: response?.isReduceSplashLoadTime ?? false,
    isLegacyIFCRedirect: response?.isLegacyIFCRedirect ?? false,
    entitlementPasses: response?.entitlementPass ?? [],
    key: response?.key,
    locales: validateLocales(response?.locales, response?.defaultLocale),
    name: response?.name,
    partner: response?.partner,
    partnerName: response?.partnerName || "GOGO",
    requiresFig2: response?.requiresFig2 ?? false,
    targeting: response?.targeting ?? {},
    theme: response?.theme ?? true,
    thirdPartyPayments: response?.thirdPartyPayments || [],
    useUnbrandedLogo: response?.useUnbrandedLogo ?? false,
  };
};
