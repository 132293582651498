import queryString from "query-string";
import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import {
  COOKIE_JWT_TOKEN,
  getCookie,
  ROUTE_PAYMENT,
  setCookie,
  URLS,
} from "portal-utilities";

/**
 * gets redirectUri for loyalty credentials verification
 * @param  {Object} options.config
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getLoyaltyUrl = ({ config, uxdId, status }) => {
  return axios({
    method: "post",
    url: "/v1/loyalty/authorize-url",
    baseURL: getAirborneABPEdgeBaseUrl(status),
    data: {
      callback_url: `https://${URLS?.airborneSecure}${config?.baseName}${ROUTE_PAYMENT}?airlineCode=${config?.key}`,
      uxd_id: uxdId,
    },
    transformResponse: [
      function (data) {
        return transformLoyaltyUrl(data, config);
      },
    ],
  });
};

/**
 * tranforms loyalty redirect url to readable format
 * @param  {Object} data
 * @param  {Object} config
 * @return {Object}
 */
export const transformLoyaltyUrl = (data, config) => {
  const response = JSON.parse(data);

  let loyaltyUrl = queryString.parseUrl(response.uri);

  if (process.env.NODE_ENV === "development") {
    loyaltyUrl.query.returnUrl = `${window.location.origin}${config?.baseName}${ROUTE_PAYMENT}`;
  }

  loyaltyUrl = queryString.stringifyUrl(loyaltyUrl);

  return {
    loyaltyUrl,
  };
};

/**
 * authenticates loyalty user and returns profile
 * @param  {String} options.airlineCode
 * @param  {String} options.authCode
 * @param  {String} options.locale
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const authenticateLoyaltyUser = ({
  airlineCode,
  authCode,
  locale = "en-US",
  partnerName,
  uxdId,
  status,
  config
}) => {
  return axios({
    method: "post",
    url: "/v1/authenticate",
    baseURL: getAirborneABPEdgeBaseUrl(status),
    transformResponse: [
      function (data, headers) {
        return transformLoyaltyProfile(data, headers);
      },
    ],
    data: {
      airline_code: airlineCode,
      partner_oauth: {
        auth_code: authCode,
        locale: locale.replace("-", "_"),
        partner_name: partnerName,
      redirect_uri:`https://${URLS?.airborneSecure}${config?.baseName}${ROUTE_PAYMENT}?airlineCode=${config?.key}`
      },
      uxd_id: uxdId,
    },
  });
};

/**
 * tranforms loyalty profile to readable format
 * @param  {Object} data
 * @param  {Object} headers
 * @param  {Object} authCode
 * @return {Object}
 */
const transformLoyaltyProfile = (data, headers) => {
  const jwtToken = headers?.authorization;

  if (jwtToken) {
    setCookie(COOKIE_JWT_TOKEN, jwtToken);
  }

  const response = JSON.parse(data);
  let eligibleBenefits = [];

  if (response?.partner_oauth_response?.eligible_benefits) {
    eligibleBenefits = response?.partner_oauth_response?.eligible_benefits.map(
      (benefit) => {
        return {
          code: benefit?.code,
          description: benefit?.description,
          rewardPoints: benefit?.reward_points,
          redemptionExpirationDate: benefit?.redemption_expiry_date,
        };
      }
    );
  }

  return {
    emailAddress: response?.partner_oauth_response?.email_address,
    firstName: response?.partner_oauth_response?.first_name,
    lastName: response?.partner_oauth_response?.last_name,
    loyaltyProfile: {
      authenticated: true,
      accountId: parseInt(response?.partner_oauth_response?.account_id),
      eligibleBenefits,
      rewardsBalance:
        parseInt(response?.partner_oauth_response?.rewards_balance) || 0,
    },
  };
};

/**
 * processes order based on params
 * @param  {String} options.accountId
 * @param  {String} options.benefitCode
 * @param  {String} options.currency
 * @param  {String} options.locale
 * @param  {String} options.partnerName
 * @param  {String} options.salesChannel
 * @param  {String} options.uxdId
 * @param  {object} status
 * @return {Promise}
 */
export const postProcessBenefit = ({
  accountId,
  benefitCode,
  currency,
  locale = "en-US",
  partnerName,
  salesChannel,
  status,
  uxdId,
}) =>
  axios({
    method: "post",
    url: "/v1/process-benefit",
    headers: { Authorization: getCookie(COOKIE_JWT_TOKEN) },
    baseURL: getAirborneABPEdgeBaseUrl(status),
    data: {
      account_id: accountId,
      benefit_code: benefitCode,
      currency,
      locale: locale.replace("-", "_"),
      partner_name: partnerName,
      sales_channel: salesChannel,
      uxd_id: uxdId,
    },
  });
