import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Error from "@src/app/pages/Error";
import {
  AnalyticsContext,
  APP_INFO,
  getGlobalWindow,
  LOG_LEVELS,
} from "portal-analytics";
import { Logger } from "portal-services";

class ErrorBoundary extends Component {
  static contextType = AnalyticsContext;

  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { config } = this.props;
    const hasStackTraceErrorLogging = config?.data?.hasStackTraceErrorLogging;
    const type = APP_INFO;
    const data = {
      type,
      message: `React JS Error | ${getGlobalWindow().location.href}`,
      level: LOG_LEVELS.ERROR,
      error: hasStackTraceErrorLogging
        ? { message: error?.message, stack: error?.stack }
        : "error message and stack disabled",
      info: hasStackTraceErrorLogging
        ? { errorInfo }
        : "component error disabled",
    };

    this.context.analytics.logEvent(type, data);
    Logger.track("pageLoad", {
      message: data,
    });
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
