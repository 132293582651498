import { initialState } from "../index";

/**
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
export const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_THEME":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: true,
      };
    case "GET_THEME_SUCCESS":
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        isLoading: false,
      };
    case "GET_THEME_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
