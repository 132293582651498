import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import { getHeaders } from "portal-utilities";

/**
 * gets user pin based on uxdId
 * @param {String} uxdId
 * @param {string} status
 * @param {string} jwtToken
 * @return {Promise}
 */

export const getActiveDevicePin = ({ uxdId, status, jwtToken }) => {
  return axios({
    url: `/v1/switch-device/${uxdId}`,
    baseURL: getAirborneABPEdgeBaseUrl(status),
    headers: {
      Authorization: jwtToken,
      ...getHeaders(),
    },
    transformResponse: [
      function (data) {
        return transformActiveDevicePinResponse(data);
      },
    ],
  });
};

/**
 * tranforms switch user details response to readable format
 * @param {Object} data
 * @return {Object}
 */
export const transformActiveDevicePinResponse = (data) => {
  const response = JSON.parse(data);

  let output = {
    uxdId: response.tracking_id,
    emailAddress: response.email_address,
    pin: response.pin,
  };

  return output;
};

/**
 * swtich a active device session to non active device
 * @param {string} email
 * @param {string} pin
 * @param {object} status
 * @param {string} uxdId
 * @returns {Promise}
 */
export const switchDevice = ({ emailAddress, pin, status, uxdId }) => {
  return axios({
    method: "post",
    url: `/v1/switch-device/${uxdId}`,
    baseURL: getAirborneABPEdgeBaseUrl(status),
    headers: getHeaders(),
    data: {
      tracking_id: uxdId,
      email_address: emailAddress,
      pin,
    },
  });
};
