import moment from "moment";

/**
 * returns boolean for whether the portal app should load theme-target.json
 * @function isTargetRoute
 * @param  {string} currentRoute
 * @param  {array} targetRoutes
 * @return {Boolean}
 */
const isTargetRoute = ({ currentRoute, targetRoutes }) => {
  const hasTargetRoute =
    targetRoutes?.filter((index) => index === currentRoute).length > 0;
  return hasTargetRoute;
};

/**
 * returns boolean for whether the portal app should load theme-target.json
 * @function isTargetDate
 * @param  {string} currentDate
 * @param  {array} targetDates
 * @return {Boolean}
 */
const isTargetDate = ({ currentDate, timeZoneOffset, targetDates }) => {
  if (currentDate && timeZoneOffset && targetDates) {
    const isBetween = targetDates?.map((index) =>
      moment(currentDate)
        .utcOffset(timeZoneOffset)
        .isBetween(index[0], index[1])
    );
    const hasTargetDate = isBetween?.includes(true);
    return hasTargetDate;
  }
  return false;
};

/**
 * returns boolean for whether the portal app should load theme-target.json
 * @function isTargetTail
 * @param  {string} currentTail
 * @param  {array} targetTails
 * @return {Boolean}
 */
const isTargetTail = ({ currentTail, targetTails }) => {
  const hasTargetTail =
    targetTails?.filter((index) => index === currentTail).length > 0;
  return hasTargetTail;
};

/**
 * returns boolean for whether the portal app should load theme-target.json
 * @function applyTargetTheme
 * @param  {object} config
 * @param  {object} flightInfo
 * @return {Boolean}
 */
export const applyTargetTheme = ({ config, flightInfo }) => {
  const targeting = config?.data?.targeting;
  const targetBy = targeting?.targetBy;
  const targetingActive = targeting?.active;

  if (targetBy && targetingActive) {
    const timeZoneOffset = flightInfo?.arrival?.timeZoneOffset;
    const currentDate = flightInfo?.utcTime; // "2022-08-28T14:35:02.439Z"
    const currentRoute = flightInfo?.route;
    const currentTail = flightInfo?.tailNumber;

    const targetDates = targeting?.targetDates;
    const targetRoutes = targeting?.targetRoutes;
    const targetTails = targeting?.targetTails;

    const targetByFactories = {
      dates: isTargetDate({ currentDate, timeZoneOffset, targetDates }),
      routes: isTargetRoute({ currentRoute, targetRoutes }),
      tails: isTargetTail({ currentTail, targetTails }),
    };
    return !!targetByFactories[targetBy];
  }
  return false;
};
