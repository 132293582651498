import { call, put, takeLatest } from "redux-saga/effects";
import { getIntl } from "portal-services";

/**
 * overrides dictionary with theme specific translations
 * @param  {Object} dictionary
 * @param  {String} locale
 */
const dictionaryOverride = (dictionary, locale) => {
  if (process.env.THEME) {
    let overrideDictionary = {};
    try {
      overrideDictionary = require(`@theme/dictionaries/${locale}`);
    } finally {
      dictionary.data = { ...dictionary.data, ...overrideDictionary };
    }
  }
};

/**
 * @param {Object} action
 */
export function* intlSaga(action) {
  const meta = action.meta;
  const { airlineCode, locale } = action.payload;

  let dictionary;

  try {
    dictionary = yield call(getIntl, { airlineCode, locale });
    dictionaryOverride(dictionary, action.payload.locale);
  } catch {
    dictionary = yield call(getIntl, { airlineCode, locale: "en-US" });
    dictionaryOverride(dictionary, "en-US");
  }

  yield put({
    type: "GET_INTL_SUCCESS",
    payload: { locale: action.payload.locale, messages: dictionary.data },
    meta: meta,
  });
}

/**
 * get intl watcher used for testing purposes
 */
export function* getIntlWatcher() {
  yield takeLatest("GET_INTL", intlSaga);
}
