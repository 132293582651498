import toUpper from "lodash/toUpper";
import {
  AIRLINE_CODES,
  CLICK_EVENT,
  NO_ANALYTICS_FOR_THESE_APPS,
  PAGE_EVENT,
} from "../constants";
import { LegacyAnalyticsAdapter, NoopAdapter } from "../adapters";
import {
  ClickEventDataModel,
  DefaultDataModel,
  LogEventDataModel,
  PageEventDataModel,
} from "../data_models";

let _instance;

class Analytics {
  constructor(analyticsAdapter) {
    this.state = {
      isSetupComplete: false,
    };
    this.analyticsAdapter = analyticsAdapter;
  }

  bootstrap(config = {}) {
    const result = this.analyticsAdapter.bootstrap(config);
    return result;
  }

  setup({ data }) {
    const isSetupComplete = this.analyticsAdapter.setup({
      data: new DefaultDataModel(data).toJSON(),
    });
    if (isSetupComplete) {
      this.setState({ isSetupComplete: true });
    }
  }

  track({ type, data }) {
    switch (type) {
      case PAGE_EVENT:
        return this._track(type, data, PageEventDataModel);
      case CLICK_EVENT:
        return this._track(type, data, ClickEventDataModel);
      default:
        return this._track(type, data, LogEventDataModel);
    }
  }

  trackPageEvent(data) {
    return this.track({ type: PAGE_EVENT, data });
  }

  trackClickEvent(data) {
    return this.track({ type: CLICK_EVENT, data });
  }

  logEvent(type, data) {
    return this.track({ type, data });
  }

  getState() {
    return this.state;
  }

  _track(type, data, EventDataModel) {
    return this.analyticsAdapter.track({
      type,
      data: new EventDataModel(data).toJSON(),
    });
  }

  setState(newState) {
    this.state = {
      ...this.state,
      ...newState,
    };
  }

  static getAdapter(airlineCode) {
    // for example, 'captive' app doesn't require analytics - so noop
    if (NO_ANALYTICS_FOR_THESE_APPS.includes(toUpper(process.env.APP))) {
      return NoopAdapter;
    }

    // NOTE: To enable analytics for an airline, it must be added here.
    switch (toUpper(airlineCode)) {
      case AIRLINE_CODES.UAL:
        return LegacyAnalyticsAdapter;
      default:
        return NoopAdapter;
    }
  }

  static apiInterceptors(airlineCode, apiClient) {
    return Analytics.getAdapter(airlineCode).apiInterceptors(apiClient);
  }

  static getInstance(airlineCode) {
    if (_instance instanceof Analytics) return _instance;

    const Adapter = Analytics.getAdapter(airlineCode);

    _instance = new Analytics(new Adapter());

    return _instance;
  }

  static init(airlineCode) {
    return Analytics.getInstance(airlineCode);
  }
}

export default Analytics;
