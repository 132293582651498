import { call, put, takeLatest } from "redux-saga/effects";
import { getConfig, transformConfig } from "portal-services";

const getLocalConfig = (config) => {
  if (process.env.THEME) {
    let data = require(`@theme/config.json`);

    config.data = transformConfig({ ...config.data, ...data });
  }
};
/**
 * @param {Object} action
 */
export function* configSaga(action) {
  const meta = action.meta;
  const { airlineCode } = action.payload;

  let config;

  try {
    config = yield call(getConfig, { airlineCode });

    if (
      process.env.NODE_ENV === "development" &&
      process.env.TEST !== "cypress" &&
      !process.env.THEME_ID
    ) {
      getLocalConfig(config);
    }
    yield put({
      type: "GET_CONFIG_SUCCESS",
      payload: { data: config.data },
      meta: meta,
    });
  } catch (e) {
    yield put({ type: "GET_CONFIG_FAIL", payload: e, meta });
  }
}

/**
 * get config watcher used for testing purposes
 */
export function* getConfigWatcher() {
  yield takeLatest("GET_CONFIG", configSaga);
}
