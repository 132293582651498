import { getUploadBaseUrl } from "portal-services";

/**
 * appends CSS to head
 * @param  {String} css
 */
const appendCSS = (css) => {
  const head = document.head || document.getElementsByTagName("head")[0],
    style = document.createElement("style");

  head.appendChild(style);

  style.appendChild(document.createTextNode(css));
  style.type = "text/css";
};

/**
 * loads theme fonts and global styles
 * @param  {Object} theme
 */
export const loadThemeStyles = async (theme) => {
  const bodyBackgroundColor = theme?.body_background_color || "#f2f2f2";
  const bodyColor = theme?.body_color || theme?.gray_dark;
  const colorPrimary = theme?.color_primary;
  const fontBold = theme?.font_bold?.url;
  const fontNormal = theme?.font_normal?.url;
  const linkColor = theme?.link_color || colorPrimary;
  const linkTextDecoration = theme?.link_text_decoration || "underline";

  if (fontNormal) {
    const primaryFontNormal = new FontFace(
      "Primary",
      `url(${getUploadBaseUrl(fontNormal)})`
    );
    primaryFontNormal.load().then((loadedFace) => {
      document.fonts.add(loadedFace);
    });

    const css = `body, button, input, textarea, select, a { font-family: ${
      fontNormal ? "Primary" : ""
    }; }`;
    appendCSS(css);
  }

  if (fontBold) {
    const primaryFontBold = new FontFace(
      "Primary",
      `url(${getUploadBaseUrl(fontBold)})`,
      { weight: "700" }
    );
    primaryFontBold.load().then((loadedFace) => {
      document.fonts.add(loadedFace);
    });
  }

  if (bodyBackgroundColor) {
    document.body.style.backgroundColor = bodyBackgroundColor;
  }

  if (bodyColor) {
    document.body.style.color = bodyColor;

    const css = `body, button, input, textarea, select, a { color: ${bodyColor};`;
    appendCSS(css);
  }

  const css = `.wysiwyg a, .wysiwyg u { color: ${linkColor}; text-decoration: ${linkTextDecoration}};`;
  appendCSS(css);

  const secondaryCSS = `.wysiwyg.is-container a, .wysiwyg.is-container u {color: ${colorPrimary}};`;
  appendCSS(secondaryCSS);
};
