import { initialState } from "../index";

/**
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */

export const switchDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACTIVE_DEVICE_PIN":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ACTIVE_DEVICE_PIN_SUCCESS":
      return {
        ...state,
        data: { ...action.payload },
        isLoading: false,
      };
    case "GET_ACTIVE_DEVICE_PIN_FAIL":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
