import { axios } from "portal-services";
import { isCaptiveApp, isIFCApp, isPaymentApp } from "portal-utilities";

/**
 * gets build info for onboard portal
 * @return {Promise}
 */
export const getAppBuildInfo = (airlineCode) => {
  let url;
  let app;

  if (isCaptiveApp()) {
    app = `captive`;
  } else if (isIFCApp()) {
    app = `ifc`;
  }

  if (isPaymentApp()) {
    url = "/app/build.json";
  } else if (process.env.HAS_ABP_CONFIG === "true") {
    url = `/app/buildinfo/${app}-build.json`;
  } else if (airlineCode) {
    url = `/app/${airlineCode.toLowerCase()}/build.json`;
  } else {
    throw "Missing airlineCode param for getAppBuildInfo()";
  }

  return axios({
    method: "get",
    url,
    transformResponse: [
      function (data) {
        return transformAppBuildInfo(data);
      },
    ],
  });
};

/**
 * transforms app build info to needed fields
 * @param  {Object} data
 * @return {Object}
 */
export const transformAppBuildInfo = (data) => {
  const response = JSON.parse(data);

  let output = {
    buildNumber: response?.buildNumber,
    version: response?.version,
  };

  return output;
};
