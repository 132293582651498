import queryString from "query-string";
import { getConfig, getIntl, getTheme } from "portal-state-management";
import { loadThemeStyles, localeValidator, oauthUser } from "portal-utilities";
import { getLocale, initGlobalApp } from "../index";

/**
 * OAuth initializer
 * @param {*} dispatch
 * @returns
 */
export const initOAuthApp = async ({ dispatch }) => {
  const oauthQS = queryString.parse(window.location.search);

  oauthUser.setOauthUser(oauthQS);

  const { airlineCode, ui_locales } = oauthUser.getOauthUser();

  const config = await dispatch(getConfig({ airlineCode }));

  await initGlobalApp({ config: config?.data });

  const defaultLocale = await getLocale({ config: config?.data });

  const airlineLocales = config?.data?.locales;

  const validLocale = localeValidator(ui_locales, airlineLocales);

  const locale = validLocale || defaultLocale;

  await dispatch(getIntl({ airlineCode, locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(getTheme({ airlineCode }));
    loadThemeStyles(theme);
  }
};

/**
 * OAuth initializer status
 * @param {*} props
 * @returns
 */
export const isOAuthInitialized = (props) => {
  if (
    props?.intl?.isLoading === false &&
    props?.config?.isLoading === false &&
    props?.theme?.isLoading === false
  ) {
    return true;
  }

  return false;
};
