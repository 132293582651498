/**
 * get products redux action
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Object}
 */
export const getProducts = ({
  airlineCode,
  connectivityType,
  currency,
  config,
  departureCodeIcao,
  destinationCodeIcao,
  flightNumber,
  locale,
  region,
  status,
  tailNumber,
  user,
  uxdId,
}) => {
  return {
    type: "GET_PRODUCTS",
    payload: {
      airlineCode,
      connectivityType,
      currency,
      config,
      departureCodeIcao,
      destinationCodeIcao,
      flightNumber,
      locale,
      region,
      status,
      tailNumber,
      user,
      uxdId,
    },
    meta: {
      thunk: true,
    },
  };
};
