import React from "react";
import queryString from "query-string";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import "moment-duration-format";

import { isPaymentGroundApp, QUERY_PRODUCT } from "portal-utilities";

import {
  bogo1HrAddOn,
  bogo90MinAddOn,
  globalAddOn,
  streamAddOn,
  twoDeviceAddOn,
} from "./helpers/constants";

const BROWSE = "BROWSE";
const MESSAGING = "MESSAGING";
const MONTHLY = "MONTHLY";
const STREAM = "STREAM";
const ULTIMATE = "ULTIMATE";

/**
 * get current product given array of products and product code
 * @param  {Array}  products
 * @param  {String} currentProductCode
 * @return {Object}
 */
export const getCurrentProduct = (products, currentProductCode) => {
  if (isPaymentGroundApp()) {
    return products?.data?.products;
  } else {
    return products?.data?.products?.find(
      (product) => product?.productCode === currentProductCode
    );
  }
};

/**
 * get session duration of current product given array of products and product code
 * @param  {Array}  products
 * @param  {String} currentProductCode
 * @return {Number}
 */
export const getProductDurationSeconds = (products, currentProductCode) => {
  const activeProduct = products?.data?.products?.find(
    (product) => product?.productCode === currentProductCode
  );
  return activeProduct?.totalDurationSeconds;
};

/**
 * determine isTimedProduct given productDurationSeconds
 * @param  {Number}  productDurationSeconds
 * @return {Boolean}
 */
export const isTimedProduct = (productDurationSeconds) => {
  if (productDurationSeconds === 0) return false;
  return productDurationSeconds < 4 * 60 * 60; // less than 4 hours
};

/**
 * get crew product based on duration hours
 * @param  {Array}  products
 */
export const getCrewProduct = (products) => {
  if (!products.isLoading) {
    const crewProduct = products?.data?.products?.filter((product) => {
      return Number(product.durationHours) === 3;
    });
    const [product] = crewProduct;
    return product?.productCode;
  }
};

/**
 * get current product code by parsing query
 * @param  {Object} location
 * @return {String}
 */
export const getCurrentProductCode = (location) =>
  queryString.parse(location?.search)[QUERY_PRODUCT];

/**
 * get query value by parsing query name
 * @param  {Object} location
 * @param  {Object} queryName
 * @return {String}
 */
export const getQueryValue = ({ location, queryName }) =>
  queryString.parse(location?.search)[queryName];

/**
 * gets true/false for if guest checkout is available for current product
 * @param  {Object} config
 * @param  {Object} location
 * @param  {Array} products
 * @return {Boolean}
 */
export const getGuestCheckoutAvailable = ({ config, location, products }) => {
  const currentProductCode = getCurrentProductCode(location);
  const currentProduct = getCurrentProduct(products, currentProductCode);
  return (
    currentProduct &&
    !currentProduct?.isMonthlySubscriptionPlan &&
    !currentProduct?.isFullDayPass &&
    !currentProduct?.isMultiDevicePass &&
    config?.data?.hasGuestCheckout
  );
};

/**
 * checks to see if product is domestic monthly plan
 * @param  {Object} product
 * @return {Boolean}
 */
export const checkDomesticMonthlyPlan = (product) => {
  return product?.isDomesticPlan && product?.isMonthlySubscriptionPlan;
};

/**
 * gets Monthly Subscription product name
 * @param {String} productName
 * @returns  {string}
 */

export const getMonthlyProductsDuration = (englishProductName) => {
  switch (englishProductName) {
    case "Global Plan":
      return "GLOBAL_PLAN";
    case "North America Plan":
      return "NORTH_AMERICA_PLAN";
    case "Air Canada 2-device Plan":
      return "TWO_DEVICE_PLAN";
    case "Alaska Airlines 2-Device":
      return "TWO_DEVICE_PLAN";
    case "Monthly Global Plan":
      return "GLOBAL_PLAN";
    case "GOL Light Monthly Plan":
      return "LIGHT_MONTHLY_PLAN";
    case "GOL Max Monthly Plan":
      return "MAX_MONTHLY_PLAN";
    case "LATAM Browse Monthly Plan":
      return "MONTHLY_BROWSE_PLAN";
    case "LATAM Stream Monthly Plan":
      return "MONTHLY_STREAM_PLAN";
    default:
      return "MONTHLY";
  }
};

/**
 * gets product group based on service code mappings
 * @param  {Object} product
 * @return {String}
 */
export const getProductGroup = (product) => {
  const mappings = {
    ASPOS: BROWSE,
    BRWS: BROWSE,
    BRWSH: BROWSE,
    BRWSM: BROWSE,
    MSG: MESSAGING,
    MSPOS: BROWSE,
    PGS1AU: STREAM,
    PGS1PT: STREAM,
    PGS2AG: BROWSE,
    PGS2BULK: BROWSE,
    PGS2BULKV: BROWSE,
    STRM: STREAM,
    STRMSD: STREAM,
    STRMHD: ULTIMATE,
    WIFI: BROWSE,
    WIFI4: BROWSE,
    WIFI14: BROWSE,
    WIFI5: STREAM,
    WIFI7: STREAM,
    WIFI12: BROWSE,
  };

  return product?.isMonthlySubscriptionPlan
    ? MONTHLY
    : mappings[product?.serviceCode];
};

/**
 * get product groups based on service code
 * @param  {Array} products
 * @return {Array}
 */
export const getProductGroups = (products) => {
  return uniqBy(products, "group").map(({ group }) => group);
};

/**
 * gets a products duration
 * @param  {Object} product
 * @return {String}
 */
export const getProductDuration = (product) => {
  if (product?.isMonthlySubscriptionPlan) {
    return "MONTHLY";
  } else if (product?.isFullFlightPass) {
    return "FULL_FLIGHT";
  } else if (product?.isFullDayPass) {
    return "ALL_DAY";
  } else {
    return moment
      .duration(product?.totalDurationSeconds, "seconds")
      .format("mm", {
        useSignificantDigits: true,
        precision: 0,
      });
  }
};

/**
 * gets a groups products based on selected group and available products
 * @param  {String} options.group
 * @param  {Array} options.products
 * @return {Array}
 */
export const getGroupProducts = ({ group, products }) => {
  return products.filter((product) => {
    return product.group === group;
  });
};

/**
 * duration translations
 * @type {Object}
 */
const DURATION_TRANSLATIONS = {
  ALL_DAY: (
    <FormattedMessage
      id="pages.splash.products.durations.all_day"
      defaultMessage="All day"
    />
  ),
  FULL_FLIGHT: (
    <FormattedMessage
      id="pages.splash.products.durations.full_flight"
      defaultMessage="Full flight"
    />
  ),
  GLOBAL_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.global_plan"
      defaultMessage="Global"
    />
  ),
  LIGHT_MONTHLY_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.light_monthly_plan"
      defaultMessage="Light"
    />
  ),
  MAX_MONTHLY_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.max_monthly_plan"
      defaultMessage="Max"
    />
  ),
  MONTHLY: (
    <FormattedMessage
      id="pages.splash.products.durations.monthly"
      defaultMessage="Monthly"
    />
  ),
  MONTHLY_BROWSE_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.monthly_browse_plan"
      defaultMessage="Browsing"
    />
  ),
  MONTHLY_STREAM_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.monthly_stream_plan"
      defaultMessage="Streaming"
    />
  ),
  NORTH_AMERICA_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.north_america_plan"
      defaultMessage="North America"
    />
  ),
  TWO_DEVICE_PLAN: (
    <FormattedMessage
      id="pages.splash.products.durations.two_device_plan"
      defaultMessage="2-Device"
    />
  ),
};

/**
 * gets unique set of product durations that are available
 * @param  {String} options.group
 * @param  {Array} options.products
 * @return {Array}
 */
export const getProductDurations = ({ group, products }) => {
  return uniqBy(getGroupProducts({ group, products }), "productCode")
    .filter((product) => {
      return !product?.isAddOnProduct;
    })
    .map((product) => {
      const duration = product?.duration;

      const durationName =
        product?.duration === "MONTHLY"
          ? getMonthlyProductsDuration(product?.englishProductName)
          : product?.duration;

      const getHourlyLabel = (duration) => {
        return duration > 1 ? (
          <FormattedMessage
            id="pages.splash.products.hours"
            defaultMessage="{value} hours"
            values={{ value: duration }}
          />
        ) : (
          <FormattedMessage
            id="pages.splash.products.hour"
            defaultMessage="{value} hour"
            values={{ value: duration }}
          />
        );
      };

      const getMinutesLabel = (duration) => {
        return (
          <FormattedMessage
            id="pages.splash.products.minutes"
            defaultMessage="{value} minutes"
            values={{ value: duration }}
          />
        );
      };

      const getHourlyOrMinutesLabel = (duration) => {
        let durationInMinutes = parseInt(duration, 10);
        return durationInMinutes >= 60
          ? getHourlyLabel(Math.round(durationInMinutes / 60))
          : getMinutesLabel(durationInMinutes);
      };

      return {
        label:
          DURATION_TRANSLATIONS[durationName] ||
          getHourlyOrMinutesLabel(duration),
        value: product?.productCode,
        type: duration,
      };
    });
};

/**
 * get selectedProduct information
 * @param  {String} options.productCode
 * @param  {Array} options.products
 * @return {Array}
 */
export const getSelectedProduct = ({ productCode, products }) => {
  return products.find((product) => {
    return productCode === product?.productCode;
  });
};

/**
 * addon translations
 * @type {Object}
 */
const ADDON_TRANSLATIONS = {
  BOGO_90MIN: (
    <FormattedMessage
      id="pages.splash.products.addon.bogo90Min"
      defaultMessage="Buy 60 Min, Get 30 Free!"
    />
  ),
  BOGO_1HR: (
    <FormattedMessage
      id="pages.splash.products.addon.bogo1Hr"
      defaultMessage="Buy 2 Hours, Get 1 Free!"
    />
  ),
  GLOBAL: (
    <FormattedMessage
      id="pages.splash.products.addon.global"
      defaultMessage="Global"
    />
  ),
  NONE: (
    <FormattedMessage
      id="pages.splash.products.addon.none"
      defaultMessage="None"
    />
  ),
  STREAMING: (
    <FormattedMessage
      id="pages.splash.products.addon.streaming"
      defaultMessage="STREAMING"
    />
  ),
  TWO_DEVICE: (
    <FormattedMessage
      id="pages.splash.products.addon.two_device"
      defaultMessage="2-Device"
    />
  ),
};

/**
 * gets any addOn information for the product
 * @param  {Object} products
 * @return {Array}
 */

export const generateProductAddOnInfo = (products) => {
  const addOnProductFeatures = products.filter((product) => {
    return product?.features;
  });

  const addOnRules = [];

  addOnProductFeatures.map((product) => {
    const productFeatures = product.features;
    productFeatures.map((feature) => {
      let finalRules = {};
      finalRules.groupCategory =
        "MONTHLY" === product?.product_type
          ? product.product_type
          : product.service_code;
      finalRules.rule = feature.featureRule;
      finalRules.rootProduct = product?.product_code;

      addOnRules.push(finalRules);
    });
  });

  let productsList = {};
  /* eslint complexity: ["error", 14] */
  addOnRules?.map((addOnRule) => {
    const addOnCategory = products.filter((product) => {
      const category =
        "MONTHLY" === product?.product_type
          ? product.product_type
          : product.service_code;
      return (
        addOnRule.rootProduct !== product?.product_code &&
        addOnRule.groupCategory === category
      );
    });
    addOnCategory.map((product) => {
      const isAddOnTwoDevice =
        addOnRule.rule === "concr_devices=2"
          ? addOnRule.rule.split("=")[1] === product?.redemption?.concr_devices
          : false;
      const isAddOnGlobal =
        addOnRule.rule === "rdm_region=any"
          ? addOnRule.rule.split("=")[1] === product?.redemption?.rdm_region
          : false;
      const isAddOnBOGO1Hr =
        addOnRule.rule === "duration_hours=3"
          ? addOnRule.rule.split("=")[1] === product?.redemption?.duration_hours
          : false;
      const isAddOnBOGO90 =
        addOnRule.rule === "duration_mins=90"
          ? addOnRule.rule.split("=")[1] === product?.redemption?.duration_mins
          : false;
      const isAddOnStreamMSP =
        addOnRule.rule === "service_code=STRMSD"
          ? addOnRule.rule.split("=")[1] === product?.service_code
          : false;

      const isAddOnProduct =
        isAddOnTwoDevice ||
        isAddOnGlobal ||
        isAddOnBOGO1Hr ||
        isAddOnBOGO90 ||
        isAddOnStreamMSP;

      const addOnProductRule = getAddOnRule({
        isAddOnTwoDevice,
        isAddOnGlobal,
        isAddOnBOGO1Hr,
        isAddOnBOGO90,
        isAddOnStreamMSP,
      });

      if (isAddOnProduct) {
        const productCode = product?.product_code;
        productsList[productCode] = {
          addOnProductRule,
          isAddOnProduct,
          productCode,
          rootProduct: isAddOnProduct ? addOnRule.rootProduct : null,
        };
      }
    });
  });

  return productsList;
};

/**
 * gets addon rule for particular product based on condition
 * @param  {Boolean} options.isAddOnTwoDevice
 * @param  {Boolean} options.isAddOnGlobal
 * @param  {Boolean} options.isAddOnBOGO1Hr
 * @param  {Boolean} options.isAddOnBOGO90
 * @param  {Boolean} options.isAddOnStreamMSP
 * @return {String}
 */
export const getAddOnRule = ({
  isAddOnTwoDevice,
  isAddOnGlobal,
  isAddOnBOGO1Hr,
  isAddOnBOGO90,
  isAddOnStreamMSP,
}) => {
  if (isAddOnTwoDevice) {
    return twoDeviceAddOn;
  }

  if (isAddOnGlobal) {
    return globalAddOn;
  }

  if (isAddOnBOGO1Hr) {
    return bogo1HrAddOn;
  }

  if (isAddOnBOGO90) {
    return bogo90MinAddOn;
  }

  if (isAddOnStreamMSP) {
    return streamAddOn;
  }

  return null;
};

/**
 * gets upgrades available for a particular duration
 * @param  {String} options.duration
 * @param  {Array} options.products
 * @return {Array}
 */

export const getDurationUpgrades = ({ selectedProductCode, products }) => {
  const selectedProduct = getSelectedProduct({
    productCode: selectedProductCode,
    products,
  });

  let upgradeProductsList = products.filter((product) => {
    return product?.rootProduct === selectedProduct?.productCode;
  });

  if (upgradeProductsList.length < 1) {
    return [];
  }

  let upgradeOptions = [
    { label: ADDON_TRANSLATIONS["NONE"], value: selectedProductCode },
  ];

  upgradeProductsList.find((product) => {
    const hasTwoDeviceUpgrade =
      product?.isTwoDevicePass && product?.addOnProductRule === twoDeviceAddOn;
    const hasGlobalUpgrade =
      product?.isGlobalPlan && product?.addOnProductRule === globalAddOn;
    const hasBOGONinetyMinUpgrade =
      product?.isBOGONintyMinPlan && product?.addOnProductRule === bogo1HrAddOn;
    const hasBOGOOneHourUpgrade =
      product?.isBOGOOneHourPlan &&
      product?.addOnProductRule === bogo90MinAddOn;
    const hasStreamingUpgrade =
      product?.isStreamingDevicePass &&
      product?.addOnProductRule === streamAddOn;

    if (hasTwoDeviceUpgrade) {
      upgradeOptions.push({
        label: ADDON_TRANSLATIONS["TWO_DEVICE"],
        value: product?.productCode,
      });
    }

    if (hasGlobalUpgrade) {
      upgradeOptions.push({
        label: ADDON_TRANSLATIONS["GLOBAL"],
        value: product?.productCode,
      });
    }

    if (hasBOGONinetyMinUpgrade) {
      upgradeOptions.push({
        label: ADDON_TRANSLATIONS["BOGO_90MIN"],
        value: product?.productCode,
      });
    }

    if (hasBOGOOneHourUpgrade) {
      upgradeOptions.push({
        label: ADDON_TRANSLATIONS["BOGO_1HR"],
        value: product?.productCode,
      });
    }

    if (hasStreamingUpgrade) {
      upgradeOptions.push({
        label: ADDON_TRANSLATIONS["STREAMING"],
        value: product?.productCode,
      });
    }
  });

  return upgradeOptions;
};

/**
 * gets if the product is a sponsered product
 * @param  {String} product
 * @return {Boolean}
 */

export const isSponsoredProduct = (product) => {
  const productCode = product?.productCode;
  const sponsoredProductCodeList = [
    "ACMS1201LY",
    "ACMS1202LY",
    "ACMS1203LY",
    "ACMS1204LY",
    "ACSPPA0000",
    "AMX_MSG",
    "ASA_MSG",
    "ASSPAA0000",
    "ASSSKA0000",
    "ASSPPC0000",
    "JTA_FLT",
    "RXA_FLT",
    "SPR_1HRMSG",
    "SPR_FLT",
    "TMO_1HRMSG",
    "TMO_FLT",
    "TMO_MSG",
  ];

  return sponsoredProductCodeList.includes(productCode);
};

export const getInitialProductGroup = ({
  conditions = false,
  group = "",
  isInitialLoading = false,
  products = [],
}) => {
  group = isInitialLoading ? getProductGroups(products)[0] : group;
  return conditions ? "" : group;
};
