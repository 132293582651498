import DataModel from "../DataModel";
import { LOG_EVENT } from "../../constants";

class LogEventDataModel extends DataModel {
  constructor(data = {}) {
    super(data);
    this.displayName = LOG_EVENT;
  }
}

export default LogEventDataModel;
