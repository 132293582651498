import { axios, getAirborneABPEdgeBaseUrl } from "portal-services";
import { COOKIE_USERNAME, getCookie } from "portal-utilities";

/**
 * gets login status of user/uxdId
 * @param  {String} options.uxdId
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getLoginStatus = ({ uxdId, status }) => {
  return axios({
    method: "get",
    url: `/v2/loginstatus/${uxdId}`,
    baseURL: getAirborneABPEdgeBaseUrl(status),
    transformResponse: [
      function (data) {
        return transformLoginStatus(data);
      },
    ],
  });
};

/**
 * tranforms login status to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformLoginStatus = (data) => {
  const response = JSON.parse(data);
  const userName = getCookie(COOKIE_USERNAME);

  let output;

  if (typeof response !== "boolean") {
    output = {
      authenticated: false,
      invalidUxdId: true,
    };
  } else {
    output = {
      authenticated: (response && userName) ?? false,
    };
  }

  return output;
};
