/**
 * returns boolean if app is IFE app
 * @param  {String} locale
 * @return {Boolean}
 */
export const isIFEApp = () => process.env.APP === "ife";

/**
 * returns boolean if app is IFE app route
 * @param  {String} locale
 * @return {Boolean}
 */
export const isRouteIFEApp = () =>
  window.location.host.includes("airbornemedia");
