/**
 * @const {String}
 */
export const ROUTE_ACCOUNT = "/account";

/**
 * @const {String}
 */
export const ROUTE_CAPTIVE = "/captive";

/**
 * @const {String}
 */
export const ROUTE_CAPTIVE_IOS = "/captive/ios";

/**
 * @const {String}
 */
export const ROUTE_CAPTIVE_NON_IOS = "/captive/non_ios";

/**
 * @const {String}
 */
export const ROUTE_IFC = "/ifc";

/**
 * @const {String}
 */
export const ROUTE_IFC_ACTIVATION_RETRY = "/ifc/activation_retry";

/**
 * @const {String}
 */
export const ROUTE_IFC_SPLASH = "/ifc/splash";

/**
 * @const {String}
 */
export const ROUTE_IFC_GOBROWSE = "/ifc/gobrowse";

/**
 * @const {String}
 */
export const ROUTE_IFC_SWITCH_DEVICE = "/ifc/switch_device";

/**
 * @const {String}
 */
export const ROUTE_IFC_PARTNERS = "/ifc/partners";

/**
 * @const {String}
 */
export const ROUTE_IFC_PASS_DETAILS = "/ifc/pass_details";

/**
 * @const {String}
 */
export const ROUTE_IFC_PRE_PURCHASED_PASSES = "/ifc/pre_purchased_passes";

/**
 * @const {String}
 */
export const ROUTE_IFC_SUPPORT = "/ifc/support";

/**
 * @const {String}
 */
export const ROUTE_IFC_FEEDBACK = "/ifc/feedback";

/**
 * @const {String}
 */
export const ROUTE_IFC_ROAMING = "/ifc/roaming";

/**
 * @const {String}
 */
export const ROUTE_IFC_PARTNER_SIGN_IN = "/ifc/partner_sign_in";
export const ROUTE_IFC_SPONSOR_CERTIFY = "/ifc/sponsor_certify";
export const ROUTE_IFC_SPONSOR_VALIDATION = "/ifc/sponsor_validation";

/**
 * @const {String}
 */
export const ROUTE_LEGAL_CONTENT = "/legal-content";

/**
 * @const {String}
 */
export const ROUTE_LEGAL_CONTENT_COOKIE_POLICY = "/legal-content/cookie-policy";

/**
 * @const {String}
 */
export const ROUTE_LEGAL_CONTENT_TERMS_OF_USE = "/legal-content/terms";

/**
 * @const {String}
 */
export const ROUTE_LEGAL_CONTENT_PRIVACY_POLICY =
  "/legal-content/privacy-policy";

/**
 * @const {String}
 */
export const ROUTE_IFC_FREE_MESSAGING = "/ifc/free_messaging";

/**
 * @const {String}
 */
export const ROUTE_IFE = "/ife";

/**
 * @const {String}
 */
export const ROUTE_IFE_ENTERTAINMENT = "/ife/entertainment";

/**
 * @const {String}
 */
export const ROUTE_IFE_LIVE_TV = "/ife/live_tv";

/**
 * @const {String}
 */
export const ROUTE_IFE_MEDIA = "/ife/media";

/**
 * @const {String}
 */
export const ROUTE_OAUTH = "/oauth";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT = "/payment";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_BENEFIT_ACTIVATION = "/payment/benefit_activation";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_BENEFIT_UNAVAILABLE = "/payment/benefit_unavailable";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_CHECKOUT = "/payment/checkout";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_CREATE_ACCOUNT = "/payment/create_account";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_PARTNER_LOGIN = "/payment/partner_login";

/**
 * @const {String}
 */
export const ROUTE_PAYMENT_SIGN_IN = "/payment/sign_in";

/**
 * @const {String}
 */
export const ROUTE_ROUTES = "/routes";
