import {
  getConfig,
  getFlightInfo,
  getIntl,
  getStatus,
  getTheme,
} from "portal-state-management";
import { loadThemeStyles } from "portal-utilities";
import { getCaptive } from "../../state_management/captive/actions.js";
import { getLocale, initGlobalApp } from "../index";

/**
 * Captive initializer
 * @param {*} dispatch
 * @returns
 */
export const initCaptiveApp = async ({ dispatch }) => {
  const config = await dispatch(getConfig({}));

  await initGlobalApp({ config: config?.data });

  const serviceStatus = await dispatch(getStatus());

  await dispatch(
    getFlightInfo({
      config: config?.data,
      status: serviceStatus,
    })
  );

  let locale = await getLocale({ config: config?.data });

  await dispatch(getIntl({ locale }));

  if (config?.data?.theme) {
    const theme = await dispatch(getTheme({}));
    loadThemeStyles(theme);
  }

  try {
    dispatch(
      getCaptive({
        status: serviceStatus,
      })
    );
  } catch (error) {
    dispatch(
      getCaptive({
        serviceStatus: {},
      })
    );
  }
};

/**
 * Captive initializer status
 * @param {*} props
 * @returns
 */
export const isCaptiveInitialized = (props) => {
  // TODO: Check for error.
  if (
    props?.flightInfo?.isLoading === false &&
    props?.config?.isLoading === false &&
    props?.theme?.isLoading === false
  ) {
    return true;
  }

  return false;
};
