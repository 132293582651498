import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "portal-components";

import styles from "./_index.module.scss";

class Error extends Component {
  refreshPage = () => {
    window.location.assign("/");
  };

  render() {
    return (
      <div>
        <div className={styles["error-content"]}>
          <div>
            <h4>
              <FormattedMessage
                id="errors.generic"
                defaultMessage="Something went wrong..."
              />
            </h4>
            <Button
              label="Refresh Page"
              type="ghost"
              size="small"
              onClick={this.refreshPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
