/**
 * Returns first match of locale in locales passed from third party query string
 * to locale of available airline locales. ICD specifies third party ui_locales as:
 * End-User's preferred languages and scripts for the user interface, represented
 * as a space-separated list of language tag values, ordered by preference. Default to en_US
 * @function localeValidator
 * @param {string} locales
 * @param {array} availableLocales
 * @returns {string}
 */

export const localeValidator = (locales, availableLocales) => {
  try {
    const localeList = locales.split(" ");
    const localeListTransform = localeList.map((el) => el.replace("_", "-"));
    const commonList = localeListTransform.filter((el) =>
      availableLocales.includes(el)
    );
    return commonList[0] ?? "";
  } catch (e) {
    return "";
  }
};
