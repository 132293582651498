import {
  axios,
  getAirborneABPEdgeCacheBaseUrl,
  getAirborneEdgeBaseUrl,
} from "portal-services";
import { getHeaders } from "portal-utilities";
import isEmpty from "lodash/isEmpty";

/**
 * gets weather data based on airport and locale
 * @param  {String} options.airportCode
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getWeather = ({
  airportCode,
  airportCodeIcao,
  locale,
  status,
}) => {
  return status.edgeCacheStatus
    ? getABPEdgeWeather({ airportCodeIcao, locale, status })
    : getGroundWeather({ airportCode, locale, status });
};

/**
 * gets weather from abp edge (https tails)
 * @param  {String} options.airportCode
 * @param  {Object} options.status
 * @param  {String} options.locale
 * @return {Promise}
 */
export const getABPEdgeWeather = ({
  airportCodeIcao,
  locale = "en-US",
  status,
}) => {
  return axios({
    method: "get",
    url: `weather/${airportCodeIcao}?version=1.0&locale=${locale}&scale=F&days=1`,
    baseURL: getAirborneABPEdgeCacheBaseUrl(status),
    headers: getHeaders(),
    transformResponse: [
      function (data) {
        return transformWeather(data);
      },
    ],
  });
};

/**
 * gets weather from ground (http tails)
 * @param  {String} options.airportCode
 * @param  {Object} options.status
 * @param  {String} options.locale
 * @return {Promise}
 */
export const getGroundWeather = ({ airportCode, locale = "en-US", status }) => {
  return axios({
    method: "get",
    url: `/v2/weather/full/${airportCode}?days=7&locale=${locale}&scale=F`,
    baseURL: getAirborneEdgeBaseUrl(status),
    headers: getHeaders(),
    transformResponse: [
      function (data) {
        return transformWeather(data);
      },
    ],
  });
};

/**
 * tranforms weather to readable format
 * @param  {Object} data
 * @return {Object}
 */
export const transformWeather = (data) => {
  let response = JSON.parse(data);

  let forecast = response.forecasts.forecast.map((dayOfWeek) => {
    return {
      dateTime: dayOfWeek?.dateTime,
      minTempC: dayOfWeek?.minTempC + "°",
      minTempF: dayOfWeek?.minTempF + "°",
      maxTempC: !isEmpty(dayOfWeek.day) ? dayOfWeek?.maxTempC + "°" : "--",
      maxTempF: !isEmpty(dayOfWeek.day) ? dayOfWeek?.maxTempF + "°" : "--",
      sky: !isEmpty(dayOfWeek.day)
        ? dayOfWeek?.day?.sky
        : dayOfWeek?.night?.sky,
      skyIcon: !isEmpty(dayOfWeek.day)
        ? String(dayOfWeek?.day?.skyIcon)?.padStart(2, "0")
        : String(dayOfWeek?.night?.skyIcon)?.padStart(2, "0"),
    };
  });

  let output = {
    city: response?.currentConditions?.city,
    state: response?.currentConditions?.state,
    reportedAt: parseInt(response?.currentConditions?.reportedAt),
    currentConditions: {
      dateTime: response?.currentConditions?.dateTime,
      tempC: response?.currentConditions?.temperatureC
        ? response?.currentConditions?.temperatureC + "°"
        : "--",
      tempF: response?.currentConditions?.temperatureF
        ? response?.currentConditions?.temperatureF + "°"
        : "--",
      sky: response?.currentConditions?.sky,
      skyIcon: String(response?.currentConditions?.skyIcon)?.padStart(2, "0"),
    },
    forecast: forecast,
  };

  output.isValid = isWeatherValid(output);

  return output;
};

/**
 * validity check for weather to display
 * @param  {Object} weather
 * @return {Boolean}
 */
export const isWeatherValid = (weather) => {
  return weather.currentConditions.tempC ? true : false;
};
