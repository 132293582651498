import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ROUTE_IFC_GOBROWSE, ROUTE_IFC_SPLASH } from "portal-utilities";
import { logSplashLoadTime } from "portal-services";
import { Logger } from "../index";

const withPageLogging = (WrappedComponent) => {
  class WithPageLogging extends Component {
    componentDidMount() {
      this.trackPageEvent();
    }

    componentDidUpdate(prevProps) {
      const { flightInfo, location } = this.props;
      if (!prevProps.flightInfo?.data && flightInfo?.data) {
        this.trackPageEvent();
        const isSplashPage = location?.pathname?.includes(ROUTE_IFC_SPLASH);
        const airlineCode = flightInfo?.data?.airlineCode;
        const connectivityType = flightInfo?.data?.system?.type;
        const departureCodeIcao = flightInfo?.data?.departure?.airportCodeIcao;
        const destinationCodeIcao = flightInfo?.data?.arrival?.airportCodeIcao;
        const flightNumber = flightInfo?.data?.flightNumber;
        const tailNumber = flightInfo?.data?.tailNumber;
        const user = flightInfo?.data?.user;

        if (isSplashPage) {
          logSplashLoadTime({
            airlineCode,
            connectivityType,
            departureCodeIcao,
            destinationCodeIcao,
            flightNumber,
            tailNumber,
            user,
          });
        }
      }
    }

    trackPageEvent() {
      const { flightInfo, location } = this.props;
      let message = location?.pathname + location?.search;
      const goBrowsePage = location?.pathname?.includes(ROUTE_IFC_GOBROWSE);
      const hasActivePass = flightInfo?.data?.user?.activePass;
      if (goBrowsePage && hasActivePass) {
        message = `hasActivePass ${message}`;
      }
      if (flightInfo?.data) {
        Logger.track("pageLoad", {
          ...flightInfo?.data,
          message,
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    flightInfo: state?.flightInfo,
  });

  WithPageLogging.propTypes = {
    flightInfo: PropTypes.object,
    location: PropTypes.object,
  };

  return connect(mapStateToProps)(WithPageLogging);
};

export default withPageLogging;
