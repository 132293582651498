import { call, put, takeLatest } from "redux-saga/effects";
import {
  COOKIE_DYNATRACE_THEME_USED,
  darkMode,
  setCookie,
} from "portal-utilities";
import { getTheme } from "portal-services";
import { jVar } from "json-variables";

/**
 * gets theme data from file within the application for local development
 * @return {Promise}
 */
const getLocalTheme = ({ isDarkModeEnabled, theme, isTargetTheme }) => {
  // THIS NEEDS TO REMAIN EXPLICITY SEPARATE TO AVOID LOCAL WEBPACK TREESHAKING ISSUE
  if (process.env.THEME) {
    const isDarkMode = isDarkModeEnabled || darkMode();
    const themeJson = "theme.json";
    let data = require(`@theme/${themeJson}`);

    if (isDarkMode) {
      const theme = "theme_dark.json";
      let darkData = require(`@theme/${theme}`);
      darkData.isDarkMode = true;

      data = { ...data, ...darkData };
    }

    if (isTargetTheme) {
      const theme = "theme-target.json";
      let intlData = require(`@theme/${theme}`);

      data = { ...data, ...intlData };
    }

    const overrideThemeData = jVar(data);
    theme.data = { ...theme.data, ...overrideThemeData };
  }
};

/**
 * @param {Object} action
 */
export function* themeSaga(action) {
  const meta = action.meta;
  const { airlineCode, isDarkMode, isTargetTheme } = action.payload;

  let theme;

  try {
    theme = yield call(getTheme, { airlineCode, isDarkMode, isTargetTheme });

    if (isTargetTheme) {
      setCookie(COOKIE_DYNATRACE_THEME_USED, "targeted-theme-used");
    } else {
      setCookie(COOKIE_DYNATRACE_THEME_USED, "default-theme-used");
    }

    // For Cypress testing, run TEST=test with yarn airline.
    if (
      process.env.NODE_ENV === "development" &&
      process.env.TEST !== "cypress" &&
      !process.env.THEME_ID
    ) {
      getLocalTheme({ isDarkModeEnabled: isDarkMode, theme, isTargetTheme });
    }

    yield put({
      type: "GET_THEME_SUCCESS",
      payload: theme.data,
      meta: meta,
    });
  } catch (e) {
    yield put({ type: "GET_THEME_FAIL", payload: e, meta });
  }
}

/**
 * get theme watcher used for testing purposes
 */
export function* getThemeWatcher() {
  yield takeLatest("GET_THEME", themeSaga);
}
