import { axios, getAirborneMediaASPBaseUrl } from "portal-services";

/**
 * gets catalog storefront settings
 * @param  {String} options.locale
 * @param  {Object} options.status
 * @return {Promise}
 */
export const getCatalogStorefront = ({ locale, status }) => {
  return axios({
    method: "get",
    url: `/v1/${locale}/collections/storefront`,
    baseURL: getAirborneMediaASPBaseUrl(status),
  });
};
